import { useEffect } from 'react';
import {
  buildFiltersQueryString,
  requestFiltersSearchResults,
} from '@lib/requests/vehicleSearch';

const useShowMore = (
  vehicleSearch,
  setVehicleSearch,
  hasSearched,
  setHasSearched
) => {
  const buildDataFromCache = (parsedData, pagesToRetrieve) => {
    const pagesToInclude = Array.from(
      { length: pagesToRetrieve },
      (_, index) => index + 1
    );

    const searchResults = pagesToInclude
      .map((number) => parsedData[`page-${number}`].searchResults)
      .flat();

    const UnformattedStockChipData = Object.values(parsedData).map(
      (obj) => obj.stockChipData
    );

    const stockChipData = UnformattedStockChipData.reduce((result, obj) => {
      Object.keys(obj).forEach((key) => {
        result[key] = Object.assign({}, result[key], obj[key]);
      });
      return result;
    }, {});

    return { searchResults, stockChipData };
  };

  const resetCacheToSinglePage = ({
    pagination,
    searchResults,
    stockChipData,
  }) => {
    const data = {};
    data['page-1'] = {
      pagination: pagination,
      searchResults: searchResults,
      stockChipData: stockChipData,
    };
    sessionStorage.setItem('cachedVehicleData', JSON.stringify(data));
  };

  const resetPagination = (vehicleSearch) => {
    return {
      previousPage: null,
      currentPage: 1,
      nextPage: 2,
      totalNumberOfPages: vehicleSearch.result.pagination.total_number_of_pages,
    };
  };

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      const parsedData = JSON.parse(
        sessionStorage.getItem('cachedVehicleData')
      );

      const pageNumber = event.state
        ? event.state.result.pagination.currentPage
        : 1;

      const { searchResults, stockChipData } = buildDataFromCache(
        parsedData,
        pageNumber
      );

      setVehicleSearch((prevState) => ({
        ...prevState,
        result: {
          ...prevState.result,
          pagination: event.state
            ? {
                previousPage: pageNumber - 1,
                currentPage: pageNumber,
                nextPage: pageNumber + 1,
                totalNumberOfPages:
                  vehicleSearch.result.pagination.total_number_of_pages,
              }
            : resetPagination(vehicleSearch),
          searchResults: searchResults,
          stockChipData: stockChipData,
        },
      }));
    });

    return () => {
      window.removeEventListener('popstate');
    };
  }, []);

  useEffect(() => {
    const parsedData = JSON.parse(sessionStorage.getItem('cachedVehicleData'));
    const lastPageView = sessionStorage.getItem('lastPageView');
    const currentPage = `page-${lastPageView}`;
    const historySearched = window.history?.state?.hasSearched ?? false;
    historySearched && setHasSearched(true);
    const shouldResetCache =
      (!hasSearched && !historySearched) || !lastPageView || lastPageView === 1;
    const shouldRestoreFromCache =
      parsedData &&
      Object.keys(parsedData).length !== 0 &&
      parsedData?.[currentPage] &&
      (hasSearched || historySearched);

    shouldResetCache && resetCacheToSinglePage(vehicleSearch.result);

    if (shouldRestoreFromCache) {
      const { pagination } = parsedData[currentPage];

      const { searchResults, stockChipData } = buildDataFromCache(
        parsedData,
        lastPageView
      );

      setVehicleSearch((prevState) => ({
        ...prevState,
        result: {
          ...vehicleSearch.result,
          ...{
            pagination: pagination,
            searchResults: searchResults,
            stockChipData: stockChipData,
          },
        },
      }));
    }
  }, []);

  const determineResult = async (newPageNumber, requestFilters) => {
    const parsedData = JSON.parse(sessionStorage.getItem('cachedVehicleData'));
    const hasCachedPageData = parsedData?.[`page-${newPageNumber}`] ?? false;
    let result;

    if (newPageNumber && hasCachedPageData) {
      const { searchResults, stockChipData } = buildDataFromCache(
        parsedData,
        newPageNumber
      );

      result = {
        ...vehicleSearch.result,
        stockChipData: stockChipData,
        pagination: {
          previousPage: newPageNumber - 1,
          currentPage: newPageNumber,
          nextPage: newPageNumber + 1,
          totalNumberOfPages:
            vehicleSearch.result.pagination.total_number_of_pages,
        },
        searchResults: searchResults,
      };

      sessionStorage.setItem('lastPageView', newPageNumber);
    } else if (newPageNumber) {
      const requestedResult = await requestFiltersSearchResults(
        buildFiltersQueryString({
          ...vehicleSearch.appliedFilters,
          ...requestFilters,
        })
      );

      const { pagination, stockChipData, searchResults } = requestedResult;

      const combinedSearchResults = [
        ...Object.values(parsedData)
          .map((obj) => obj.searchResults)
          .flat(),
        ...searchResults,
      ];

      const allStockChipData = Object.values(parsedData).map(
        (obj) => obj.stockChipData
      );

      if (Object.keys(stockChipData).length > 0) {
        allStockChipData.push(stockChipData);
      }

      const newStockChipData = allStockChipData.reduce((result, obj) => {
        Object.keys(obj).forEach((key) => {
          result[key] = Object.assign({}, result[key], obj[key]);
        });
        return result;
      }, {});

      parsedData[`page-${pagination.currentPage}`] = {
        stockChipData: stockChipData,
        pagination: pagination,
        searchResults: searchResults,
      };

      sessionStorage.setItem('cachedVehicleData', JSON.stringify(parsedData));
      sessionStorage.setItem('lastPageView', pagination.currentPage);

      result = {
        ...requestedResult,
        searchResults: combinedSearchResults,
        stockChipData: newStockChipData,
      };
    } else {
      result = await requestFiltersSearchResults(
        buildFiltersQueryString({
          ...vehicleSearch.appliedFilters,
          ...requestFilters,
        })
      );
      resetCacheToSinglePage(result);
      sessionStorage.setItem('lastPageView', 1);
    }

    return result;
  };

  return { determineResult };
};

export default useShowMore;
