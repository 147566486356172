import React, { useState, useEffect } from 'react';
import Modal from '@components/application/modal';
import SingleSelect from '../search_filters/filters/singleSelect';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import { toQueryString } from '@utils';

const BranchFilterModal = ({ franchises, initialParams, baseUrl }) => {
  const [params, setParams] = useState(initialParams);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    EventEmitter.emit(EVENT_TYPES.closeModal, 'filters');
  };

  const shouldUpdate = () => {
    return initialParams.franchise_id !== params.franchise_id;
  };

  const navigateToFranchiseUrl = () => {
    setIsLoading(true);
    const queryString = toQueryString(params);
    window.location.href = `${baseUrl}?${queryString}`;
  };
  const handleUrlChange = () => {
    shouldUpdate() ? navigateToFranchiseUrl() : closeModal();
  };

  useEffect(() => {
    handleUrlChange();
  }, [params]);

  const onFilterUpdate = (selection) => {
    setParams({
      ...params,
      franchise_id: selection['franchise'],
      franchise_name: '',
    });
  };

  return (
    <Modal id="filters">
      <SingleSelect
        availableOptions={franchises}
        id="franchise"
        isLoading={isLoading}
        name="a branch"
        onFilterUpdate={onFilterUpdate}
        selected={initialParams.franchise_id || ''}
        subtext={
          'Shop new or book a manufacturer service at our specialist dealerships.'
        }
      />
    </Modal>
  );
};

export default BranchFilterModal;

BranchFilterModal.propTypes = {
  baseUrl: PropTypes.string,
  franchises: PropTypes.array,
  initialParams: PropTypes.object,
};
