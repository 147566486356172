const BackButton = (): JSX.Element => {
  if (
    typeof window === 'undefined' ||
    !document.referrer.includes(window.origin)
  )
    return <div className="ch-mb--10" />;
  return (
    <button
      className="ac-back-button ch-ba--0 ch-color--ac-black ch-mr--auto ch-bg--grey-2 ch-rounded--md ch-pv--1 ch-ph--2 ch-fs--3 ch-mb--4"
      onClick={() => window.history.back()}
      type="button"
    >
      Back
    </button>
  );
};

export default BackButton;
