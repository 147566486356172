import { useRef, useState, useEffect } from 'react';

export default function useScrollDetection<T extends HTMLElement>() {
  const ref = useRef<T | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const handleScroll = () => {
      //once the element is scrolled back to the top, it's no longer scrolled
      if (element.scrollTop === 0) {
        setIsScrolled(false);
      } else {
        setIsScrolled(true);
      }
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [ref, isScrolled];
}
