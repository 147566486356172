import React, { useState } from 'react';
import {
  FilterContainer,
  ModelAndTrimOptions,
} from '@components/search_filters/units';
import PropTypes from 'prop-types';
import { deepClone } from '@lib/utils';

const TrimSelect = ({ availableOptions, onFilterUpdate, selected }) => {
  const [makeModelTrimSelection, setMakeModelTrimSelection] = useState(
    deepClone(selected.makeModelTrimSelection)
  );

  const generateMakeModelTrimInstance = (makeKey, modelKey, trims) => {
    return {
      [makeKey]: {
        displayName: makeModelTrimSelection[makeKey].displayName,
        models: {
          ...makeModelTrimSelection[makeKey].models,
          [modelKey]: {
            displayName:
              makeModelTrimSelection[makeKey].models[modelKey].displayName,
            trims: {
              ...trims,
            },
          },
        },
      },
    };
  };

  const selectTrim = (makeKey, trimKey, modelKey) => {
    const trims = makeModelTrimSelection[makeKey].models[modelKey].trims;
    const isTrimSelected =
      trimKey in makeModelTrimSelection[makeKey].models[modelKey].trims;

    if (isTrimSelected) {
      delete trims[trimKey];
    } else {
      trims[trimKey] = {
        ...availableOptions[makeKey].models[modelKey].trims[trimKey],
      };
    }

    setMakeModelTrimSelection((prevState) => {
      return {
        ...prevState,
        ...generateMakeModelTrimInstance(makeKey, modelKey, trims),
      };
    });
  };

  const removeTrimsForModels = (makeKey, modelKey) => {
    setMakeModelTrimSelection((prevState) => ({
      ...prevState,
      ...generateMakeModelTrimInstance(makeKey, modelKey, {}),
    }));
  };

  const handleClearSelection = () => {
    const trimsToClear = {};
    Object.keys(makeModelTrimSelection).forEach((make) => {
      trimsToClear[make] = { ...makeModelTrimSelection[make] };
      Object.keys(makeModelTrimSelection[make].models).forEach((modelKey) => {
        trimsToClear[make].models[modelKey] = {
          ...makeModelTrimSelection[make].models[modelKey],
          trims: {},
        };
      });
    });

    setMakeModelTrimSelection((prevState) => ({
      ...prevState,
      ...trimsToClear,
    }));
  };

  const renderTrimOptions = () => {
    const modelAndTrimOptions = [];

    Object.entries(makeModelTrimSelection).forEach((make) => {
      const [makeKey, { displayName: makeDisplayName, models: models }] = make;

      Object.entries(models).forEach((model) => {
        const [modelKey, { displayName: modelDisplayName }] = model;
        const trims = availableOptions[makeKey].models[modelKey].trims;

        modelAndTrimOptions.push(
          <ModelAndTrimOptions
            displayName={`${makeDisplayName} ${modelDisplayName}`}
            key={`${makeKey} ${modelKey}`}
            label={'All model variants'}
            makeKey={makeKey}
            modelKey={modelKey}
            name={modelDisplayName}
            options={trims}
            removeSelectedOption={removeTrimsForModels}
            selectOption={selectTrim}
            selectedOptions={
              makeModelTrimSelection[makeKey].models[modelKey].trims
            }
            suffix="model variants"
          />
        );
      });
    });

    return modelAndTrimOptions;
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilterUpdate({ makeModel: makeModelTrimSelection });
  };

  return (
    <FilterContainer
      handleClearSelection={handleClearSelection}
      handleClick={handleSubmit}
      showClearButton
      title="Select model variants"
    >
      {renderTrimOptions()}
    </FilterContainer>
  );
};

TrimSelect.propTypes = {
  availableOptions: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.object,
};

export default TrimSelect;
