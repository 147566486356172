import { useEffect } from 'react';
import type { StoreApi, UseBoundStore } from 'zustand';

//extending StoreApi to include zustant-persist functionality
interface PersistedStore<T> extends StoreApi<T> {
  persist: {
    hasHydrated: () => boolean;
    rehydrate: () => Promise<void> | void;
  };
}

interface HydratedStore<T> {
  hasHydrated: boolean;
  store: T;
}

function useHydrateStore<T>(store: PersistedStore<T>): void {
  useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined' && !store.persist.hasHydrated()) {
        await store.persist.rehydrate();
      }
    })();
  }, []);
}

function hasHydrated<T>(store: PersistedStore<T>): boolean {
  return typeof window !== 'undefined' && store.persist.hasHydrated();
}

export default function withHydrationHelpers<T>(
  store: UseBoundStore<PersistedStore<T>>
): () => HydratedStore<T> {
  return () => {
    useHydrateStore<T>(store);
    return {
      hasHydrated: hasHydrated<T>(store),
      store: store(),
    };
  };
}
