import React from 'react';
import PropTypes from 'prop-types';

const VehicleComparisonDisclaimer = ({ children }) => {
  return (
    <div className="compare__disclaimer ch-container ch-ph--1 lg:ch-ph--3 ch-pv--2">
      <div className="ch-card ch-bg--grey-1">
        <div className="ch-card__content">{children}</div>
      </div>
    </div>
  );
};

VehicleComparisonDisclaimer.propTypes = {
  children: PropTypes.node,
};

export default VehicleComparisonDisclaimer;
