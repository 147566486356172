import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const paymentTypes = [
  'price_up',
  'price_down',
  'monthly_payment_up',
  'monthly_payment_down',
];

export default class SortOrder extends PureComponent {
  static propTypes = {
    appliedFilters: PropTypes.object,
    onFilterCriteriaUpdate: PropTypes.func,
  };

  updateSortOrder = (event) => {
    if (paymentTypes.includes(event.target.value)) {
      if (
        this.props.appliedFilters.paymentType ===
        this.switchPaymentType(event.target.value)
      ) {
        this.props.onFilterCriteriaUpdate({
          sort_order: event.target.value,
          payment_type: this.switchPaymentType(event.target.value),
        });
      } else {
        this.props.onFilterCriteriaUpdate({
          sort_order: event.target.value,
          payment_type: this.switchPaymentType(event.target.value),
          min_price: '',
          max_price: '',
        });
      }
    } else {
      this.props.onFilterCriteriaUpdate({
        sort_order: event.target.value,
      });
    }
  };

  switchPaymentType = (value) => {
    if (['price_up', 'price_down'].includes(value)) return 'cash';
    return 'monthly';
  };

  renderDistanceOption = () => {
    if (this.props.appliedFilters.location) {
      return (
        <option ref="distance" value="distance">
          Distance (near to far)
        </option>
      );
    }
  };

  render() {
    return (
      <label
        className="ch-btn ch-mr--auto search-header__sort-btn"
        htmlFor="sort-order"
      >
        Sort
        <select
          className="ch-form__control"
          id="sort-order"
          name="sort_order"
          onChange={this.updateSortOrder}
          value={this.props.appliedFilters.sortOrder}
        >
          <option ref="monthlyPaymentUp" value="monthly_payment_up">
            Monthly payment (low to high)
          </option>
          <option ref="monthlyPaymentDown" value="monthly_payment_down">
            Monthly payment (high to low)
          </option>
          <option ref="cashPriceUp" value="price_up">
            Cash price (low to high)
          </option>
          <option ref="cashPriceDown" value="price_down">
            Cash price (high to low)
          </option>
          <option value="mileage">Mileage (low to high)</option>
          <option value="age">Age (new to old)</option>
          <option value="recently_added">Recently added</option>
          {this.renderDistanceOption()}
        </select>
      </label>
    );
  }
}
