import classNames from 'classnames';

type FeatureOptionProps = {
  name: string;
  onChange: (value: string) => void;
  selected: boolean;
  value: string;
};

const FeatureOption = ({
  name,
  onChange,
  selected,
  value,
}: FeatureOptionProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(value);
    }
  };

  return (
    <label
      className={classNames('feature-option', {
        'feature-option--checked': selected,
      })}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <input
        checked={selected}
        className="ch-reader"
        name={name}
        onChange={() => onChange(value)}
        tabIndex={-1}
        type="checkbox"
        value={value}
      />
      {value}
    </label>
  );
};

export default FeatureOption;
