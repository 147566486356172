import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

const GalleryView = ({ position, setPosition, thumbnails }) => {
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div className="gallery__thumbs" ref={ref}>
          {inView &&
            thumbnails.map((thumbnail, index) => (
              <div
                className={`gallery__thumb ${
                  position === index ? 'active' : ''
                }`}
                key={thumbnail}
              >
                <img
                  alt=""
                  height="147"
                  onClick={() => setPosition(index)}
                  src={thumbnail}
                  width="196"
                />
              </div>
            ))}
        </div>
      )}
    </InView>
  );
};

export default GalleryView;

GalleryView.propTypes = {
  position: PropTypes.number,
  setPosition: PropTypes.func,
  thumbnails: PropTypes.array,
};
