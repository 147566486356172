import React from 'react';

interface SearchFeedbackSuccessProps {
  successHeading: string;
  successMessage: string;
}
const SearchFeedbackSuccess = ({
  successHeading,
  successMessage,
}: SearchFeedbackSuccessProps): JSX.Element => {
  return (
    <div className="search-feedback__success ch-text--center sm:ch-pb--6 ch-ph--4 sm:ch-ph--10">
      <img alt="success" className="success-image ch-mb--2"></img>
      <h3>{successHeading}</h3>
      <p className="sm:ch-mb--0">{successMessage}</p>
    </div>
  );
};

export default SearchFeedbackSuccess;
