import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import useShortlist from './shortlistStore';

const ShortlistPopup = (): JSX.Element | null => {
  const {
    store: { shortlist },
  } = useShortlist();

  const { isNativeApp, shortlistEnabled } = acMobileFeatures();

  if (!isNativeApp() || shortlistEnabled() || shortlist.length === 0)
    return null;

  return (
    <a
      className="js-off--hide ch-btn ch-btn--primary ac-shortlist__indicator ac-animation--fade-in-from-bottom"
      href="/shortlist"
    >
      Shortlist
      <span className="ac-shortlist__count">{shortlist.length}</span>
    </a>
  );
};

export default ShortlistPopup;
