import { useState, useEffect } from 'react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

interface UseComparisonReturnValue {
  clearAll: () => void;
  handleComparison: (stockReference: string, isChecked: boolean) => void;
  stockRefs: string[];
}

const useComparison = (): UseComparisonReturnValue => {
  const COMPARISON_CAP = 4;
  const [stockRefs, setStockRefs] = useState<string[]>([]);
  const [stockRefsToRemove, setStockRefsToRemove] = useState<string[]>([]);

  const handleRemove = () => {
    if (stockRefsToRemove.length <= 0) return;

    setStockRefs(
      stockRefs.filter((stockRef) => !stockRefsToRemove.includes(stockRef))
    );
    setStockRefsToRemove([]);
  };

  useEffect(() => {
    EventEmitter.on(EVENT_TYPES.modalClosed, handleRemove);
    return () => {
      EventEmitter.off(EVENT_TYPES.modalClosed);
    };
  }, [stockRefsToRemove]);

  const removeFromComparison = (stockRef: string) => {
    setStockRefsToRemove((stockRefsToRemove) => [
      ...stockRefsToRemove,
      stockRef,
    ]);
  };

  useEffect(() => {
    EventEmitter.on(EVENT_TYPES.removeFromComparison, removeFromComparison);
    return () => {
      EventEmitter.off(EVENT_TYPES.removeFromComparison);
    };
  }, []);

  const handleComparison = (
    stockReference: string,
    isChecked: boolean
  ): void => {
    if (
      stockRefs.length >= COMPARISON_CAP &&
      !stockRefs.includes(stockReference)
    )
      return;

    isChecked
      ? setStockRefs([...stockRefs, stockReference])
      : setStockRefs(
          stockRefs.filter((stockRef) => stockRef !== stockReference)
        );
  };

  const clearAll = (): void => {
    setStockRefs([]);
  };

  return {
    clearAll,
    handleComparison,
    stockRefs,
  };
};

export default useComparison;
