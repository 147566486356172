import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import Modal from '@components/application/modal';
import VehicleComparisonContainer from './modal/vehicleComparisonContainer';

const ComparePanel = ({ clearAll, stockRefs }) => {
  const openVehicleComparisonModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, 'vehicle-comparison');
  };

  return (
    <>
      <div className="compare-container ch-bg--white ac-animation--fade-in-from-bottom">
        <div className="ch-container">
          <div className="ch-pv--1 ch-mh--n1 ch-text--center ch-display--flex ch-flex-flow--row-wrap ch-justify-content--center ch-align-items--center sm:ch-pv--3">
            <p className="ch-lh--body ch-mv--1 ch-mh--2">
              You can select up to 4 vehicles to compare
            </p>
            <div className="ch-display--flex ch-flex-flow--row-wrap ch-flex--auto sl:ch-flex--none">
              <button
                className="ch-btn ch-btn--success ch-flex--auto ch-ma--1 ch-ph--3 sm:ch-ph--6"
                onClick={openVehicleComparisonModal}
                type="button"
              >
                Compare {stockRefs.length} of 4
              </button>
              <button
                className="ch-btn ch-flex--auto ch-ma--1 ch-ph--3 sm:ch-ph--6"
                onClick={clearAll}
                type="button"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal hideClose id="vehicle-comparison">
        <VehicleComparisonContainer stockRefs={stockRefs} />
      </Modal>
    </>
  );
};

ComparePanel.propTypes = {
  clearAll: PropTypes.func,
  stockRefs: PropTypes.array,
};

export default ComparePanel;
