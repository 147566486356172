import React from 'react';
import PropTypes from 'prop-types';

const FilterToggle = ({ checked, id, name, onChange }) => {
  const handleFilterToggleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(id, !checked);
    }
  };

  return (
    <label
      aria-checked={checked}
      className={`filter-toggle ${checked && 'filter-toggle--checked'}`}
      onKeyDown={handleFilterToggleKeyDown}
      role="checkbox"
      tabIndex="0"
    >
      <input
        checked={checked.length > 0 && JSON.parse(checked)}
        className="ch-reader"
        onChange={() => onChange(id, !checked)}
        tabIndex="-1"
        type="checkbox"
      />
      {name}
      <span className="filter-toggle__indicator">
        <span className="filter-toggle__indicator-inner" />
      </span>
    </label>
  );
};

export default FilterToggle;

FilterToggle.defaultProps = {
  checked: false,
};

FilterToggle.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
