import React from 'react';
import PropTypes from 'prop-types';

const VehicleComparisonSummary = ({ categories, vehicleData }) => {
  const buildCells = (category) => {
    return vehicleData.map((vehicle, index) => {
      const summaryValue = vehicle.summary[category];
      return (
        <td
          className="compare-width ch-pa--1 ch-mb--1"
          key={'cell' + category + index}
        >
          <div className="ch-width--12 ch-pb--2 ch-bb--1 ch-bc--grey-3">
            {summaryValue || '-'}
          </div>
        </td>
      );
    });
  };

  return (
    <>
      <div className="compare__subheader ch-ph--1 lg:ch-ph--3">
        <h3 className="ch-pb--2 ch-fs--3 ch-fs--4 ch-bb--1 ch-bc--grey-3 ch-mt--4">
          Summary
        </h3>
      </div>
      <table className="compare__table ch-width--12">
        {categories.map((category) => (
          <tbody key={category}>
            <tr>
              <th className="ch-ph--1 lg:ch-ph--3" colSpan={vehicleData.length}>
                {category}
              </th>
            </tr>
            <tr className="lg:ch-ph--2">{buildCells(category)}</tr>
          </tbody>
        ))}
      </table>
    </>
  );
};

VehicleComparisonSummary.propTypes = {
  categories: PropTypes.array,
  vehicleData: PropTypes.array,
};

export default VehicleComparisonSummary;
