import { StateCreator, StoreMutatorIdentifier } from 'zustand';
import { ShortlistStoreState } from './shortlistStore';
import { AccountEvent, AccountEventMessage } from '../account/accountListener';

type SyncTabs = <
  T extends ShortlistStoreState,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>
) => StateCreator<T, Mps, Mcs>;

type SyncTabsImpl = <T extends ShortlistStoreState>(
  f: StateCreator<T, [], []>
) => StateCreator<T, [], []>;

const syncTabsImpl: SyncTabsImpl = (f) => (set, get, store) => {
  type T = ReturnType<typeof f>;
  if (typeof window === 'undefined') return f(set, get, store);

  const shortlistEvents = new BroadcastChannel('shortlistEvents');
  const accountEvents = new BroadcastChannel('accountEvents');

  //listening for updates to the shortlist store
  shortlistEvents.onmessage = ({
    data: { event, shortlist, tabID },
  }: MessageEvent<{ event: string; shortlist: string[]; tabID: string }>) => {
    if (event === 'set' && tabID !== window.tabID) {
      set({ shortlist } as Partial<T>);
    }
  };

  //listening for logout events and clearing shortlist across open tabs to avoid any overwriting
  accountEvents.onmessage = ({
    data: { event, tabID },
  }: MessageEvent<AccountEventMessage>) => {
    if (event === AccountEvent.logout && tabID !== window.tabID) {
      set({ shortlist: [] as string[] } as Partial<T>);
    }
  };

  //extending set to broadcast the updated shortlist to open tabs
  const setAndBroadcast: typeof set = (...a) => {
    set(...a);
    const shortlist = get().shortlist;
    shortlistEvents.postMessage({
      event: 'set',
      shortlist,
      tabID: window.tabID,
    });
  };

  return f(setAndBroadcast, get, store);
};

export default syncTabsImpl as unknown as SyncTabs;
