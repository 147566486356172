import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

const MonthlyPayment = ({
  financeHeading,
  stockReference,
  vehicleUrl,
  showTooltip,
  prefix,
  amount,
}) => {
  const openModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, 'finance-illustration', {
      financeHeading: financeHeading,
      stockReference: stockReference,
      vehicleUrl: vehicleUrl,
    });
  };

  const renderInlineIllustration = () => {
    if (showTooltip && stockReference) {
      return (
        <button
          className="ac-finance__tooltip ac-finance__tooltip--hover"
          onClick={openModal}
          type="button"
        >
          <span className="ch-reader">
            View this car’s full finance example
          </span>
        </button>
      );
    }
  };

  return (
    <span className="ac-pricing__monthly">
      {prefix && <span className="ac-pricing__prefix">{prefix}</span>}
      <Money amount={amount} />
      <span className="ac-pricing__suffix">
        {' '}
        Per month{renderInlineIllustration()}
      </span>
    </span>
  );
};

MonthlyPayment.propTypes = {
  amount: PropTypes.number.isRequired,
  financeHeading: PropTypes.string,
  prefix: PropTypes.string,
  showTooltip: PropTypes.bool,
  stockReference: PropTypes.string,
  vehicleUrl: PropTypes.string,
};

export default MonthlyPayment;
