const GA = { all: '_all', divider: '|' };

const additionalFilterChangesFor = (filters, currentVehicleSearch) => {
  const {
    branchName,
    deliveryOnly,
    deposit,
    location,
    page,
    paymentType,
    sortOrder,
    searchType,
  } = filters;
  const {
    appliedFilters: { paymentType: currentPaymentType },
  } = currentVehicleSearch;

  return {
    ...filters,
    ...(!page && { page: 1 }),
    ...((searchType === 'Vans' || searchType === 'Motability') &&
      currentPaymentType === 'monthly' && {
        maxPrice: '',
        minPrice: '',
        paymentType: 'cash',
        sortOrder: 'price_up',
      }),
    ...(branchName === '' && { branchId: '' }),
    ...(paymentType === 'monthly' && sortOrder === 'monthly_payment_up'),
    ...(paymentType === 'monthly' && sortOrder === 'monthly_payment_down'),
    ...(paymentType === 'cash' && sortOrder === 'price_up'),
    ...(paymentType === 'cash' && sortOrder === 'price_down'),
    ...((location === '' || deliveryOnly === '') && { resetLocation: true }),
    ...(deposit === '' && { resetDeposit: true }),
  };
};

const createMakeModelGAStr = (makeModel) =>
  Object.keys(makeModel)
    .map((make) => {
      const modelKeys = Object.keys(makeModel[make].models);

      if (modelKeys.length > 0) {
        return Object.keys(makeModel[make].models)
          .map((model) => {
            const trimKeys = Object.keys(makeModel[make].models[model].trims);
            return `${make} ${model} ${
              (trimKeys.length && trimKeys.join(', ')) || GA.all
            }`;
          })
          .join(GA.divider);
      }
      return `${make} ${GA.all}`;
    })
    .join(GA.divider);

const isEqual = (a, b) => {
  if (Array.isArray(a)) {
    if (!Array.isArray(b) || a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) return false;
    }
    return true;
  }
  if (typeof a === 'object' && a !== null && b !== null) {
    if (!(typeof b === 'object')) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    for (const key in a) {
      if (!isEqual(a[key], b[key])) return false;
    }
    return true;
  }
  return a === b;
};

export { additionalFilterChangesFor, createMakeModelGAStr, isEqual };
