import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import Auth from '@javascript/lib/auth';

const LogoutModal = () => {
  const logout = () => {
    new Auth()
      .initializeAuthClient(window.authOptions)
      .then((client) => client.logout());
  };
  const closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);
  return (
    <div className="ch-modal ch-modal--drawer log-out-modal ch-ph--3 ch-pv--4">
      <p className="ch-fs--5 ch-fw--500">Are you sure?</p>
      <button
        className="ch-btn ch-btn--success ch-pa--2 ch-mv--2"
        onClick={logout}
        type="button"
      >
        Log out
      </button>
      <button className="ch-btn ch-pa--2" onClick={closeModal} type="button">
        Not now
      </button>
    </div>
  );
};

export default LogoutModal;
