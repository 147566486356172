import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const CashPrice = ({ amount, prefix, suffix }) => {
  return (
    <span className="ac-pricing__cash">
      <span className="ac-pricing__prefix">{prefix} </span>
      <Money amount={amount} />
      {suffix && <span className="ac-pricing__suffix">{suffix}</span>}
    </span>
  );
};

CashPrice.propTypes = {
  amount: PropTypes.number.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.string,
};

CashPrice.defaultProps = {
  prefix: 'Only',
};

export default CashPrice;
