import React, { useMemo } from 'react';
import RadioInput from '../../application/input/radioInput';

interface SearchFeedbackOptionsProps {
  error: boolean;
  handleChange: (e: React.ChangeEvent<HTMLFormElement>) => void;
  selectedAnswer: string;
}

const answers: string[] = [
  'Price',
  'Body type',
  'Age',
  'Location',
  'Make / Model',
];
const SearchFeedbackOptions = ({
  error,
  handleChange,
  selectedAnswer,
}: SearchFeedbackOptionsProps): JSX.Element => {
  const randomisedAnswers = useMemo(() => {
    return answers.sort(() => Math.random() - 0.5);
  }, []);

  return (
    <div className="search-feedback-buttons">
      {selectedAnswer !== 'Other' && error && (
        <p className="ch-color--ac-red ch-fw--500 ch-fs--3">
          Please select an option
        </p>
      )}
      {randomisedAnswers.concat('Other').map((answer, index) => (
        <div className="ch-mv--2 ch-width--12" key={`search-feedback-${index}`}>
          <RadioInput
            checked={selectedAnswer === answer}
            id={`search-feedback-radio-${index}`}
            labelText={answer}
            name="Vehicle search criteria feedback"
            onChange={handleChange}
            value={answer}
          />
        </div>
      ))}
    </div>
  );
};

export default SearchFeedbackOptions;
