const ShortlistHeader = ({
  onlySoldVehicles,
  shortlist,
  usingApiStorage,
}: {
  onlySoldVehicles: boolean;
  shortlist: string[];
  usingApiStorage: boolean;
}): JSX.Element => {
  if (usingApiStorage) {
    return (
      <h1 className="ch-fs--5 sm:ch-fs--6 ch-mv--4">
        {onlySoldVehicles
          ? 'All of your saved vehicles have now sold'
          : `Your saved vehicles`}
      </h1>
    );
  }

  return (
    <h1 className="ch-fs--5 sm:ch-fs--6 ch-mv--4">
      {shortlist.length} {shortlist.length === 1 ? 'vehicle' : 'vehicles'} in
      your shortlist
    </h1>
  );
};

export default ShortlistHeader;
