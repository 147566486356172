import { fetchVehiclesByStockReference } from '@lib/requests/stockReference';
import localStorageAvailable from '@helpers/localStorage';

const LOCAL_STORAGE_CAP = 12;

export const getRecentlyViewed = () => {
  return JSON.parse(localStorage.getItem('recentlyViewed')) || [];
};

export const fetchRecentlyViewed = () => {
  const EMPTY_SEARCH_RESULTS = Promise.resolve({ body: [] });
  if (!localStorageAvailable()) return EMPTY_SEARCH_RESULTS;
  const stockRefs = getRecentlyViewed();
  return !stockRefs.length
    ? EMPTY_SEARCH_RESULTS
    : fetchVehiclesByStockReference(stockRefs);
};

export const vehiclePreviouslyViewed = (recentlyViewed, stockRef) => {
  return recentlyViewed.includes(stockRef);
};

export const makeMostRecentlyViewed = (recentlyViewed, stockRef) => {
  const updatedRecentlyViewed = recentlyViewed.filter((viewedRef) => {
    return viewedRef !== stockRef;
  });
  updatedRecentlyViewed.unshift(stockRef);
  return updatedRecentlyViewed;
};

export const overStorageCap = (recentlyViewed) => {
  return recentlyViewed.length > LOCAL_STORAGE_CAP;
};

export const addToRecentlyViewed = (stockRef) => {
  const recentlyViewed = getRecentlyViewed();

  if (vehiclePreviouslyViewed(recentlyViewed, stockRef)) {
    return localStorage.setItem(
      'recentlyViewed',
      JSON.stringify(makeMostRecentlyViewed(recentlyViewed, stockRef))
    );
  }

  recentlyViewed.unshift(stockRef);

  if (overStorageCap(recentlyViewed)) {
    recentlyViewed.pop();
  }

  localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
};
