import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import classNames from 'classnames';

export default class Tooltip extends Component {
  static displayName = 'Tooltip';
  static propTypes = {
    additionalClass: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    title: PropTypes.string,
    windowWidth: PropTypes.number,
  };

  state = {
    isOpen: false,
    positionOffset: 0,
  };

  componentDidMount() {
    EventEmitter.on(EVENT_TYPES.closeTooltips, this.checkForClosure);
  }

  componentWillUnmount() {
    EventEmitter.off(EVENT_TYPES.closeTooltips);
  }

  openTooltip = () => {
    this.setState({ isOpen: true }, this.checkPosition);
    this.closeOtherTooltips();
  };

  closeTooltip = () => this.setState({ isOpen: false });

  toggleTooltip = () =>
    this.state.isOpen ? this.closeTooltip() : this.openTooltip();

  closeOtherTooltips = () =>
    EventEmitter.emit(EVENT_TYPES.closeTooltips, this.props.id);

  checkForClosure = (originId) => {
    if (this.props.id !== originId) return this.closeTooltip();
  };

  checkPosition = () => {
    const viewportWidth = this.props.windowWidth
      ? this.props.windowWidth
      : window.innerWidth;
    const rightPosition = this.tooltip.getBoundingClientRect().right;
    const difference = Math.floor(viewportWidth - rightPosition);

    if (difference < 0) {
      const padding = 14;
      this.setState({ positionOffset: difference - padding });
    }
  };

  getStyle = () => {
    if (this.state.positionOffset < 0)
      return { transform: `translateX(${this.state.positionOffset}px)` };
  };

  render() {
    return (
      <span className="ac-tooltip__anchor">
        <button
          className={classNames(
            `ac-tooltip__link ${this.props.additionalClass}`,
            {
              'ac-tooltip__link--open': this.state.isOpen,
            }
          )}
          onClick={this.toggleTooltip}
          ref={(link) => (this.link = link)}
          type="button"
        >
          {this.props.title}
        </button>
        {this.state.isOpen && (
          <div
            className="ac-tooltip"
            ref={(tooltip) => (this.tooltip = tooltip)}
            style={this.getStyle()}
          >
            <button
              className="ac-tooltip__close"
              onClick={this.closeTooltip}
              ref={(close) => (this.close = close)}
              type="button"
            >
              <span className="ch-reader">Close</span>
            </button>
            {this.props.children}
          </div>
        )}
      </span>
    );
  }
}
