import React from 'react';
import PropTypes from 'prop-types';
import VehicleResult from './vehicleResult';
import NoResults from './noResults';
import StockAlertMessage from './stockAlertMessage';

const SharedShortlistVehicleResults = ({
  clickAndCollectDetails,
  count,
  distances,
  experiments,
  searchResults,
  authenticityToken,
}) => {
  return (
    <div className="ac-results">
      {count > 0 &&
        searchResults.map((result) => (
          <VehicleResult
            {...result}
            authenticityToken={authenticityToken}
            clickAndCollectDetails={clickAndCollectDetails}
            distances={distances}
            experiments={experiments}
            key={result.stockReference}
          ></VehicleResult>
        ))}
      {count === 0 && (
        <NoResults
          experiments={experiments}
          stockAlertMessage={<StockAlertMessage />}
        />
      )}
    </div>
  );
};

SharedShortlistVehicleResults.displayName = 'SharedShortlistVehicleResults';
export default SharedShortlistVehicleResults;

SharedShortlistVehicleResults.propTypes = {
  authenticityToken: PropTypes.string,
  clickAndCollectDetails: PropTypes.object,
  count: PropTypes.number,
  distances: PropTypes.object,
  experiments: PropTypes.object,
  searchResults: PropTypes.array,
};
