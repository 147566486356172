import {
  addToShortlist,
  clearShortlist,
  getShortlist,
  mergeShortlist,
  removeFromShortlist,
  replaceShortlist,
} from '@javascript/lib/requests/shortlist';
import {
  StateCreator,
  StoreMutatorIdentifier,
  Mutate,
  StoreApi,
} from 'zustand';
import { ShortlistStoreState, StockReferences } from './shortlistStore';
import { openToast, ToastType } from '../application/toast';

type Write<T extends object, U extends object> = Omit<T, keyof U> & U;

type Cast<T, U> = T extends U ? T : U;

type PeristConfig = {
  hasHydrated: () => boolean;
  rehydrate: () => Promise<void>;
};

declare module 'zustand' {
  interface StoreMutators<S, A> {
    'ac/apiShortlist': Write<
      Cast<S, object>,
      { hasHydrated: boolean; persist: A }
    >;
  }
}

type ApiStorage = <
  T extends ShortlistStoreState,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, [...Mps, ['ac/apiShortlist', PeristConfig]], Mcs>
) => StateCreator<T, Mps, [['ac/apiShortlist', PeristConfig], ...Mcs]>;

type ApiStorageImpl = <T extends ShortlistStoreState>(
  f: StateCreator<T, [], []>
) => StateCreator<T, [], []>;

const apiStorageImpl: ApiStorageImpl = (f) => (set, get, _store) => {
  type T = ReturnType<typeof f> & { hasHydrated: boolean };

  let currentRequest: Promise<void> | null = null;

  //this function gets called on both store types when zustand is in the browser,
  //so we can hook into it for an initial fetch
  const rehydrate = async () => {
    //checking to see if a shortlist fetch is in progress to avoid multiple fetching
    if (currentRequest !== null) {
      return currentRequest;
    }
    //if logged-in user had vehicles in localStorage,
    //migratedShortlist() will have set them as initial state
    const locallyStoredStockRefs = get().shortlist;
    //depending on whether or not we have localStorage stockRefs
    //we either merge them with the ones held in the shortlist API
    //or just retrieve what the API has
    const retrieveShortlist = () =>
      locallyStoredStockRefs.length > 0
        ? mergeShortlist(locallyStoredStockRefs)
        : getShortlist();

    currentRequest = retrieveShortlist()
      .then((vehicles) => {
        const sold = vehicles
          .filter((vehicle) => vehicle.sold)
          .map((vehicle) => vehicle.stockReference);
        const shortlist = vehicles
          .filter((vehicle) => !vehicle.sold)
          .map((vehicle) => vehicle.stockReference);

        set({ ...get(), shortlist, sold, hasHydrated: true });
      })
      .catch((e) => {
        //this error is thrown when we try to parse Auth0's unauthorised response (which is HTML) to JSON
        const auth0UnauthorisedError = e.message.includes('Unexpected token');

        //we know about that error, so handle everything else
        if (!auth0UnauthorisedError) {
          if (window.newrelic) {
            window.newrelic.noticeError(e);
          }

          openToast({
            message: 'We had a problem reaching your shortlist. Try again.',
            toastType: ToastType.warning,
            additionalStyling: 'ac-toast--shortlist__error',
          });
        }
      });

    return currentRequest;
  };

  const extendedSet: typeof set = async (...a) => {
    const oldShortlist = get().shortlist;
    set(...a);

    //update the API with the latest version of the shortlist
    //but only in the tab that's visible to the user
    if (!document.hidden) {
      //we need to work out if we need to call the shortlist API with add, remove, clear or replace
      let updateShortlist = null;
      //and what stockRefs need to be sent if so
      let stockRefs: StockReferences | null = null;

      const newShortlist = get().shortlist;

      const shortlistDifference = newShortlist.length - oldShortlist.length;

      //if there's been no change, no need to do anything
      if (shortlistDifference === 0) return;

      //if the shortlist difference is positive, it's an addition
      const addingToShortlist = shortlistDifference > 0;

      //if there's a single difference between the two shortlists we're either adding or removing
      if (Math.abs(shortlistDifference) === 1) {
        stockRefs = (
          addingToShortlist
            ? newShortlist.find((stockRef) => !oldShortlist.includes(stockRef))
            : oldShortlist.find((stockRef) => !newShortlist.includes(stockRef))
        ) as StockReferences;

        updateShortlist = addingToShortlist
          ? addToShortlist
          : removeFromShortlist;
      } else {
        // at this point there are multiple stock references different between the old and new shortlist
        // so the shortlist has either been cleared, or we need to use the replace endpoint
        // either way, the current state of the shortlist needs to be sent
        stockRefs = newShortlist;
        updateShortlist =
          newShortlist.length === 0 ? clearShortlist : replaceShortlist;
      }

      updateShortlist(stockRefs).then((response) => {
        //if this fails, revert the shortlist to the previous version
        //to keep UI in sync with API
        if (!response.ok) {
          set({ shortlist: oldShortlist } as Partial<T>);
          const message = `We had a problem ${
            addingToShortlist ? 'adding' : 'removing'
          } that ${
            addingToShortlist ? 'to' : 'from'
          } your shortlist. Try again.`;

          openToast({
            message,
            toastType: ToastType.warning,
            additionalStyling: 'ac-toast--shortlist__error',
          });
        }
      });
    }
  };

  const store = _store as Mutate<
    StoreApi<T>,
    [['ac/apiShortlist', PeristConfig]]
  >;

  store.persist = {
    rehydrate,
    hasHydrated: () => store.getState().hasHydrated,
  };
  return f(extendedSet, get, store);
};

export default apiStorageImpl as unknown as ApiStorage;
