import React from 'react';
import PropTypes from 'prop-types';
import Money from '@components/application/money';

const VehicleComparisonFinance = ({ vehicleData }) => {
  const DATA_TYPE = {
    FINANCE: 'finance',
    PRICING: 'pricing',
  };

  const renderCellData = (object, data) => {
    switch (object) {
      case DATA_TYPE.PRICING:
        return (
          <span className="ac-pricing ch-fs--4">
            <Money amount={data} />
          </span>
        );
      case DATA_TYPE.FINANCE:
        return (
          <span className="ch-text--uppercase">{data.replace('_', ' ')}</span>
        );
      default:
        break;
    }
  };

  const renderCell = (object, category) => {
    return vehicleData.map((vehicle, index) => {
      return (
        <td
          className="compare-width ch-pa--1 ch-mb--1"
          key={'cell' + category + index}
        >
          <div className="ch-width--12 ch-pb--2 ch-bb--1 ch-bc--grey-3">
            {vehicle[object] && vehicle[object][category]
              ? renderCellData(object, vehicle[object][category])
              : '-'}
          </div>
        </td>
      );
    });
  };

  return (
    <>
      <div className="compare__subheader ch-ph--1 lg:ch-ph--3">
        <h2 className="ch-pb--2 ch-fs--4 ch-bb--1 ch-bc--grey-3 ch-mt--2">
          Finance
        </h2>
      </div>
      <table className="compare__table ch-width--12">
        <tbody>
          <tr>
            <th className="ch-ph--1 lg:ch-ph--3" colSpan={vehicleData.length}>
              Finance type
            </th>
          </tr>
          <tr className="lg:ch-ph--2">
            {renderCell(DATA_TYPE.FINANCE, 'cheapest_finance')}
          </tr>
          <tr>
            <th className="ch-ph--1 lg:ch-ph--3" colSpan={vehicleData.length}>
              Deposit
            </th>
          </tr>
          <tr className="lg:ch-ph--2">
            {renderCell(DATA_TYPE.PRICING, 'deposit')}
          </tr>
          <tr>
            <th className="ch-ph--1 lg:ch-ph--3" colSpan={vehicleData.length}>
              Monthly cost
            </th>
          </tr>
          <tr className="lg:ch-ph--2">
            {renderCell(DATA_TYPE.PRICING, 'monthlyPayment')}
          </tr>
        </tbody>
      </table>
    </>
  );
};

VehicleComparisonFinance.propTypes = {
  vehicleData: PropTypes.array,
};

export default VehicleComparisonFinance;
