import request from 'superagent';

const fetchVehiclesByStockReference = (
  stockReferences: string[],
  isVehicleData?: boolean
) => {
  const params = {
    stock_references: stockReferences.join(' '),
    ...(isVehicleData && { vcdata: true }),
  };

  return request.get('/api/vehicles/search').query(params);
};

export { fetchVehiclesByStockReference };
