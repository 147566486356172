import React from 'react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import Modal from '../modal';
import PropTypes from 'prop-types';

const BigGiftGiveawayBanner = ({ isProductPage }) => {
  const openModal = () =>
    EventEmitter.emit(EVENT_TYPES.openModal, 'big-gift-giveaway');

  return (
    <>
      <button
        className="bgg-banner bgg-banner--search ch-hand ch-rounded--md"
        onClick={openModal}
        type="button"
      >
        <div className="ch-color--white ch-pa--3 ch-width--12 ch-fs--2 md:ch-fs--4 ch-fw--500">
          Buy {isProductPage ? 'this' : 'a used'} car between
          11th&nbsp;-&nbsp;18th November and enjoy a free gift!{' '}
          <u className="ch-fw--400 ch-fs--2 md:ch-fs--3 ch-display--inline-flex">
            Find out more
          </u>
        </div>
      </button>
      <Modal id="big-gift-giveaway">
        <div className="ch-modal ch-modal--xs bgg-modal">
          <div className="ch-modal__body ch-fs--3">
            <div className="bgg-modal__header ch-text--center ch-pa--3">
              <img
                alt="The Big Gift Giveaway"
                className="bgg-modal__logo"
                height="52"
                loading="lazy"
                width="124"
              />
            </div>
            <div className="ch-text--center ch-pv--4 ch-ph--6">
              <p className="ch-fw--500 ch-mb--3">
                Choose one of these gifts when you buy a used car:
              </p>
              <ul className="ch-text--left bgg-modal__item-list">
                <li className="ch-mb--3">
                  <img
                    alt=""
                    className="bgg-modal__item-image-1 ch-mr--3"
                    height="80"
                    loading="lazy"
                    width="80"
                  />
                  £50 M&S E-Gift Card
                </li>
                <li className="ch-mb--3">
                  <img
                    alt=""
                    className="bgg-modal__item-image-2 ch-mr--3"
                    height="80"
                    loading="lazy"
                    width="80"
                  />
                  £50 Argos eGift Card
                </li>
                <li className="ch-mb--3">
                  <img
                    alt=""
                    className="bgg-modal__item-image-3 ch-mr--3"
                    height="80"
                    loading="lazy"
                    width="80"
                  />
                  £50 Love2shop e-Gift Card
                </li>
              </ul>
              <a
                className="ch-fw--500 bgg-modal__link"
                href="/the-big-gift-giveaway"
              >
                Find out more
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

BigGiftGiveawayBanner.propTypes = {
  isProductPage: PropTypes.bool,
};

export default BigGiftGiveawayBanner;
