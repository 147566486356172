export default function onAnimationEnd<T extends HTMLElement>(
  callback: () => void,
  onError?: (e: Error) => void
): (element: T) => void | undefined {
  return (element: T) => {
    if (!element) return;
    Promise.all(element.getAnimations().map((animation) => animation.finished))
      .then(callback)
      .catch((e) => {
        if (onError) return onError(e);
      });
  };
}
