import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from '../application/progressiveImage';
import GalleryTrigger from '../application/galleryTrigger';

const ImageThumbnail = ({
  height,
  imageCount,
  imagePath,
  launchImageGallery,
  placeholder,
  stockReference,
  width,
}) => {
  return (
    <div className="ac-imagethumbnail">
      {imagePath ? (
        <div className="js-off--hide">
          <ProgressiveImage
            height={height}
            image={imagePath}
            placeholder={placeholder}
            width={width}
          />
        </div>
      ) : (
        <div className="ac-imagethumbnail__image-placeholder" />
      )}
      <GalleryTrigger
        imageCount={imageCount}
        launchImageGallery={launchImageGallery}
        originPage={'Product'}
        stockReference={stockReference}
      />
    </div>
  );
};

ImageThumbnail.propTypes = {
  height: PropTypes.number,
  imageCount: PropTypes.number,
  imagePath: PropTypes.string,
  launchImageGallery: PropTypes.bool,
  placeholder: PropTypes.string,
  stockReference: PropTypes.string,
  width: PropTypes.number,
};

export default ImageThumbnail;
