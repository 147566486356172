import React, { useState } from 'react';
import MakeAndModelSelector from '../homepage/makeAndModelSelector';
import PropTypes from 'prop-types';

const OffersNavigation = ({
  baseUrl,
  makesAndModels,
  makesDisplay,
  selectedMake,
  selectedModel,
}) => {
  const [makeModel, setMakeModel] = useState({
    make: selectedMake,
    model: selectedModel,
  });

  const modelSelected = makeModel.model !== ('' || '_all');

  const handleMakeModelSelect = (event) => {
    event.target.id === 'make'
      ? setMakeModel({ [event.target.id]: event.target.value, model: '_all' })
      : setMakeModel({ ...makeModel, [event.target.id]: event.target.value });
  };

  const handleModelText = () => {
    return makeModel.make === 'motorstore' || makeModel.make === 'motability'
      ? 'All makes'
      : 'All models';
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (modelSelected) {
      window.location.href = `${baseUrl}/${makeModel.make}/${makeModel.model}`;
    } else {
      window.location.href = `${baseUrl}/${makeModel.make}`;
    }
  };

  return (
    <div className="ac-searchmask ch-pb--2">
      <form onSubmit={handleSubmit}>
        <input name="utf8" type="hidden" value="✓" />
        <div className="ch-row ac-row--compact">
          <MakeAndModelSelector
            handleSelect={handleMakeModelSelect}
            makeModel={makesAndModels}
            makesDisplay={makesDisplay}
            modelText={handleModelText()}
            selectedMake={makeModel.make}
            selectedModel={makeModel.model}
          />

          <div className="xs:ch-col--12 sm:ch-col--2 ac-col--compact">
            <button
              className="ch-btn ch-btn--cta ch-btn--block ch-mt--2 sm:ch-mt--0"
              disabled={
                makeModel.model === selectedModel &&
                makeModel.make === selectedMake
              }
              id="search-button"
              tabIndex="0"
              type="submit"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OffersNavigation;

OffersNavigation.propTypes = {
  baseUrl: PropTypes.string,
  makesAndModels: PropTypes.object,
  makesDisplay: PropTypes.object,
  selectedMake: PropTypes.string,
  selectedModel: PropTypes.string,
};
