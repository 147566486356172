import { EventEmitter as EE } from 'eventemitter3';

export const EVENT_TYPES = {
  calculatedHpUpdate: 'calculatedHpUpdate',
  calculatedPcpUpdate: 'calculatedPcpUpdate',
  changedFinance: 'changedFinance',
  submittedFinance: 'submittedFinance',
  closeModal: 'closeModal',
  modalClosed: 'modalClosed',
  closeTooltips: 'closeTooltips',
  deliveryPreferenceUpdate: 'deliveryPreferenceUpdate',
  failedHpUpdate: 'failedHpUpdate',
  failedPcpUpdate: 'failedPcpUpdate',
  imgGalleryOpen: 'imageGalleryOpen',
  imgGalleryClosed: 'imageGalleryClosed',
  loadScript: 'loadScript',
  openModal: 'openModal',
  requestedPcpUpdate: 'requestedPcpUpdate',
  scriptLoaded: {
    google: 'google',
    worldpay: 'worldpay',
  },
  searchCriteriaChange: 'searchCriteriaChange',
  submitEnquiryForm: 'submitEnquiryForm',
  removeFromComparison: 'removeFromComparison',
  removeAllShortlist: 'removeAllShortlist',
};

const EventEmitter = new EE();

export default EventEmitter;
