import React from 'react';
import PropTypes from 'prop-types';
import GalleryTrigger from './galleryTrigger';

const ImageThumbnailExperiment = ({
  height,
  imageCount,
  imagePath,
  launchImageGallery,
  stockReference,
  width,
}) => {
  return (
    <div className="ac-imagethumbnail">
      {imagePath ? (
        <img
          alt=""
          className="ch-img--responsive"
          height={height}
          src={imagePath}
          width={width}
        />
      ) : (
        <div className="ac-imagethumbnail__image-placeholder" />
      )}
      <GalleryTrigger
        imageCount={imageCount}
        launchImageGallery={launchImageGallery}
        originPage={'Product'}
        stockReference={stockReference}
      />
    </div>
  );
};

ImageThumbnailExperiment.propTypes = {
  height: PropTypes.number,
  imageCount: PropTypes.number,
  imagePath: PropTypes.string,
  launchImageGallery: PropTypes.bool,
  stockReference: PropTypes.string,
  width: PropTypes.number,
};

export default ImageThumbnailExperiment;
