import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VehicleComparisonCard from './vehicleComparisonCard';
import VehicleComparisonSummary from './vehicleComparisonSummary';
import VehicleComparisonFinance from './vehicleComparisonFinance';
import VehicleComparisonDisclaimer from './vehicleComparsionDisclaimer';
import VehicleComparisonCardInfo from './vehicleComparisonCardInfo';
import VehicleComparisonCta from './vehicleComparisonCta';
import StatusBanner from '@components/application/vehicle/statusBanner';
import { FeedbackContainer } from '@components/feedback';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

const VehicleComparison = ({ comparisonData, updateComparisonVehicleData }) => {
  const [vehicleData, setVehicleData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showFinance, setShowFinance] = useState(false);

  useEffect(() => {
    const { categories, vehicleSearchData, showFinanceExamples } =
      comparisonData;
    setCategories(categories);
    setVehicleData(vehicleSearchData);
    setShowFinance(showFinanceExamples);
  }, [comparisonData]);

  const removeFromCompare = (stockReference) => {
    const filteredVehicleData = vehicleData.filter((vehicle) => {
      return vehicle.stockReference !== stockReference;
    });
    updateComparisonVehicleData(filteredVehicleData);

    EventEmitter.emit(EVENT_TYPES.removeFromComparison, stockReference);
  };

  return (
    <>
      <div className="compare__vehicles ch-display--flex ch-bg--grey-1 lg:ch-ph--2 lg:ch-pt--2">
        {vehicleData.map((vehicle) => (
          <VehicleComparisonCard
            firstPhoto={vehicle.firstPhoto}
            key={vehicle.stockReference}
            removeFromCompare={removeFromCompare}
            statusBanner={vehicle.isReserved && <StatusBanner {...vehicle} />}
            stockReference={vehicle.stockReference}
            title={vehicle.title.name}
          />
        ))}
      </div>
      <div className="ch-display--flex lg:ch-ph--2">
        {vehicleData.map((vehicle) => (
          <div
            className="compare-width ch-flex--none ch-display--flex ch-flex-flow--column-nowrap"
            key={`lowerRow ${vehicle.stockReference}`}
          >
            <VehicleComparisonCardInfo
              cashPrice={vehicle.pricing?.cashPrice}
              cashPricePrefix={vehicle.pricing?.cashPricePrefix}
              stockReference={vehicle.stockReference}
              variant={vehicle.title.variant}
            />
          </div>
        ))}
      </div>
      <div className="ch-display--flex lg:ch-ph--2">
        {vehicleData.map((vehicle) => (
          <VehicleComparisonCta
            isEligibleForDealBuilder={vehicle.isEligibleForDealBuilder}
            key={`cta ${vehicle.stockReference}`}
            stockReference={vehicle.stockReference}
          />
        ))}
      </div>
      {showFinance && <VehicleComparisonFinance vehicleData={vehicleData} />}
      <VehicleComparisonSummary
        categories={categories}
        vehicleData={vehicleData}
      />
      <div className="ch-display--flex lg:ch-ph--2">
        {vehicleData.map((vehicle) => (
          <VehicleComparisonCta
            isEligibleForDealBuilder={vehicle.isEligibleForDealBuilder}
            key={`cta ${vehicle.stockReference}`}
            stockReference={vehicle.stockReference}
          />
        ))}
      </div>
      <VehicleComparisonDisclaimer>
        <p className="ch-mb--0">
          * Depending on the age of the vehicle, MPG and CO2 may be quoted using
          either NEDC or WLTP testing standards.
          {categories.includes('Electric range (combined)') && (
            <span>
              {' '}
              Figures for fuel consumption, the CO2 produced, and/or the pure
              electric range are provided for comparative purposes only and are
              based on laboratory testing. The actual figures under real world
              driving conditions will depend upon a number of factors, including
              any accessories fitted after registration, variations in driving
              styles, weather conditions, vehicle load and the health of the
              battery.
            </span>
          )}
        </p>
      </VehicleComparisonDisclaimer>
      <div className="compare__feedback ch-ph--1 lg:ch-ph--3 ch-pv--2">
        <FeedbackContainer
          heading="Want to help us improve this feature?"
          name="Comparison v2"
          question="How would you rate your experience using our car comparison tool today?"
        />
      </div>
    </>
  );
};

VehicleComparison.propTypes = {
  comparisonData: PropTypes.shape({
    categories: PropTypes.array,
    vehicleSearchData: PropTypes.array,
    showFinanceExamples: PropTypes.bool,
  }),
  updateComparisonVehicleData: PropTypes.func,
};

export default VehicleComparison;
