import React from 'react';
import PropTypes from 'prop-types';
import BranchResult from './branchResult';
import BranchImage from './branchImage';
import BranchContent from './branchContent';
import BranchChips from './branchChips';
import BranchContact from './branchContact';

const BranchResults = ({ branchesSearchData, branchImageFallbackUrl }) => {
  return (
    <>
      <ul className="ch-display--flex ch-flex-flow--row-wrap ch-justify-content--center sm:ch-justify-content--start ch-mb--4 sm:ch-mh--n2">
        {branchesSearchData.map((searchData) => {
          const {
            branchName,
            branchAddress,
            firstBranchImageUrl,
            branchImageAttributes,
            branchChips,
            branchNumbers,
            formattedDistanceForBranch,
            seoFriendlyBranchUrlForBranch,
            unlockNumbers,
          } = searchData;

          return (
            <BranchResult
              content={
                <BranchContent
                  branchAddress={branchAddress}
                  branchChips={<BranchChips branchChips={branchChips} />}
                  branchContact={
                    <BranchContact
                      branchNumbers={branchNumbers}
                      unlockNumbers={unlockNumbers}
                    />
                  }
                  branchName={branchName}
                  formattedDistanceForBranch={formattedDistanceForBranch}
                  seoFriendlyBranchUrlForBranch={seoFriendlyBranchUrlForBranch}
                />
              }
              image={
                <BranchImage
                  branchImageAttributes={branchImageAttributes}
                  branchImageFallbackUrl={branchImageFallbackUrl}
                  firstBranchImageUrl={firstBranchImageUrl}
                />
              }
              key={branchName}
            />
          );
        })}
      </ul>
    </>
  );
};

export default BranchResults;

BranchResults.propTypes = {
  branchesSearchData: PropTypes.array,
  branchImageFallbackUrl: PropTypes.string,
};
