const ShortlistLoginCard = ({
  isAppUser,
  login,
}: {
  isAppUser: boolean;
  login: () => void;
}): JSX.Element => (
  <div className="ch-display--flex ch-align-items--center ch-bg--white ch-rounded--md ch-pv--3 sm:ch-pv--2 ch-pl--2 ch-pr--3 ch-mb--2 md:ch-mb--4">
    <span className="ch-circle ch-bg--grey-2 ch-display--inline-block ac-shortlist__log-in--icon" />
    <p className="ch-ml--2 md:ch-ml--3 ch-mb--0 ch-fs--3 md:ch-fs--4">
      Make shortlisting easier by logging into{' '}
      {isAppUser ? (
        'your account.'
      ) : (
        <button
          className="ch-btn ch-btn--link ch-ph--0 ch-fs--3 md:ch-fs--4"
          data-gtm-track="click_cta"
          data-gtm-track-label="Login - Shortlist Banner"
          onClick={login}
          type="button"
        >
          your account.
        </button>
      )}
    </p>
  </div>
);

export default ShortlistLoginCard;
