import React from 'react';
import PropTypes from 'prop-types';
import FilterHeader from '../filterHeader';
import FilterFooter from '../filterFooter';
import useScrollDetection from '@javascript/lib/hooks/useScrollDetection';
import { ButtonType } from '@javascript/types/ButtonType';

const FilterContainer = ({
  children,
  handleClearSelection,
  handleClick,
  showClearButton,
  isLoading = false,
  subtext,
  title,
  titleSubtext,
}) => {
  const [filterRef, isScrolled] = useScrollDetection();

  return (
    <div className="filter">
      <FilterHeader
        clearFiltersText={'Clear selection'}
        closeFilter={handleClick}
        resetFilters={handleClearSelection}
        showBackButton
        showClearButton={showClearButton}
        showCloseButton
        subtext={subtext}
        title={title}
        titleSubtext={titleSubtext}
      />
      <div
        className={`filter__body ${isScrolled && 'filter--scroll-shadow'}`}
        ref={filterRef}
      >
        {children}
      </div>
      <FilterFooter
        buttonText={'Done'}
        buttonType={ButtonType.primary}
        isLoading={isLoading}
        onClick={handleClick}
      />
    </div>
  );
};

export default FilterContainer;

FilterContainer.propTypes = {
  children: PropTypes.node,
  handleClearSelection: PropTypes.func,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showClearButton: PropTypes.bool,
  subtext: PropTypes.string,
  title: PropTypes.string,
  titleSubtext: PropTypes.string,
};
