import React from 'react';
import PropTypes from 'prop-types';

const VehicleTitle = ({ name, url, variant }) => {
  const renderVariant = () => {
    return <small className="ac-vehicle__title-variant">{variant}</small>;
  };

  return url ? (
    <h2 className="ac-vehicle__title">
      <a href={url}>
        {name} {renderVariant()}
      </a>
    </h2>
  ) : (
    <h1 className="ac-vehicle__title">
      {name} {renderVariant()}
    </h1>
  );
};

VehicleTitle.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

export default React.memo(VehicleTitle);
