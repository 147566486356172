import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GeolocationButton extends PureComponent {
  static propTypes = {
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    source: PropTypes.oneOf([
      'find a dealer',
      'branch search',
      'vehicle search',
    ]).isRequired,
  };

  state = {
    isLoading: false,
  };

  getBrowserLocation = () => {
    this.setState({ isLoading: true });

    navigator.geolocation.getCurrentPosition(
      this.handleSuccess,
      this.props.onError || this.handleError
    );
  };

  handleSuccess = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(location.coords.latitude),
      lng: parseFloat(location.coords.longitude),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      const place = results[0];
      const state = {
        place,
        location: 'Current Location',
      };

      if (status === 'OK' && place) {
        state.location = place.address_components.find(
          (data) => data.types[0] === 'postal_code'
        ).long_name;
      }

      this.props.onSuccess(state);
      this.setState({ isLoading: false });
    });
  };

  handleError = (positionError) => {
    /* eslint-disable no-alert, no-console */
    switch (positionError.code) {
      case positionError.TIMEOUT: {
        alert('Sorry, it took too long to get your location.');
        break;
      }
      case positionError.POSITION_UNAVAILABLE: {
        alert('Location information is unavailable.');
        break;
      }
      case positionError.PERMISSION_DENIED: {
        alert(
          'Please enable the permission to share location to use this feature.'
        );
        break;
      }
      default: {
        alert('Sorry, we were unable to get your location.');
        break;
      }
    }
    this.setState({ isLoading: false }, () =>
      console.error(positionError.message)
    );
    /* eslint-enable no-alert, no-console */
  };

  render() {
    if (this.props.source === 'find a dealer') {
      return (
        <button
          aria-label="use my location button"
          className="ch-btn ch-btn--link ch-centered ch-pt--2 ac__location-marker"
          data-test="currentLocationBtn"
          onClick={this.getBrowserLocation}
          onKeyPress={this.getBrowserLocation}
          tabIndex="0"
          type="button"
        ></button>
      );
    }
    if (this.state.isLoading) {
      return (
        <div className="ac-loading__wrapper">
          <span className="ac-loading__spinner ac-loading__spinner--inline" />
        </div>
      );
    }
    if (this.props.source === 'branch search') {
      return (
        <button
          aria-label="use my location button"
          className="ch-btn ch-btn--link ac-location__button"
          data-test="currentLocationBtn"
          onClick={this.getBrowserLocation}
          onKeyPress={this.getBrowserLocation}
          tabIndex="0"
          type="button"
        ></button>
      );
    }
    return (
      <div
        className="ac-location__button"
        onClick={this.getBrowserLocation}
        onKeyPress={this.getBrowserLocation}
        role="button"
        tabIndex="0"
      />
    );
  }
}

export default GeolocationButton;
