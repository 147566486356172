export type Endpoint = `/${string}`;

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export type QueryString = `?${string}`;
