import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CashPrice from '@components/application/pricing/cashPrice';
import Deposit from '@components/application/pricing/deposit';
import MonthlyPayment from '@components/application/pricing/monthlyPayment';
import Saving from '@components/application/pricing/saving';

export default class SearchPricing extends Component {
  static propTypes = {
    pricing: PropTypes.shape({
      businessContractHire: PropTypes.bool,
      businessVehicle: PropTypes.bool,
      cashPrice: PropTypes.number.isRequired,
      cashPricePrefix: PropTypes.string,
      cashPriceSuffix: PropTypes.string,
      deposit: PropTypes.number,
      extras: PropTypes.string,
      monthlyPayment: PropTypes.number,
      savingPrefix: PropTypes.string,
      saving: PropTypes.number,
      savingSuffix: PropTypes.string,
    }).isRequired,
    stockReference: PropTypes.string,
    vehicleUrl: PropTypes.string,
  };

  hideCashPrice = () =>
    this.props.pricing.cashPrice === 0 &&
    (this.props.pricing.businessVehicle ||
      this.props.pricing.businessContractHire);

  showMonthlyPayment = () =>
    this.props.pricing.monthlyPayment !== 0 &&
    this.props.pricing.monthlyPayment;

  render() {
    return (
      <div className="ch-bt--1 ch-bb--1 ch-bc--grey-3 ch-display--flex ch-flex-flow--column-wrap">
        <div className="ac-pricing">
          {!this.hideCashPrice() && (
            <>
              <CashPrice
                amount={this.props.pricing.cashPrice}
                prefix={this.props.pricing.cashPricePrefix}
                suffix={this.props.pricing.cashPriceSuffix}
              />
            </>
          )}
          {this.showMonthlyPayment() && (
            <div className="result__finance">
              <Deposit
                amount={this.props.pricing.deposit}
                isBusinessContractHire={this.props.pricing.businessContractHire}
              />
              <span className="ch-ph--1">+</span>
              <MonthlyPayment
                amount={this.props.pricing.monthlyPayment}
                showTooltip
                stockReference={this.props.stockReference}
                vehicleUrl={this.props.vehicleUrl}
              />
            </div>
          )}
        </div>
        {this.props.pricing.saving && (
          <Saving
            amount={this.props.pricing.saving}
            prefix={this.props.pricing.savingPrefix}
            suffix={this.props.pricing.savingSuffix}
          />
        )}
        {this.props.pricing.extras && (
          <span className="ac-pricing__extras">
            {this.props.pricing.extras}
          </span>
        )}
      </div>
    );
  }
}
