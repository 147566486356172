import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const DepositSetter = ({ deposit, handleChange }) => {
  const [activeDeposit, setActiveDeposit] = useState(deposit);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activeDeposit && !activeDeposit.match('^[0-9]*$')) {
      return setError(true);
    }
    setError(false);
    handleChange(activeDeposit);
  }, [activeDeposit]);

  return (
    <div className={error ? 'ch-form__group--error' : ''}>
      <label className="ch-fs--3 ch-mt--2" htmlFor="deposit">
        Deposit (£)
        <span className="ch-form__control-hint ch-pv--1">
          Enter the finance deposit that works for you.{' '}
          <span className="ch-display--inline-block">
            You can change this anytime.
          </span>
        </span>
      </label>
      {error && (
        <span className="ch-form__control-validation">
          Please enter a number
        </span>
      )}
      <input
        className="ch-form__control"
        id="deposit"
        inputMode="numeric"
        name="deposit"
        onChange={(e) => setActiveDeposit(e.target.value)}
        pattern="[0-9]*"
        placeholder="e.g. 249"
        type="text"
        value={activeDeposit}
      />
    </div>
  );
};

export default DepositSetter;

DepositSetter.propTypes = {
  deposit: PropTypes.string,
  handleChange: PropTypes.func,
};
