import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from '@components/application/progressiveImage';
import Glider from 'react-glider';
import { InView } from 'react-intersection-observer';

const ImageWrapperExperiment = ({
  hideImageSlider,
  imageCount,
  photos,
  resultIndex,
}) => {
  const skipLazyLoading = () => resultIndex === 0;

  return (
    <>
      {imageCount === 0 && <div className="result__image-no-vehicle" />}
      {imageCount > 0 && (
        <InView rootMargin="600px 0px 600px 0px">
          {({ inView, ref }) => (
            <div className="vehicle-slider" ref={ref}>
              {!inView || hideImageSlider ? (
                <img
                  alt=""
                  className="ch-img--responsive"
                  fetchpriority={skipLazyLoading() ? 'high' : 'medium'}
                  height="600"
                  loading={skipLazyLoading() ? 'eager' : 'lazy'}
                  src={photos[0]}
                  width="800"
                />
              ) : (
                <Glider hasDots>
                  {photos.map((photo, index) =>
                    index === 0 ? (
                      <img
                        alt=""
                        className="ch-img--responsive"
                        fetchpriority={skipLazyLoading() ? 'high' : 'medium'}
                        height="600"
                        key={photo}
                        loading={skipLazyLoading() ? 'eager' : 'lazy'}
                        src={photo}
                        width="800"
                      />
                    ) : (
                      <ProgressiveImage
                        height={600}
                        image={photo}
                        key={photo}
                        placeholder="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDgwMCA2MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZBRkFGQSIgZD0iTTAgMGg4MDB2NjAwSDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
                        width={800}
                      />
                    )
                  )}
                </Glider>
              )}
            </div>
          )}
        </InView>
      )}
    </>
  );
};

ImageWrapperExperiment.propTypes = {
  hideImageSlider: PropTypes.bool,
  imageCount: PropTypes.number,
  photos: PropTypes.array,
  resultIndex: PropTypes.number,
};

export default React.memo(ImageWrapperExperiment);
