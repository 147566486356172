import BackButton from '../application/backButton';

const ShortlistError = (): JSX.Element => (
  <div className="ch-container ch-display--flex ch-flex-direction--column ch-align-items--center ch-text--center ch-pt--3">
    <BackButton />
    <div className="ac-shortlist__error ch-display--flex ch-flex-direction--column ch-align-items--center ch-text--center sm:ch-mt--6">
      <span className="ac-shortlist__icon--attention ch-mb--2 sm:ch-mb--4" />
      <h1 className="ch-fs--5 sm:ch-fs--6 ch-ph--4">
        We had a problem getting your shortlist
      </h1>
      <h4 className="ch-fw--400 ch-color--grey-6 ch-ph--3 sm:ch-ph--0 ch-mb--6 ch-fs--3 sm:ch-fs--4">
        We’re working on it, please come back later and try again.
      </h4>
      <button
        className="ch-btn ch-btn--secondary ch-width--7 sm:ch-width--8"
        onClick={location.reload}
        type="button"
      >
        Try again
      </button>
    </div>
  </div>
);

export default ShortlistError;
