import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const PreSalePrice = ({ amount }) => {
  return (
    <span className="ac-pricing__presaleprice">
      <span className="ac-pricing__prefix">Was </span>
      <Money amount={amount} />
    </span>
  );
};

PreSalePrice.propTypes = {
  amount: PropTypes.number,
};

export default PreSalePrice;
