import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterOption from './filterOption';

const ModelAndTrimOptions = ({
  displayName,
  suffix,
  name,
  options,
  selectOption,
  selectedOptions = {},
  removeSelectedOption,
  makeKey,
  modelKey,
  label,
}) => {
  const shouldSelectAll = () => {
    return Object.keys(selectedOptions).length === 0;
  };

  const [allSelected, setAllSelected] = useState(shouldSelectAll());

  useEffect(() => {
    setAllSelected(shouldSelectAll());
  }, [selectedOptions]);

  const renderOptions = () => {
    return Object.entries(options).map((data) => {
      const [optionKey, { displayName }] = data;
      return (
        <FilterOption
          key={`${name} ${optionKey}`}
          label={displayName}
          name={optionKey}
          onChange={() => selectOption(makeKey, optionKey, modelKey)}
          selected={optionKey in selectedOptions}
          type="checkbox"
          value={optionKey}
        />
      );
    });
  };

  return (
    <div className="ch-mb--3">
      <h4 className="ch-mt--2 ch-mb--3">
        {displayName} {suffix}
      </h4>
      <FilterOption
        label={label}
        name={`${name}_none`}
        onChange={() => removeSelectedOption(makeKey, modelKey)}
        selected={allSelected}
        type="checkbox"
        value={''}
      />
      {renderOptions()}
    </div>
  );
};

ModelAndTrimOptions.propTypes = {
  displayName: PropTypes.string,
  label: PropTypes.string,
  makeKey: PropTypes.string,
  modelKey: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.object,
  removeSelectedOption: PropTypes.func,
  selectedOptions: PropTypes.object,
  selectOption: PropTypes.func,
  suffix: PropTypes.string,
};

export default ModelAndTrimOptions;
