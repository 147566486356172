import React from 'react';

export const VehicleShowcaseLoading = () => (
  <div className="ch-display--flex ch-justify-content--between">
    <div
      className="ch-width--12 sl:ch-width--6 md:ch-width--4 ch-ma--1 ch-skeleton"
      style={{ height: '400px' }}
    />
    <div
      className="ac-hidden--xs sl:ch-width--6 md:ch-width--4 ch-ma--1 ch-skeleton"
      style={{ height: '400px' }}
    />
    <div
      className="ac-hidden--sm ch-width--4 ch-ma--1 ch-skeleton"
      style={{ height: '400px' }}
    />
  </div>
);
