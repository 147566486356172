import * as Qs from 'qs';

const camelCaseKeys = (parameters) => {
  return Object.keys(parameters).reduce((camelCasedParameters, key) => {
    const camelCasedKey = key.replace(/(_[a-z])/g, ($1) =>
      $1.toUpperCase().replace('_', '')
    );
    camelCasedParameters[camelCasedKey] = parameters[key];
    return camelCasedParameters;
  }, {});
};

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }

  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

const delimitWithCommas = (amount) => {
  const amountString = amount.toString();
  if (amount < 10000) return amountString;
  return amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const isBrowser = () => {
  let browser;
  return process.env.NODE_ENV === 'production' && typeof browser === 'boolean'
    ? browser
    : typeof window !== 'undefined';
};

const getValueFromString = (string) => parseFloat(string.replace(/[£,]/g, ''));

const pluralise = (count, noun, suffix = 's') =>
  `${noun}${count !== 1 ? suffix : ''}`;

const range = (from, to) => {
  var pageNumbers = [];
  var index = from;
  for (index; index <= to; index++) {
    pageNumbers.push(index);
  }
  return pageNumbers;
};

const snakeCaseKeys = (parameters) => {
  return Object.keys(parameters).reduce((snakeCasedParameters, key) => {
    var snakeCasedKey = key.replace(/([A-Z])/g, ($1) => '_' + $1.toLowerCase());

    snakeCasedParameters[snakeCasedKey] = parameters[key];
    return snakeCasedParameters;
  }, {});
};

const toQueryString = (parameters) => {
  const nonBlankParams = {};

  const { make_model, show_click_and_collect_options, page } = parameters;
  Object.keys(parameters).forEach((param) => {
    if (
      (typeof parameters[param] !== 'object' &&
        (parameters[param] === true || parameters[param].length > 0)) ||
      (parameters[param] instanceof Array && parameters[param].length > 0)
    ) {
      nonBlankParams[param] = parameters[param];
    }
  });
  return Qs.stringify(
    { ...nonBlankParams, make_model, show_click_and_collect_options, page },
    { arrayFormat: 'brackets' }
  );
};

const updatePageTitle = (title) => (document.title = title);

const uuid = (() => {
  var self = {};
  var lut = [];
  var index;

  for (index = 0; index < 256; index++) {
    lut[index] = (index < 16 ? '0' : '') + index.toString(16);
  }

  self.generate = () => {
    var d0 = (Math.random() * 0xffffffff) | 0;
    var d1 = (Math.random() * 0xffffffff) | 0;
    var d2 = (Math.random() * 0xffffffff) | 0;
    var d3 = (Math.random() * 0xffffffff) | 0;

    return (
      lut[d0 & 0xff] +
      lut[(d0 >> 8) & 0xff] +
      lut[(d0 >> 16) & 0xff] +
      lut[(d0 >> 24) & 0xff] +
      '-' +
      lut[d1 & 0xff] +
      lut[(d1 >> 8) & 0xff] +
      '-' +
      lut[((d1 >> 16) & 0x0f) | 0x40] +
      lut[(d1 >> 24) & 0xff] +
      '-' +
      lut[(d2 & 0x3f) | 0x80] +
      lut[(d2 >> 8) & 0xff] +
      '-' +
      lut[(d2 >> 16) & 0xff] +
      lut[(d2 >> 24) & 0xff] +
      lut[d3 & 0xff] +
      lut[(d3 >> 8) & 0xff] +
      lut[(d3 >> 16) & 0xff] +
      lut[(d3 >> 24) & 0xff]
    );
  };
  return self;
})();

export {
  camelCaseKeys,
  deepClone,
  delimitWithCommas,
  getValueFromString,
  pluralise,
  isBrowser,
  isEmptyObject,
  range,
  snakeCaseKeys,
  toQueryString,
  updatePageTitle,
  uuid,
};
