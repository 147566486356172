import React from 'react';
import PropTypes from 'prop-types';
import VehicleBranch from './vehicleBranch';

const BranchFooter = ({
  branchInfo,
  clickAndCollectDetails,
  pickupAvailable,
  vehicleMovable,
}) => {
  return (
    <>
      <div className="ac-result__footer">
        <VehicleBranch {...branchInfo} />
        {vehicleMovable && <VehicleBranch {...clickAndCollectDetails} />}
        {clickAndCollectDetails && pickupAvailable && (
          <div className="ac-result__footer__collect_badge ch-mt--2 ch-ba--1 ch-text--center ch-mb--0">
            <p className="ch-mb--0 ch-color--ac-green">
              Collect in <b>{`${vehicleMovable ? 7 : 2} days`}</b>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(BranchFooter);

BranchFooter.propTypes = {
  branchInfo: PropTypes.object,
  clickAndCollectDetails: PropTypes.object,
  pickupAvailable: PropTypes.bool,
  vehicleMovable: PropTypes.bool,
};
