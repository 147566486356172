import Auth from '@javascript/lib/auth';
import { AsyncResult } from '@javascript/types/AsyncResult';
import { useState, useEffect } from 'react';

interface AuthenticationCheck extends AsyncResult {
  isLoggedIn: boolean;
}

export default function useIsAuthenticated(): AuthenticationCheck {
  const [state, setState] = useState<AuthenticationCheck>({
    isLoggedIn: false,
    loading: true,
    error: null,
  });

  useEffect(() => {
    (async () => {
      try {
        const auth = await new Auth().initializeAuthClient(window.authOptions);

        const isAuthenticated = await auth.isAuthenticated();

        setState({
          error: null,
          isLoggedIn: isAuthenticated,
          loading: false,
        });
      } catch (error) {
        setState({
          error: error as Error,
          isLoggedIn: false,
          loading: false,
        });
      }
    })();
  }, []);

  return state;
}
