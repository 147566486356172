const ShareShortlistButton = ({
  sharedUrl,
  shortlist,
}: {
  sharedUrl: string;
  shortlist: string[];
}): JSX.Element => {
  const stockRefParams = shortlist.join(',');
  const sharedLink = `${sharedUrl}?stock_references=${stockRefParams}&utm_source=internal&utm_medium=share_button&utm_campaign=shared_shortlist`;

  const shareLink = () => {
    const title = 'Shared shortlist';
    const text = 'Someone has shared their shortlist with you! View it here:';

    const shareInfo = {
      text,
      title,
      url: sharedLink,
    };
    if (navigator.canShare && navigator.canShare(shareInfo)) {
      navigator.share(shareInfo).catch((error) => {
        if (window.newrelic) {
          window.newrelic.noticeError(`Shortlist error: ${error}`);
        }
      });
    } else {
      const body = text + ' ' + sharedLink;
      const [encodedTitle, encodedBody] = [title, body].map((text) =>
        encodeURIComponent(text)
      );

      window.location.href = `mailto:?subject=${encodedTitle}&body=${encodedBody}`;
    }
  };

  return (
    <div className="ch-display--flex">
      <button
        className="ac-shortlist__share-button ch-circle ch-ba--0 ch-display--flex ch-align-items--center ch-justify-content--center"
        data-gtm-track="interaction"
        data-gtm-track-label={`Shared ${shortlist.length} vehicles`}
        id="shareButton"
        onClick={shareLink}
        type="button"
      >
        <span className="ac-shortlist__share-button--icon" />
      </button>
      <label
        className="ac-shortlist__share-button--label ch-fs--3 ch-ml--1 sm:ch-ml--2"
        htmlFor="shareButton"
      >
        Share
      </label>
    </div>
  );
};

export default ShareShortlistButton;
