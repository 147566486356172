import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const CostNew = ({ amount }) => {
  return (
    <span className="ac-pricing__costnew">
      <span className="ac-pricing__prefix">Cost new </span>
      <Money amount={amount} />
    </span>
  );
};

CostNew.propTypes = {
  amount: PropTypes.number,
};

export default CostNew;
