import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterOption = ({
  autoFocus,
  children,
  disabled,
  label,
  name,
  onChange,
  selected,
  type,
  value,
}) => {
  const handleFilterOptionKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onChange(value);
    }
  };

  return (
    <label
      aria-checked={selected}
      className={classNames('filter-option', {
        'filter-option--checked': selected,
        'filter-option--disabled': disabled,
      })}
      onKeyDown={handleFilterOptionKeyDown}
      role={type}
      tabIndex="0"
    >
      <input
        autoFocus={autoFocus}
        checked={selected}
        className="ch-reader"
        disabled={disabled}
        name={name}
        onChange={() => onChange(value)}
        tabIndex="-1"
        type={type}
        value={value}
      />
      {children}
      {label}
      <span className={`filter-option__${type}`} />
    </label>
  );
};

export default FilterOption;

FilterOption.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
