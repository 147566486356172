import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DepositSetter,
  FilterButton,
  FilterContainer,
  FilterOption,
  PaymentSwitch,
} from '@components/search_filters/units';
import { delimitWithCommas } from '@lib/utils';
import Analytics from '@lib/analytics';

const EMPTY_VALUE = '';
const PLAN_RANGE_TYPES = {
  maximum: 'maximum',
  minimum: 'minimum',
};

const PRICING = {
  paymentType: null,
  cash: {
    minPrice: '',
    maxPrice: '',
  },
  monthly: {
    minPrice: '',
    maxPrice: '',
  },
};

const PriceSelect = ({ availableOptions, onFilterUpdate, selected }) => {
  const { deposit, paymentType, minPrice, maxPrice } = selected;

  const [pricing, setPricing] = useState({
    ...PRICING,
    [paymentType]: { minPrice, maxPrice },
  });
  const [activeDeposit, setActiveDeposit] = useState(deposit);
  const [activePaymentType, setActivePaymentType] = useState(paymentType);
  const [activePlanRange, setActivePlanRange] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const includeDeposit = activePaymentType !== 'cash';

  const closeFilter = () => {
    setActivePlanRange(null);
    setIsClosing(true);
    setTimeout(() => setIsClosing(false), 500);
  };

  const generateSelectedOptionLabelFor = (rangeType) => {
    return (
      pricing[activePaymentType][rangeType] &&
      `£${delimitWithCommas(pricing[activePaymentType][rangeType])}`
    );
  };

  const handlePaymentTypeChange = (paymentType) =>
    setActivePaymentType(paymentType);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const payload = {
      deposit: activePaymentType === 'cash' ? '' : activeDeposit,
      paymentType: activePaymentType,
      minPrice: `${pricing[activePaymentType].minPrice}`,
      maxPrice: `${pricing[activePaymentType].maxPrice}`,
    };

    Analytics.trackEvent('interaction', {
      action: 'Search filters',
      label: activeDeposit
        ? `Deposit entered - ${activeDeposit}`
        : 'Deposit not entered',
    });

    onFilterUpdate(payload);
  };

  const handleClick = (evt) =>
    activePlanRange ? closeFilter() : handleSubmit(evt);

  const openPaymentTypeOptions = (typeOption) => setActivePlanRange(typeOption);

  const isSelectedOption = (value) => {
    const selected =
      PLAN_RANGE_TYPES.maximum === activePlanRange
        ? pricing[activePaymentType].maxPrice
        : pricing[activePaymentType].minPrice;

    return (
      (selected === EMPTY_VALUE && value === selected) || value === +selected
    );
  };

  const getAvailableRangeOptions = (isMinimumRangeOpen) => {
    const selected = isMinimumRangeOpen
      ? pricing[activePaymentType].maxPrice
      : pricing[activePaymentType].minPrice;
    const rangeOptions = availableOptions[activePaymentType];

    const isVisibleInRange = isMinimumRangeOpen
      ? ([, value]) => value < selected
      : ([, value]) => value > selected;

    return selected === EMPTY_VALUE
      ? rangeOptions
      : rangeOptions.filter(isVisibleInRange);
  };

  const updatePlanPrice = (value) => {
    const key =
      activePlanRange === PLAN_RANGE_TYPES.minimum ? 'minPrice' : 'maxPrice';
    setPricing({
      ...pricing,
      [activePaymentType]: { ...pricing[activePaymentType], [key]: value },
    });
  };

  const renderOptions = () => {
    const isMinimumRangeOpen = PLAN_RANGE_TYPES.minimum === activePlanRange;
    const optionsInRange = getAvailableRangeOptions(isMinimumRangeOpen);

    return optionsInRange.map(([label, value]) => (
      <FilterOption
        key={`${activePaymentType}_${label}`}
        label={label}
        name={`${activePaymentType}_${label}`}
        onChange={() => updatePlanPrice(value)}
        selected={isSelectedOption(value)}
        type="radio"
        value={value}
      />
    ));
  };

  return (
    <FilterContainer handleClick={handleClick} title="Select a price">
      <PaymentSwitch
        onChange={handlePaymentTypeChange}
        selected={activePaymentType}
      />
      {!activePlanRange ? (
        <div
          className={`ch-mt--4 ${
            isClosing && 'ac-animation--fade-in-from-left'
          }`}
        >
          <form onSubmit={handleSubmit}>
            <FilterButton
              name="Minimum price"
              onClick={() => openPaymentTypeOptions(PLAN_RANGE_TYPES.minimum)}
              selectedOptions={generateSelectedOptionLabelFor('minPrice')}
            />
            <FilterButton
              name="Maximum price"
              onClick={() => openPaymentTypeOptions(PLAN_RANGE_TYPES.maximum)}
              selectedOptions={generateSelectedOptionLabelFor('maxPrice')}
            />
            {includeDeposit && (
              <DepositSetter
                deposit={activeDeposit}
                handleChange={setActiveDeposit}
              />
            )}
            <button className="ch-reader" type="submit">
              Done
            </button>
          </form>
        </div>
      ) : (
        <div className="ac-animation--fade-in-from-right">
          <h4 className="ch-mt--4">Select {activePlanRange}</h4>
          <FilterOption
            label="Any"
            name={`${activePaymentType}_none`}
            onChange={() => updatePlanPrice(EMPTY_VALUE)}
            selected={isSelectedOption(EMPTY_VALUE)}
            type="radio"
            value={EMPTY_VALUE}
          />
          {renderOptions()}
        </div>
      )}
    </FilterContainer>
  );
};

PriceSelect.propTypes = {
  availableOptions: PropTypes.shape({
    cash: PropTypes.array,
    monthly: PropTypes.array,
  }),
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.shape({
    deposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paymentType: PropTypes.oneOf(['cash', 'monthly']),
  }),
};

export default PriceSelect;
