import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client,
} from '@auth0/auth0-spa-js';
import { AccountEvent } from '@javascript/components/account/accountListener';
import Analytics from '../analytics';

const { isNativeApp } = acMobileFeatures();

export default class Auth {
  private _authClient: Auth0Client | null = null;

  private get authClient() {
    if (!this._authClient)
      throw new Error('Auth client has not been initialized');

    return this._authClient;
  }

  async getTokenSilently() {
    return await this.authClient.getTokenSilently();
  }

  goToProfile() {
    window.location.href = '/my-account';
  }

  async initializeAuthClient(options: Auth0ClientOptions) {
    this._authClient = await createAuth0Client(options);

    return this;
  }

  async isAuthenticated(): Promise<boolean> {
    return await this.authClient.isAuthenticated();
  }

  async login(): Promise<void> {
    if (isNativeApp()) return;
    await this.authClient.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin + '/auth/callback',
      },
      appState: {
        // send the user back to their current page after logging in
        userDestination: window.location.href,
      },
    });
  }

  async logout(): Promise<void> {
    if (isNativeApp()) return;
    await this.authClient.logout({
      logoutParams: {
        returnTo: window.location.origin + '/',
      },
    });
    document.cookie =
      '_acdotcom_logged_in=false; expires=Thu, 1 January 1970 00:00:00 GMT;';
    Analytics.dataPush('loggedIn', false);

    const accountEvents = new BroadcastChannel('accountEvents');
    accountEvents.postMessage({
      event: AccountEvent.logout,
      tabID: window.tabID,
    });
  }
}
