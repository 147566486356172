import { useEffect, useRef, useState } from 'react';

export const useTagVisibilityToggleState = () => {
  const initialFilterHeight = useRef(null);
  const [isShowingMoreSearchTags, setIsShowingMoreSearchTags] = useState(false);
  const [isTagsToggleVisible, setIsTagsToggleVisible] = useState(false);
  const [visibleTagCount, setVisibleTagCount] = useState(0);

  return {
    initialFilterHeight,
    isShowingMoreSearchTags,
    setIsShowingMoreSearchTags,
    isTagsToggleVisible,
    setIsTagsToggleVisible,
    visibleTagCount,
    setVisibleTagCount,
  };
};

export const useTagVisibilityToggle = ({
  searchTags,
  searchTagListRef,
  removeFiltersButtonRef,
  isShowingMoreSearchTags,
  setIsShowingMoreSearchTags,
  setIsTagsToggleVisible,
  setVisibleTagCount,
  initialFilterHeight,
}) => {
  useEffect(() => {
    if (!initialFilterHeight.current) {
      initialFilterHeight.current = searchTagListRef.current?.offsetHeight;
    }
  }, []);

  useEffect(() => {
    if (isShowingMoreSearchTags) {
      setIsTagsToggleVisible(false);
    }

    if (!isShowingMoreSearchTags) {
      const searchTagsNodes =
        searchTags.length > 0
          ? Array.from(searchTagListRef.current.children)
          : [];

      const searchTagsListContainerArea =
        searchTagListRef.length > 0 &&
        searchTagListRef.current?.getBoundingClientRect();

      const searchTagsListContainerLeftBoundary =
        searchTagsListContainerArea?.left ?? 0;

      const removeFiltersButtonArea =
        removeFiltersButtonRef.current?.getBoundingClientRect();

      const removeFiltersButtonLeftBoundary =
        removeFiltersButtonArea?.left ?? 0;

      let visibleTagsNum = 0;
      const showMoreTogglePadding = 120;

      if (searchTagsNodes.length > 0) {
        searchTagsNodes.forEach((tag) => {
          const tagArea = tag.getBoundingClientRect();
          if (
            tagArea.left >= searchTagsListContainerLeftBoundary &&
            tagArea.right <=
              removeFiltersButtonLeftBoundary - showMoreTogglePadding
          ) {
            visibleTagsNum++;
          }
        });
      }

      setVisibleTagCount(visibleTagsNum);
      setIsTagsToggleVisible(visibleTagsNum < searchTags.length);
    }
  }, [searchTags, isShowingMoreSearchTags]);

  const toggleShowMoreTags = () => {
    setIsShowingMoreSearchTags(!isShowingMoreSearchTags);
  };

  return toggleShowMoreTags;
};
