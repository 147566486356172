interface searchFeedbackTextboxProps {
  error: boolean;
  feedback: string;
  handleFeedback: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const SearchFeedbackTextbox = ({
  error,
  feedback,
  handleFeedback,
}: searchFeedbackTextboxProps): JSX.Element => {
  return (
    <div className={`${error && 'ch-form__group--error'} ch-mt--2 sm:ch-mt--3`}>
      <label className="ch-fs--3 ch-fw--500 ch-width--12">Your answer</label>
      {error && (
        <span className="ch-form__control-validation">
          Please enter your answer
        </span>
      )}
      <textarea
        className="ch-width--12 ch-ba--1 ch-bc--grey-6 ch-rounded--sm"
        name="feedback"
        onChange={handleFeedback}
        rows={4}
        value={feedback}
      />
    </div>
  );
};

export default SearchFeedbackTextbox;
