import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const ListPrice = ({ amount }) => {
  return (
    <span className="ac-pricing__costnew">
      <span className="ac-pricing__prefix">Current list price </span>
      <Money amount={amount} />
    </span>
  );
};

ListPrice.propTypes = {
  amount: PropTypes.number,
};

export default ListPrice;
