import React from 'react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import { pluralise } from '@lib/utils';
import PropTypes from 'prop-types';

const VehicleComparisonHeader = ({ vehicleCount }) => {
  const closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  return (
    <div className="compare__header">
      <button
        className="ac-modal__close ac-modal__close--vehicle-comparison"
        onClick={closeModal}
        type="button"
      >
        <span className="ch-reader">Close</span>
      </button>
      {vehicleCount > 0 && (
        <h1 className="ch-fs--3 ch-lh--body ch-mb--0 ch-pr--2 lg:ch-pr--4 ac-animation--fade-in">
          Comparing {vehicleCount} {pluralise(vehicleCount, 'vehicle')}
        </h1>
      )}
    </div>
  );
};

VehicleComparisonHeader.propTypes = {
  vehicleCount: PropTypes.number,
};

export default VehicleComparisonHeader;
