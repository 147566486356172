import React from 'react';
import PropTypes from 'prop-types';

export const SearchTag = ({
  isHighlighted,
  label,
  onTagClick,
  type,
  value,
}) => {
  const handleOnTagClick = (type, value) => {
    onTagClick(type, value);
  };

  return (
    <li
      className={`searchtag ${!!isHighlighted && 'searchtag--highlighted'}`}
      onClick={() => {
        handleOnTagClick(type, value);
      }}
    >
      {label}
    </li>
  );
};

export default SearchTag;

SearchTag.propTypes = {
  isHighlighted: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onTagClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};
