import React, { Component } from 'react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

import Modal from '@components/application/modal';

export default class ValueRangePromotion extends Component {
  static displayName = 'ValueRangePromotion';

  openModal = (event) => {
    event.preventDefault();
    EventEmitter.emit(EVENT_TYPES.openModal, 'valuerange');
  };

  closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  render() {
    return (
      <>
        <a
          className="ac-promotion"
          href="/value-range"
          onClick={this.openModal}
        >
          Part of Arnold’s Value Range
        </a>

        <Modal hideClose id="valuerange">
          <div className="ch-modal">
            <button
              className="ch-modal__close"
              onClick={this.closeModal}
              type="button"
            >
              <span className="ch-reader">Close</span>
            </button>
            <div className="ac-valuerange-lightbox__header">
              <p className="ac-valuerange-lightbox__subheading">
                All our Value Range cars are:
              </p>
            </div>
            <div className="ac-valuerange-lightbox__body">
              <ul className="ch-list ch-list--tick ac-valuerange-lightbox__features">
                <li>Under £10,000</li>
                <li>5 years old or over</li>
                <li>Up to 100,000 miles</li>
              </ul>
            </div>
            <div className="ac-valuerange-lightbox__footnote">
              <a href="/value-range">
                View full details about Arnold’s Value Range
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
