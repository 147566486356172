import useShortlist from './shortlistStore';

interface ShortlistButtonProps {
  stockReference: string;
}

const ShortlistButton = ({
  stockReference,
}: ShortlistButtonProps): JSX.Element | null => {
  const {
    store: { shortlist, add, remove },
    hasHydrated,
  } = useShortlist();

  const isShortlisted = shortlist.includes(stockReference);

  const label = `${isShortlisted ? 'Remove from' : 'Add to'} shortlist`;

  const updateShortlistedState = () => {
    isShortlisted ? remove(stockReference) : add(stockReference);
  };

  return hasHydrated ? (
    <button
      className="js-off--hide ac-shortlist__button"
      onClick={updateShortlistedState}
      type="button"
    >
      <img
        alt={label}
        className={`ac-shortlist__button--${
          isShortlisted ? 'active' : 'inactive'
        }`}
        data-gtm-track="click_cta"
        data-gtm-track-label={label}
        height="26"
        width="101"
      />
    </button>
  ) : null;
};

export default ShortlistButton;
