import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../application/input/textInput';
import Recaptcha from '../application/input/recaptcha';

export default class EnquiryForm extends Component {
  static propTypes = {
    action: PropTypes.string,
    authenticityToken: PropTypes.string,
    collectionPoint: PropTypes.string,
    dealbuilder: PropTypes.bool,
    finance: PropTypes.object,
    interestedInFinance: PropTypes.bool,
    isTest: PropTypes.bool,
    partExchange: PropTypes.object,
    recaptchaSiteKey: PropTypes.string,
    stockReference: PropTypes.string,
  };

  state = {
    isValid: true,
  };

  onSubmit = (event) => {
    var valid = true;

    if (!this.refs.firstName.handleValidation()) {
      valid = false;
    }

    if (!this.refs.lastName.handleValidation()) {
      valid = false;
    }

    if (!this.refs.postcode.handleValidation()) {
      valid = false;
    }

    if (!this.refs.contactNumber.handleValidation()) {
      valid = false;
    }

    if (!this.refs.contactEmail.handleValidation()) {
      valid = false;
    }

    if (!this.refs.recaptcha.validate()) {
      valid = false;
    }

    if (!valid) {
      event.preventDefault();
      return this.setState({ isValid: false });
    }

    return this.setState({ isValid: true });
  };

  renderFinanceHiddenFields = () => {
    if (this.props.finance) {
      return (
        <div>
          <input
            name="enquiry[enquiry_finance_attributes][description]"
            ref="financeDescription"
            type="hidden"
            value={this.props.finance.description}
          />
          <input
            name="enquiry[enquiry_finance_attributes][calculated]"
            ref="financeCalculated"
            type="hidden"
            value={this.props.finance.calculated}
          />
          <input
            name="enquiry[enquiry_finance_attributes][term]"
            ref="financeTerm"
            type="hidden"
            value={this.props.finance.term}
          />
          <input
            name="enquiry[enquiry_finance_attributes][deposit]"
            ref="financeDeposit"
            type="hidden"
            value={this.props.finance.deposit}
          />
          <input
            name="enquiry[enquiry_finance_attributes][annual_mileage]"
            ref="financeAnnualMileage"
            type="hidden"
            value={this.props.finance.annual_mileage}
          />
          <input
            name="enquiry[enquiry_finance_attributes][monthly_payment]"
            ref="financeMonthlyPayment"
            type="hidden"
            value={this.props.finance.monthly_payment}
          />
          <input
            name="enquiry[enquiry_finance_attributes][guaranteed_future_value]"
            ref="financeGuaranteedFutureValue"
            type="hidden"
            value={this.props.finance.guaranteed_future_value}
          />
          <input
            name="enquiry[enquiry_finance_attributes][completion_fee]"
            ref="financeCompletionFee"
            type="hidden"
            value={this.props.finance.completion_fee}
          />
          <input
            name="enquiry[enquiry_finance_attributes][fixed_interest_rate]"
            ref="financeFixedInterestRate"
            type="hidden"
            value={this.props.finance.fixed_interest_rate}
          />
          <input
            name="enquiry[enquiry_finance_attributes][apr]"
            ref="financeAPR"
            type="hidden"
            value={this.props.finance.apr}
          />
          <input
            name="enquiry[enquiry_finance_attributes][excess_mileage_charge]"
            ref="financeExcessMileageCharge"
            type="hidden"
            value={this.props.finance.excess_mileage_charge}
          />
        </div>
      );
    }
  };

  renderPartExchangeHiddenFields = () => {
    if (this.props.partExchange) {
      return (
        <div>
          <input
            name="enquiry[enquiry_part_exchange_attributes][registration]"
            ref="partExchangeRegistration"
            type="hidden"
            value={this.props.partExchange.registration}
          />
          <input
            name="enquiry[enquiry_part_exchange_attributes][mileage]"
            ref="partExchangeMileage"
            type="hidden"
            value={this.props.partExchange.mileage}
          />
          <input
            name="enquiry[enquiry_part_exchange_attributes][valuation]"
            ref="partExchangeValuation"
            type="hidden"
            value={this.props.partExchange.valuation}
          />
          <input
            name="enquiry[enquiry_part_exchange_attributes][settlement_figure]"
            ref="partExchangeSettlementFigure"
            type="hidden"
            value={this.props.partExchange.settlement_figure}
          />
        </div>
      );
    }
  };

  renderCollectionPoint = () => {
    if (this.props.collectionPoint) {
      return (
        <input
          name="enquiry[collection_point]"
          type="hidden"
          value={this.props.collectionPoint}
        />
      );
    }
  };

  renderFormError = () => {
    if (!this.state.isValid) {
      return (
        <p className="ac-enquiry-form__error-msg--show" ref="errorMessage">
          Oops! Some of your information isn’t quite right. Please correct the
          fields above.
        </p>
      );
    }
  };

  render() {
    return (
      <form
        acceptCharset="UTF-8"
        action={this.props.action}
        className="ch-form__group ac-well--contact-form"
        id="new_enquiry"
        method="post"
        onSubmit={this.onSubmit}
        ref="form"
      >
        <input name="utf8" type="hidden" value="✓" />
        <input
          name="authenticity_token"
          type="hidden"
          value={this.props.authenticityToken}
        />
        <input
          name="enquiry[stock_reference]"
          type="hidden"
          value={this.props.stockReference}
        />
        <input
          name="enquiry[interested_in_finance]"
          ref="interestedInFinance"
          type="hidden"
          value={this.props.interestedInFinance}
        />
        <input
          name="enquiry[dealbuilder]"
          ref="dealbuilder"
          type="hidden"
          value={this.props.dealbuilder}
        />
        {this.renderFinanceHiddenFields()}
        {this.renderPartExchangeHiddenFields()}
        {this.renderCollectionPoint()}
        <div className="md:ch-col--7">
          <fieldset className="ac-enquiry-form--set">
            <legend className="ch-reader">Your name</legend>
            <TextInput
              additionalInputClasses="ac-enquiry-form__input ac-input--capitalize"
              errorMessage="What is your first name? (maximum of 30 characters)"
              inputId="enquiry_first_name"
              inputName="enquiry[first_name]"
              labelName="First name"
              ref="firstName"
              requiresNameValidation
            />
            <TextInput
              additionalInputClasses="ac-enquiry-form__input ac-input--capitalize"
              errorMessage="What is your last name? (maximum of 30 characters)"
              inputId="enquiry_last_name"
              inputName="enquiry[last_name]"
              labelName="Last name"
              ref="lastName"
              requiresNameValidation
            />
          </fieldset>
        </div>

        <div className="md:ch-col--8">
          <fieldset className="ac-enquiry-form--set">
            <legend className="ch-reader">Your contact details</legend>
            <TextInput
              additionalInputClasses="ac-enquiry-form__input ac-enquiry-form__input--sm ac-input--uppercase"
              description="To help us find your nearest branch."
              errorMessage="Please enter a valid postcode."
              inputId="enquiry_postcode"
              inputName="enquiry[postcode]"
              labelName="Postcode"
              ref="postcode"
              requiresValidation
            />
            <TextInput
              additionalInputClasses="ac-enquiry-form__input ac-enquiry-form__input--md"
              description="We’ll give you a call to discuss your enquiry."
              errorMessage="Please enter a valid phone number."
              inputId="enquiry_contact_number"
              inputName="enquiry[contact_number]"
              inputType="tel"
              labelName="Phone number"
              ref="contactNumber"
              requiresValidation
            />
            <TextInput
              additionalInputClasses="ac-enquiry-form__input"
              description="We’ll send a confirmation email to this address. We won’t spam you."
              errorMessage="Please enter a valid email address."
              inputId="enquiry_contact_email"
              inputName="enquiry[contact_email]"
              inputType="email"
              labelName="Email address"
              ref="contactEmail"
              requiresEmailValidation
            />
          </fieldset>
        </div>

        <div className="md:ch-col--12">
          <fieldset className="ac-enquiry--form-set">
            <legend className="ch-reader">Comments</legend>
            <label className="ch-label" htmlFor="enquiry_comments">
              Your message (optional)
            </label>
            <span className="ac-input__description">
              If you have a specific question or comment, let us know.
            </span>
            <textarea
              className="ac-enquiry-form__textarea ac-enquiry-form__comments"
              id="enquiry_comments"
              name="enquiry[comments]"
              rows="4"
            />
          </fieldset>
        </div>

        <fieldset className="md:ch-col--11">
          <legend className="ch-reader">
            Your video and newsletter preferences
          </legend>
          <div className="ch-form__group">
            <input
              className="ch-checkbox"
              id="personalised_video_checkbox"
              name="enquiry[request_personalised_video]"
              type="checkbox"
            />
            <label
              className="ch-checkbox__label ch-fs--2 ch-text--normal"
              htmlFor="personalised_video_checkbox"
            >
              Got more questions? Book a live video appointment with one of our
              Product Consultants.
            </label>
          </div>

          <div className="ch-form__group">
            <input
              className="ch-checkbox"
              id="newsletter"
              name="enquiry[newsletter]"
              type="checkbox"
            />
            <label
              className="ch-checkbox__label ch-fs--2 ch-text--normal"
              htmlFor="newsletter"
            >
              Subscribe to our newsletter for exclusive offers.
            </label>
          </div>

          <span className="ac-input__description">
            Help us fight against online spam robots.
          </span>
          <Recaptcha
            className="g-recaptcha"
            errorMessage="Please complete the reCAPTCHA."
            isTest={this.props.isTest}
            recaptchaSiteKey={this.props.recaptchaSiteKey}
            ref="recaptcha"
          />

          {this.renderFormError()}
          <button
            className="ch-btn ch-btn--cta ch-btn--lg"
            name="button"
            ref="submitButton"
            type="submit"
          >
            Send your enquiry
          </button>
        </fieldset>
      </form>
    );
  }
}
