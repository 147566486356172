import BackButton from '../application/backButton';

const EmptyShortlist = ({
  login,
  showLoginButton,
  showLoginMessage,
}: {
  login: () => void;
  showLoginButton: boolean;
  showLoginMessage: boolean;
}): JSX.Element => (
  <div className="ch-container ch-display--flex ch-flex-direction--column ch-align-items--center ch-text--center ch-pt--3">
    <BackButton />
    <div className="ac-shortlist__empty ch-display--flex ch-flex-direction--column ch-align-items--center ch-text--center">
      <span className="ac-shortlist__icon--heart ch-mb--4" />
      <h1>Your shortlist is empty</h1>
      <h4 className="ch-fw--400 ch-color--grey-6 ch-mh--auto ch-width--11 ch-mb--6">
        {showLoginMessage
          ? 'Start adding or access your saved vehicles by logging in.'
          : 'Vehicles that you save to your shortlist will appear here.'}
      </h4>
      <a
        className="ch-btn ch-btn--success ch-width--7 sm:ch-width--8"
        href="/vehicles"
      >
        Start my search
      </a>
      {showLoginButton && (
        <button
          className="ch-btn ch-mt--2 ch-width--7 sm:ch-width--8"
          data-gtm-track="click_cta"
          data-gtm-track-label="Login - Shortlist Button"
          onClick={login}
          type="button"
        >
          Log in
        </button>
      )}
    </div>
  </div>
);

export default EmptyShortlist;
