import { useEffect, useState } from 'react';
import {
  AppliedFilters,
  AvailableOptions,
} from '@javascript/types/FilterSearch';
import Modal from '../application/modal';
import VehicleSearchFiltersContainer from '../vehicle_search/vehicleSearchFiltersContainer';
import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import {
  buildFiltersQueryString,
  requestFiltersSearchResults,
} from '@javascript/lib/requests/vehicleSearch';
import FilterFooter from '../search_filters/filterFooter';
import FilterHeader from '../search_filters/filterHeader';
import useTrackAppliedFiltersForGA from '@javascript/hooks/analytics/useTrackAppliedFiltersForGA';
import Analytics from '@lib/analytics';
import { isEqual } from '../vehicle_search/helpers/vehicleSearchHelpers';
import { ButtonType } from '@javascript/types/ButtonType';

interface HomeSearchProps {
  availableOptions: AvailableOptions;
  initialAppliedFilters: AppliedFilters;
}

interface Results {
  count: number;
  url: string;
}

const HomeSearch = ({
  initialAppliedFilters,
  availableOptions,
}: HomeSearchProps) => {
  const [appliedFilters, setAppliedFilters] = useState(initialAppliedFilters);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<Results | null>(null);
  const { trackAppliedFiltersForGA } = useTrackAppliedFiltersForGA();
  const experiments = {};

  const requestSearchResults = async (
    changedFilters: Record<string, object>
  ) => {
    try {
      const updatedFilters = {
        ...appliedFilters,
        ...changedFilters,
      };

      Analytics.dataPush('criteria', trackAppliedFiltersForGA(updatedFilters));
      setAppliedFilters(updatedFilters);
      setLoading(true);

      const requestedResult = await requestFiltersSearchResults(
        buildFiltersQueryString({
          ...updatedFilters,
          countOnly: true,
        })
      );

      setResults(requestedResult);
    } catch (err) {
      if (window.newrelic) {
        window.newrelic.noticeError(
          `Homepage search error: ${(err as Error).message}`
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePageShow = () => {
    setAppliedFilters(initialAppliedFilters);
    setLoading(false);
    setResults(null);
  };

  const resetFilters = () => {
    setAppliedFilters(initialAppliedFilters);
  };

  const openHomepageSearchModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, `homepageSearch`);
  };

  const closeModal = () => {
    EventEmitter.emit(EVENT_TYPES.closeModal, 'filters');
    isEqual(appliedFilters, initialAppliedFilters) &&
      Analytics.trackEvent('interaction', {
        action: 'Homepage search',
        label: 'Closed modal no filters applied',
      });
  };

  const handleNavigate = () => {
    const defaultHomepageSearchUrl =
      '/vehicles?payment_type=monthly&reset_deposit=true';
    setLoading(true);

    if (results?.count === 0) {
      Analytics.trackEvent('interaction', {
        action: 'Homepage search',
        label: 'Let me see all cars - 0 results',
      });
    }

    if (!results?.url) {
      Analytics.trackEvent('interaction', {
        action: 'Homepage search',
        label: 'Let me see all cars - no filters selected',
      });
    }

    Analytics.trackEvent('interaction', {
      action: 'Homepage search',
      label: 'Homepage search button clicked',
    });

    const navigateUrl =
      results?.url && results?.count !== 0
        ? results.url
        : defaultHomepageSearchUrl;

    window.location.href = navigateUrl;
  };

  useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  return (
    <>
      <div className="ch-display--flex ch-justify-content--center md:ch-justify-content--start">
        <div className="ch-flex--auto ch-bg--white ch-rounded--md ch-pa--2 sm:ch-ph--3 ch-mb--4 md:ch-mb--6 search-card">
          <h4 className="ch-text--center">Search over 20,000 cars</h4>
          <button
            className="ch-btn ch-btn--success ch-mb--1 ch-width--12"
            onClick={openHomepageSearchModal}
            type="button"
          >
            Find my next car
          </button>
        </div>
      </div>
      <Modal hideClose id="homepageSearch">
        <div className="ch-modal ch-modal--drawer homesearch-modal">
          <button
            className="ch-modal__close filter__modal--close"
            onClick={closeModal}
            type="button"
          >
            <span className="ch-reader">Close</span>
          </button>
          <FilterHeader
            closeFilter={closeModal}
            isSmallTitle
            resetFilters={resetFilters}
            title={"Let's find your next car"}
          />
          <VehicleSearchFiltersContainer
            appliedFilters={appliedFilters}
            availableOptions={availableOptions}
            experiments={experiments}
            filterFooter={null}
            filterHeader={null}
            onFilterCriteriaUpdate={requestSearchResults}
          />
          <FilterFooter
            buttonText={
              results?.count
                ? `Show ${results.count} cars`
                : 'Let me see all cars'
            }
            buttonType={
              results?.count === 0 ? ButtonType.secondary : ButtonType.success
            }
            isLoading={loading}
            onClick={handleNavigate}
          >
            {results?.count === 0 && (
              <section
                aria-labelledby="no-results-heading"
                className="ch-display--flex ch-flex-direction--column ch-align-items--center ch-width--12"
              >
                <header>
                  <h2 className="ch-mv--1 ch-fs--4" id="no-results-heading">
                    No results found
                  </h2>
                </header>
                <p className="ch-fs--3">Try removing some filters</p>
                <div className="ch-text-divider ch-width--12 ch-mb--2">
                  <p className="ch-fs--3 ch-fw--400 ch-mb--0 ch-color--grey-6">
                    Or
                  </p>
                </div>
              </section>
            )}
          </FilterFooter>
        </div>
      </Modal>
    </>
  );
};

export default HomeSearch;
