import React from 'react';
import PropTypes from 'prop-types';

const StatusBanner = ({ isAwaitingImages, isReserved }) => {
  const bannerText = () => {
    if (isReserved) return 'Reserved';
    if (isAwaitingImages) return 'More Images To Follow';
    return null;
  };

  return bannerText() ? (
    <div className="ac-status-banner">{bannerText()}</div>
  ) : null;
};

StatusBanner.propTypes = {
  isAwaitingImages: PropTypes.bool,
  isReserved: PropTypes.bool,
};

export default React.memo(StatusBanner);
