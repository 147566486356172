import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import { useAccessToken } from '@arnoldclark/use-access-token';
import type { AsyncResult } from '@javascript/types/AsyncResult';
import { useEffect, useState } from 'react';
import Auth from '../auth';

interface UseUserEmailReturnValue extends AsyncResult {
  userEmail: string;
}

export default function useUserEmail(): UseUserEmailReturnValue {
  const [state, setState] = useState<UseUserEmailReturnValue>({
    loading: true,
    error: null,
    userEmail: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const { isNativeApp, isLoggedIn } = acMobileFeatures();

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getEmailAddress } = useAccessToken(
          isNativeApp() && isLoggedIn(),
          () =>
            new Auth()
              .initializeAuthClient(window.authOptions)
              .then((client) => client.getTokenSilently())
        );

        const userEmail = await getEmailAddress();

        setState({ loading: false, error: null, userEmail });
      } catch (error) {
        setState({ loading: false, error: error as Error, userEmail: '' });
      }
    })();
  }, []);

  return state;
}
