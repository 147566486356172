import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VehicleReserved extends Component {
  static propTypes = {
    enquiryUrl: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="ac-result__reserved">
        <p>
          This car is currently reserved. We may have a similar vehicle in
          stock, so please{' '}
          <a href={this.props.enquiryUrl} ref="enquiryLink" rel="nofollow">
            contact us for more details
          </a>{' '}
          and we’ll get right back to you.
        </p>
      </div>
    );
  }
}
