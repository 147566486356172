import React from 'react';
import PropTypes from 'prop-types';

const GalleryError = ({ getImagePaths }) => {
  return (
    <div className="gallery__error">
      <p className="ch-fs--3 ch-color--white ch-pa--4">
        Sorry, we couldn’t retrieve the photos for this vehicle.
      </p>
      <div>
        <button
          className="ch-btn ch-btn--primary"
          onClick={getImagePaths}
          type="button"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export default GalleryError;

GalleryError.propTypes = {
  getImagePaths: PropTypes.func,
};
