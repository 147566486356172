import React from 'react';
import PropTypes from 'prop-types';
import ImageThumbnail from '@components/application/imageThumbnail';

const VehicleComparisonCard = ({
  firstPhoto,
  removeFromCompare,
  statusBanner,
  stockReference,
  title,
}) => {
  return (
    <div className="compare-width ch-ph--1 ch-pt--1 ch-flex--none ch-display--flex ch-flex-flow--column-nowrap ch-bg--grey-1">
      <button
        className="vc-remove ch-btn ch-btn--link ch-color--ac-red ch-fs--2 ch-mv--1 ch-ph--0 ch-justify-content--start"
        onClick={() => removeFromCompare(stockReference)}
        type="button"
      >
        Remove
      </button>
      <div className="ac-result__image">
        {statusBanner && <div className="ch-relative">{statusBanner}</div>}
        <ImageThumbnail height={600} imagePath={firstPhoto} width={800} />
      </div>
      <p className="ch-text--bold ch-mt--2 ch-mb--1 ch-lh--body vc-title">
        {title}
      </p>
    </div>
  );
};

VehicleComparisonCard.propTypes = {
  firstPhoto: PropTypes.string,
  removeFromCompare: PropTypes.func,
  statusBanner: PropTypes.node,
  stockReference: PropTypes.string,
  title: PropTypes.string,
};

export default VehicleComparisonCard;
