export const ANALYTICS_EVENTS = {
  VirtualPageview: 'VirtualPageview',
};

const _isDataLayerDefined = () => typeof dataLayer !== 'undefined';

const Analytics = {
  dataPush: (key, value) =>
    _isDataLayerDefined() && dataLayer.push({ [key]: value }),

  pushEvent: (event, data) => {
    _isDataLayerDefined() &&
      dataLayer.push({
        ...data,
        event,
      });
  },

  // GA4 event
  trackEvent: (eventName, params) => {
    if (!window.gtag) return;
    window.gtag('event', eventName, params);
  },
};

export default Analytics;
