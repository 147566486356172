import useShortlist from './shortlistStore';
import useVehicleSearch from '@javascript/lib/hooks/useVehicleSearch';
import { deleteSoldVehicles } from '@javascript/lib/requests/shortlist';
import { Vehicle } from '@javascript/types/Vehicle';

export default function useShortlistVehicles() {
  const {
    store: { shortlist, sold, reset, replace },
    hasHydrated,
  } = useShortlist();

  const removeUnusedStockRefs = ({
    stockRefs,
    vehicles,
  }: {
    stockRefs: string[];
    vehicles: Vehicle[];
  }) => {
    if (vehicles.length !== stockRefs.length) {
      replace(vehicles.map((vehicle: Vehicle) => vehicle.stockReference));
    }
  };

  const { error, loading, vehicles } = useVehicleSearch(shortlist, {
    onSuccess: removeUnusedStockRefs,
  });

  const { loading: soldLoading, vehicles: soldVehicles } = useVehicleSearch(
    sold,
    {
      endpoint: '/sold',
      onSuccess: deleteSoldVehicles,
    }
  );

  return {
    allStockRefs: [...shortlist, ...sold],
    error,
    loading: loading || soldLoading,
    onlySoldVehicles: shortlist.length === 0 && sold.length > 0,
    replace,
    reset,
    shortlist,
    //keeping the UI in sync whenever a user removes a vehicle from their shortlist
    shortlistedVehicles: vehicles.filter((vehicle) =>
      shortlist.includes(vehicle.stockReference)
    ),
    shortlistEmpty: shortlist.length === 0 && sold.length === 0,
    //ensuring soldVehicle cards have images
    soldVehicles: soldVehicles.filter((vehicle) => vehicle.imageCount > 0),
    storeHydrated: hasHydrated,
  };
}
