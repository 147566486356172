import { createStore } from 'zustand/vanilla';
import classNames from 'classnames';
import onAnimationEnd from '@javascript/lib/onAnimationEnd';
import { useStore } from 'zustand';
import { useEffect } from 'react';

export enum ToastType {
  default = '',
  success = 'ch-toast--success',
  danger = 'ch-toast--danger',
  warning = 'ch-toast--warning',
}

interface ToastState {
  additionalStyling: string | null;
  asOutline: boolean;
  isDismissable: boolean;
  message: string | null;
  showIcon: boolean;
  toastKey: number;
  toastType: ToastType | null;
  link?: { href: string; text: string };
}

const initialState: ToastState = {
  additionalStyling: null,
  asOutline: false,
  isDismissable: false,
  link: undefined,
  message: null,
  showIcon: false,
  toastKey: 0,
  toastType: ToastType.default,
};

const ToastStore = createStore<ToastState>()(() => initialState);

const useToastStore = () => useStore(ToastStore);

export const openToast = (
  args: Partial<ToastState> & { message: string }
): void => {
  ToastStore.setState((prevState) => ({
    ...args,
    link: args.link,
    toastKey: prevState.toastKey + 1,
  }));
};

export const clearToast = () => ToastStore.setState(initialState);

const Toast = (): JSX.Element | null => {
  //Fix for safari caching toast when navigating to other pages then hitting back with toast still on the screen
  useEffect(() => {
    window.addEventListener('pageshow', clearToast);

    return () => {
      window.removeEventListener('pageshow', clearToast);
    };
  }, []);

  const {
    additionalStyling,
    asOutline,
    isDismissable,
    link,
    message,
    showIcon,
    toastKey,
    toastType,
  } = useToastStore();

  if (!message) return null;

  return (
    <div
      className={classNames(
        'ch-toast',
        toastType,
        {
          'ch-toast--icon': showIcon,
          'ch-toast--outline': asOutline,
        },
        additionalStyling
      )}
      key={toastKey}
      ref={onAnimationEnd<HTMLDivElement>(clearToast)}
    >
      <p> {message} </p>{' '}
      {link && (
        <a
          data-gtm-track="interaction"
          data-gtm-track-action="Clicks toast link"
          data-gtm-track-label={link.text}
          href={link.href}
        >
          {link.text}
        </a>
      )}
      {isDismissable && (
        <button onClick={clearToast} type="button">
          <span className="ch-reader">Close</span>
        </button>
      )}
    </div>
  );
};

export default Toast;
