import React from 'react';
import PropTypes from 'prop-types';

const BranchContent = ({
  branchName,
  seoFriendlyBranchUrlForBranch,
  formattedDistanceForBranch,
  branchAddress,
  branchChips,
  branchContact,
}) => {
  return (
    <div className="branch-card__content ch-ph--3 ch-pv--4 ch-display--flex ch-flex-flow--column-nowrap ch-justify-content--between">
      <div>
        <a className="ch-card__block-link" href={seoFriendlyBranchUrlForBranch}>
          <h3 className="ch-fs--5 ch-color--ac-black">{branchName}</h3>
        </a>
        <div className="ch-mb--2 ch-display--flex ch-align-items--start ch-flex-flow--row-wrap">
          {branchChips}
        </div>
        {formattedDistanceForBranch && (
          <p className="branch__distance ch-mt--1 ch-mb--3 ch-pl--3">
            {`${formattedDistanceForBranch} away`}
          </p>
        )}
        <p className="ch-fs--3 ch-lh--body ch-mb--3">{branchAddress}</p>
      </div>
      {branchContact}
    </div>
  );
};

export default BranchContent;

BranchContent.propTypes = {
  branchAddress: PropTypes.string,
  branchChips: PropTypes.node,
  branchContact: PropTypes.node,
  branchName: PropTypes.string,
  formattedDistanceForBranch: PropTypes.string,
  seoFriendlyBranchUrlForBranch: PropTypes.string,
};
