import React from 'react';
import PropTypes from 'prop-types';

const BranchChips = ({ branchChips }) => {
  return branchChips.map((title) => {
    return (
      <span
        className="ch-chip ch-chip--lg ch-chip--grey-2 ch-mr--1 ch-ph--1 ch-mb--1"
        key={title}
      >
        {title}
      </span>
    );
  });
};

export default BranchChips;

BranchChips.propTypes = {
  branchChips: PropTypes.array,
};
