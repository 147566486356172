import React from 'react';
import PropTypes from 'prop-types';

const CompareButton = ({ stockRefs, stockReference, handleChange }) => {
  return (
    <div className="compare-button__wrapper">
      <input
        checked={stockRefs.includes(stockReference)}
        className="ch-checkbox"
        id={stockReference}
        onChange={(event) => {
          handleChange(stockReference, event.target.checked);
        }}
        type="checkbox"
      />
      <label
        className="compare-button ch-btn ch-btn--sm"
        htmlFor={stockReference}
      >
        Compare
      </label>
    </div>
  );
};

CompareButton.propTypes = {
  handleChange: PropTypes.func,
  stockReference: PropTypes.string,
  stockRefs: PropTypes.array,
};

export default CompareButton;
