import React from 'react';
import PropTypes from 'prop-types';

const OpenHours = ({ title, contact, isOpen, openingStatus }) => {
  return (
    <li
      className={`open-hours__container open-hours__container--${
        isOpen ? 'open' : 'closed'
      } ch-display--flex ch-justify-content--between`}
    >
      <div>
        <span className="ch-fw--500 ch-display--block">{title}</span>
        {contact && (
          <a
            className="ch-text-decoration--none"
            href={`tel:${contact.replace(/\s/g, '')}`}
          >
            {contact}
          </a>
        )}
      </div>
      <div className="ch-text--right ch-color--grey-6">
        <div className={isOpen ? 'open-hours__label--open' : ''}>
          {isOpen ? 'Open now' : 'Closed'}
        </div>
        <div>{openingStatus}</div>
      </div>
    </li>
  );
};

OpenHours.propTypes = {
  contact: PropTypes.string,
  isOpen: PropTypes.bool,
  openingStatus: PropTypes.string,
  title: PropTypes.string,
};

export default OpenHours;
