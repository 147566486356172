import React from 'react';

interface FeedbackSuccessProps {
  successHeading: string;
  successMessage: string;
}
const FeedbackSuccess = ({
  successHeading,
  successMessage,
}: FeedbackSuccessProps): JSX.Element => {
  return (
    <div className="feedback__success ch-pb--8 sm:ch-pb--0">
      <h3>{successHeading}</h3>
      <p className="sm:ch-mb--0">{successMessage}</p>
    </div>
  );
};

export default FeedbackSuccess;
