import React, { useState } from 'react';
import SearchFeedbackTextbox from './searchFeedbackTextbox';
import SearchFeedbackOptions from './searchFeedbackOptions';
import SearchFeedbackSuccess from './searchFeedbackSuccess';
import { setCookie } from '../helpers/feedbackCookieHelper';

interface SearchFeedbackProps {
  heading: string;
  name: string;
  question: string;
}

const SearchFeedback = ({
  heading,
  name,
  question,
}: SearchFeedbackProps): JSX.Element | null => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [feedbackRequired, setFeedbackRequired] = useState<boolean>(false);
  const [questionAnswered, setQuestionAnswered] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const complete = (): void => {
    setCookie(name);
    setQuestionAnswered(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>): void => {
    setError(false);
    setSelectedAnswer(e.target.value);
    e.target.value === 'Other'
      ? setFeedbackRequired(true)
      : setFeedbackRequired(false);
  };

  const handleFeedback = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setFeedback(e.target.value);
  };

  const feedbackMessage = () => {
    return selectedAnswer === 'Other' && feedback
      ? `${selectedAnswer} - ${feedback}`
      : selectedAnswer;
  };

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>): void => {
    e.preventDefault();
    selectedAnswer === '' || (selectedAnswer === 'Other' && !feedback)
      ? setError(true)
      : complete();
  };

  return (
    <div className="ch-display--flex ch-justify-content--center sm:ch-mb--8">
      <div className="ch-card ch-mb--2 search-feedback ch-ba--1 ch-bc--grey-3">
        {questionAnswered ? (
          <div className="ch-bg--grey-1 ch-pa--2 sm:ch-pa--5 ch-display--flex ch-justify-content--center">
            <SearchFeedbackSuccess
              successHeading="Feedback submitted"
              successMessage="Thanks for taking the time to give us your feedback, we'll use this to help improve our site"
            />
          </div>
        ) : (
          <div className="ch-bg--grey-1 ch-ph--2 ch-pv--5 sm:ch-pa--5 ch-display--flex ch-justify-content--center">
            <div className="search-feedback--form">
              <form onSubmit={handleSubmit}>
                <div className="ch-flex--auto sm:ch-text--center">
                  <p className="ch-fs--3">{heading}</p>
                  <p className="ch-fs--4 ch-fw--500">{question}</p>
                </div>
                <hr className="ch-mv--5 ch-centered divider ch-bg--grey-3"></hr>
                <SearchFeedbackOptions
                  error={error}
                  handleChange={handleChange}
                  selectedAnswer={selectedAnswer}
                />
                {feedbackRequired && (
                  <SearchFeedbackTextbox
                    error={error}
                    feedback={feedback}
                    handleFeedback={handleFeedback}
                  />
                )}
                <input
                  className="ch-btn ch-btn--success ch-width--12 ch-mt--2"
                  data-feedback-message={feedbackMessage()}
                  data-feedback-name={name}
                  data-gtm-track="feedback"
                  type="submit"
                  value="Confirm"
                />
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFeedback;
