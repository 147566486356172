import React, { useState, useEffect } from 'react';
import request from 'superagent';
import PropTypes from 'prop-types';
import GalleryView from './galleryView';
import GalleryError from './galleryError';
import { scrollHorizontally } from '@lib/scroller';

const ImageGallery = ({ reference, dmsid }) => {
  const [position, setPosition] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleErrorResponse = () => {
    setError(true);
    setLoading(false);
  };

  const handleSuccessResponse = (data) => {
    setError(false);
    setLoading(false);
    setPhotos(data.large_photo_paths);
    setThumbnails(data.tiny_photo_paths);
  };

  const getImagePaths = () => {
    setError(false);
    setLoading(true);

    const requestUrl = reference
      ? `/api/gallery/${reference}`
      : `/api/branch-gallery/${dmsid}`;

    request.get(requestUrl).end((error, response) => {
      if (error) return handleErrorResponse();
      return handleSuccessResponse(response.body);
    });
  };

  const previousImage = () => {
    setPosition(position === 0 ? photos.length - 1 : position - 1);
  };

  const nextImage = () => {
    setPosition(position === photos.length - 1 ? 0 : position + 1);
  };

  const onKeyDown = (event) => {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    if (event.keyCode === LEFT_ARROW) return previousImage();
    if (event.keyCode === RIGHT_ARROW) return nextImage();
  };

  const updateThumbnailScroll = (position) => {
    const IMAGE_WIDTH = 98;
    const SCROLL_OFFSET = 3 * IMAGE_WIDTH;
    const scrollPosition = position * IMAGE_WIDTH - SCROLL_OFFSET;

    scrollHorizontally(
      document.querySelector('.gallery__thumbs'),
      scrollPosition,
      400
    );
  };

  useEffect(() => {
    getImagePaths();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);

    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [previousImage, nextImage]);

  useEffect(() => {
    const isGalleryThumbs = document.querySelector('.gallery__thumbs');
    if (isGalleryThumbs) updateThumbnailScroll(position);
  }, [position]);

  return (
    <div className="gallery ac-modal--fade">
      {error && <GalleryError getImagePaths={getImagePaths} />}
      {loading && (
        <div className="gallery__loading">
          <span className="ch-skeleton" />
        </div>
      )}
      {!loading && !error && (
        <GalleryView
          nextImage={nextImage}
          photos={photos}
          position={position}
          previousImage={previousImage}
          setPosition={setPosition}
          thumbnails={thumbnails}
        />
      )}
    </div>
  );
};

export default ImageGallery;

ImageGallery.propTypes = {
  dmsid: PropTypes.string,
  reference: PropTypes.string,
};
