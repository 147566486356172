import request from 'superagent';
import { snakeCaseKeys, toQueryString } from '@utils';

const ALL_KEY = '_all'; // Value is based on backend rules. The reason for this rule is to avoid sending empty arrays

const _getRequestWithQS = () => {
  let pendingRequest;
  return (url, queryString) => {
    if (pendingRequest) pendingRequest.abort();
    pendingRequest = request.get(url).query(queryString);
    return pendingRequest;
  };
};
const _getSearch = _getRequestWithQS();

const requestFiltersSearchResults = async (queryString) => {
  const { body, req } = await _getSearch('/vehicles.json', queryString);
  const url = new URL(req.url.replace('.json', ''), window.origin);
  url.searchParams.delete('count_only');

  return { ...body, url: url.pathname + url.search };
};

const prepareMakeModelTrim = (generalFilters) => {
  return Object.keys(generalFilters.makeModel).reduce((acc, make) => {
    const model = [];

    Object.keys(generalFilters.makeModel[make].models).forEach((modelName) => {
      const trims = Object.values(
        generalFilters.makeModel[make].models[modelName].trims
      ).map((trim) => trim.displayName);

      model.push(
        Object.keys(trims).length ? { [modelName]: trims } : modelName
      );
    });

    return { ...acc, [make]: model.length ? model : [ALL_KEY] };
  }, {});
};

const buildFiltersQueryString = (generalFilters) => {
  const makeModel = prepareMakeModelTrim(generalFilters);

  return toQueryString(snakeCaseKeys({ ...generalFilters, makeModel }));
};

export {
  buildFiltersQueryString,
  requestFiltersSearchResults,
  prepareMakeModelTrim,
};
