import classNames from 'classnames';
import { ButtonType } from '@javascript/types/ButtonType';

interface FilterFooterProps {
  buttonText: string;
  buttonType: ButtonType;
  isLoading: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

const FilterFooter = ({
  isLoading,
  children,
  onClick,
  buttonText,
  buttonType,
}: FilterFooterProps) => {
  return (
    <div className="ch-modal__footer filter__footer ch-display--flex ch-flex-direction--column ch-align-items--center">
      {children}
      <button
        className={classNames(
          `filter__button ch-btn ch-btn--block ch-btn--${buttonType}`,
          {
            'ac-btn--loading': isLoading,
          }
        )}
        onClick={onClick}
        type="button"
      >
        {isLoading ? '' : buttonText}
      </button>
    </div>
  );
};

export default FilterFooter;
