import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FilterOption } from '@components/search_filters/units';
import { FilterContainer } from '../units';

const SingleSelect = ({
  availableOptions,
  id,
  name,
  onFilterUpdate,
  isLoading,
  selected,
  subtext,
  titleSubtext,
}) => {
  const [optionSelected, setOptionSelected] = useState(selected);

  const handleChange = (value) => setOptionSelected(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilterUpdate({ [id]: optionSelected });
  };

  return (
    <FilterContainer
      handleClick={handleSubmit}
      isLoading={isLoading}
      subtext={subtext}
      title={`Select ${name.toLowerCase()}`}
      titleSubtext={titleSubtext}
    >
      <form onSubmit={handleSubmit}>
        {availableOptions.map(([displayLabel, value]) => (
          <FilterOption
            autoFocus={optionSelected === value}
            key={`${id}_${value}`}
            label={displayLabel}
            name={id}
            onChange={handleChange}
            selected={optionSelected === value}
            type="radio"
            value={value}
          />
        ))}
        <button className="ch-reader" type="submit">
          Done
        </button>
      </form>
    </FilterContainer>
  );
};

export default SingleSelect;

SingleSelect.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onFilterUpdate: PropTypes.func.isRequired,
  selected: PropTypes.string,
  subtext: PropTypes.string,
  titleSubtext: PropTypes.string,
};
