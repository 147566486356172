import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VehicleResult from './vehicleResult';
import StockAlertMessage from './stockAlertMessage';
import NoResults from './noResults';
import StockChip from '@components/application/stockChip';

export default class VehicleResults extends PureComponent {
  static displayName = 'VehicleResults';
  static propTypes = {
    authenticityToken: PropTypes.string,
    clickAndCollectDetails: PropTypes.object,
    count: PropTypes.number,
    distances: PropTypes.object,
    experiments: PropTypes.object,
    isLastPage: PropTypes.bool,
    searchResults: PropTypes.array,
    stockAlertUrl: PropTypes.string,
    stockChipData: PropTypes.object,
  };

  static defaultProps = {
    stockChipData: {},
  };

  render() {
    return (
      <div className="ac-results">
        {this.props.count > 0 &&
          this.props.searchResults.map((result, index) => (
            <VehicleResult
              {...result}
              authenticityToken={this.props.authenticityToken}
              clickAndCollectDetails={this.props.clickAndCollectDetails}
              distances={this.props.distances}
              experiments={this.props.experiments}
              index={index}
              key={result.stockReference}
            >
              {this.props.stockChipData[result.stockReference]?.showChip && (
                <StockChip
                  count={this.props.stockChipData[result.stockReference].count}
                  imageCount={
                    this.props.stockChipData[result.stockReference].imageCount
                  }
                />
              )}
            </VehicleResult>
          ))}
        {this.props.count === 0 && (
          <NoResults
            experiments={this.props.experiments}
            stockAlertMessage={
              <StockAlertMessage url={this.props.stockAlertUrl} />
            }
          />
        )}
        {this.props.isLastPage && this.props.count > 0 && (
          <StockAlertMessage url={this.props.stockAlertUrl} />
        )}
      </div>
    );
  }
}
