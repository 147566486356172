// Based on smoothScroll by Alice Lieutier
// https://github.com/alicelieutier/smoothScroll

const _getTop = (element) =>
  element.nodeName === 'HTML'
    ? -window.pageYOffset
    : element.getBoundingClientRect().top + window.pageYOffset;

const _easeInOutCubic = (time) =>
  time < 0.5
    ? 4 * time * time * time
    : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1;

const _position = (start, end, elapsed, duration) =>
  elapsed > duration
    ? end
    : start + (end - start) * _easeInOutCubic(elapsed / duration);

const scrollToElement = (el, duration = 500, callback, context = window) => {
  const start = window.pageYOffset;
  const end = _getTop(el);

  const clock = Date.now();
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    function (fn) {
      window.setTimeout(fn, 15);
    };

  const step = () => {
    const elapsed = Date.now() - clock;
    if (context !== window) {
      context.scrollTop = _position(start, end, elapsed, duration);
    } else {
      window.scroll(0, _position(start, end, elapsed, duration));
    }

    if (elapsed > duration) {
      if (typeof callback === 'function') {
        callback(el);
      }
    } else {
      requestAnimationFrame(step);
    }
  };
  step();
};

const scrollHorizontally = (
  el,
  newScrollPosition,
  duration = 500,
  callback
) => {
  const start = el.scrollLeft;
  const end = newScrollPosition;

  const clock = Date.now();
  const requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    function (fn) {
      window.setTimeout(fn, 15);
    };

  const step = () => {
    const elapsed = Date.now() - clock;
    el.scrollLeft = _position(start, end, elapsed, duration);

    if (elapsed > duration) {
      if (typeof callback === 'function') {
        callback(el);
      }
    } else {
      requestAnimationFrame(step);
    }
  };
  step();
};

export { scrollHorizontally, scrollToElement };
