import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const Saving = ({ prefix, amount, suffix }) => {
  return (
    <span className="ac-pricing__saving">
      {prefix || 'Save '}
      <Money amount={amount} />
      {suffix}
    </span>
  );
};

Saving.propTypes = {
  amount: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

export default Saving;
