import Modal from '../application/modal';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

interface ShortlistRemoveAllModalProps {
  reset: () => void;
  usingApiStorage: boolean;
}

const ShortlistRemoveAllModal = ({
  reset,
  usingApiStorage,
}: ShortlistRemoveAllModalProps): JSX.Element => {
  const closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  const handleRemoveAll = () => {
    reset();
    closeModal();
  };

  return (
    <Modal hideClose id="removeAllShortlist">
      <div className="ch-modal ch-modal--drawer ch-modal--xs">
        <div className="ch-modal__header">
          <h1 className="ch-fs--3 ch-mb--0">
            Are you sure you want to remove all{' '}
            {usingApiStorage ? 'saved' : 'shortlisted'} vehicles?
          </h1>
          <button
            className="ch-modal__close"
            onClick={closeModal}
            type="button"
          >
            <span className="ch-reader">Close</span>
          </button>
        </div>
        <div className="ch-modal__body ch-ph--2 ch-pv--3 ch-bg--grey-1">
          <button
            className="ch-btn ch-mb--2 ch-color--ac-red ch-width--12"
            onClick={handleRemoveAll}
            type="button"
          >
            Remove all
          </button>
          <button
            className="ch-btn ch-width--12"
            onClick={closeModal}
            type="button"
          >
            No, cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShortlistRemoveAllModal;
