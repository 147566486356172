import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterContainer,
  ModelAndTrimOptions,
} from '@components/search_filters/units';
import { deepClone } from '@lib/utils';

const ModelSelect = ({ availableOptions, onFilterUpdate, selected }) => {
  const [makeModelTrimSelection, setMakeModelTrimSelection] = useState(
    deepClone(selected.makeModelTrimSelection)
  );

  const generateMakeModelInstance = (makeKey, models) => {
    return {
      [makeKey]: {
        displayName: makeModelTrimSelection[makeKey].displayName,
        models: {
          ...models,
        },
      },
    };
  };

  const selectModel = (makeKey, modelKey) => {
    const models = makeModelTrimSelection[makeKey].models;
    const isModelSelected = modelKey in makeModelTrimSelection[makeKey].models;

    if (isModelSelected) {
      delete models[modelKey];
    } else {
      models[modelKey] = {
        displayName: availableOptions[makeKey].models[modelKey].displayName,
        trims: {},
      };
    }

    setMakeModelTrimSelection((prevState) => {
      return { ...prevState, ...generateMakeModelInstance(makeKey, models) };
    });
  };

  const handleClearSelection = () => {
    setMakeModelTrimSelection(
      Object.keys(makeModelTrimSelection).reduce((acc, makeKey) => {
        acc[makeKey] = {
          ...makeModelTrimSelection[makeKey],
          models: {},
        };
        return acc;
      }, {})
    );
  };

  const removeModelsForMake = (makeKey) => {
    setMakeModelTrimSelection((prevState) => ({
      ...prevState,
      ...{
        [makeKey]: {
          displayName: prevState[makeKey].displayName,
          models: {},
        },
      },
    }));
  };

  const renderModelFilters = () => {
    return Object.keys(makeModelTrimSelection).map((makeKey) => {
      const { displayName: displayName, models: models } =
        availableOptions[makeKey];
      return (
        <ModelAndTrimOptions
          displayName={displayName}
          key={makeKey}
          label={'All models'}
          makeKey={makeKey}
          name={makeKey}
          options={models}
          removeSelectedOption={removeModelsForMake}
          selectOption={selectModel}
          selectedOptions={makeModelTrimSelection[makeKey].models}
          suffix="models"
        />
      );
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilterUpdate({ makeModel: makeModelTrimSelection });
  };

  return (
    <FilterContainer
      handleClearSelection={handleClearSelection}
      handleClick={handleSubmit}
      showClearButton
      title="Select models"
    >
      {renderModelFilters()}
    </FilterContainer>
  );
};

ModelSelect.propTypes = {
  availableOptions: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.object,
};

export default ModelSelect;
