import Analytics from '@lib/analytics';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type Place = {
  address_components: AddressComponent[];
};

export const getPostcodePrefix = (postcode: string): string =>
  postcode ? postcode.split(' ')[0] : '';

export const getPostcode = (place: Place): string => {
  const postcode = place.address_components?.find(
    (data) =>
      data.types.includes('postal_code_prefix') ||
      data.types.includes('postal_code')
  );
  return postcode ? postcode.long_name : '';
};

export const getTownName = (place: Place): string => {
  const town = place.address_components?.find((data) =>
    data.types.includes('postal_town')
  );
  return town ? town.long_name : '';
};

export const buildAnalyticsData = (place: Place): string => {
  const postcode = getPostcode(place);
  const townName = getTownName(place);
  const postalPrefix = getPostcodePrefix(postcode);
  const addressParts = [townName, postalPrefix].filter(Boolean);

  return addressParts.join(', ');
};

const addToAnalytics = (place: Place): void => {
  if (!place.address_components) return;

  const location = buildAnalyticsData(place);
  if (!location) return;

  Analytics.trackEvent('interaction', {
    action: 'Search Location',
    label: location,
  });
};

export default addToAnalytics;
