import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({
  checked,
  children,
  disabled,
  id,
  labelSubText,
  labelText,
  name,
  onChange,
  value,
}) => {
  const idString = () => id.replace(/\s+/g, '-').toLowerCase();

  return (
    <>
      <input
        checked={checked}
        className="ch-reader ch-radio"
        disabled={disabled}
        id={idString()}
        name={name}
        onChange={(e) => onChange(e)}
        type="radio"
        value={value}
      />
      <label
        className="ch-radio__label ch-radio__label--large ch-fs--3 ch-radio__label--primary"
        htmlFor={idString()}
      >
        {labelText}
        <small>{labelSubText}</small>
      </label>
      {children}
    </>
  );
};

export default RadioInput;

RadioInput.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  labelSubText: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
