import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

const GalleryTrigger = ({
  imageCount,
  launchImageGallery,
  showImageCount,
  stockReference,
  dmsid,
  originPage,
}) => {
  const openImageGallery = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, 'imageGallery', {
      reference: stockReference,
      dmsid: dmsid,
      originPage: originPage,
    });
  };
  return (
    <>
      {imageCount > 0 && launchImageGallery && (
        <>
          {showImageCount && (
            <div className="ac-imagethumbnail__image-count">
              {imageCount}
              <span className="ch-reader"> images</span>
            </div>
          )}
          <button
            className="ac-imagethumbnail__gallery-trigger"
            onClick={openImageGallery}
            type="button"
          >
            <span className="ch-reader">Open gallery</span>
          </button>
        </>
      )}
    </>
  );
};

export default GalleryTrigger;

GalleryTrigger.defaultProps = {
  showImageCount: true,
};

GalleryTrigger.propTypes = {
  dmsid: PropTypes.string,
  imageCount: PropTypes.number,
  launchImageGallery: PropTypes.bool,
  originPage: PropTypes.string,
  showImageCount: PropTypes.bool,
  stockReference: PropTypes.string,
};
