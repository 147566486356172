import { delimitWithCommas, pluralise } from '@lib/utils';

export const selectedEngineSizeToString = (appliedFilters) => {
  const { minEngineSize, maxEngineSize } = appliedFilters;
  if (!minEngineSize && !maxEngineSize) return;

  const inLitres = (engineSize) => (engineSize / 1000).toFixed(1);

  const formattedMin = inLitres(99 + parseInt(minEngineSize));
  const formattedMax = inLitres(maxEngineSize);

  if (minEngineSize && maxEngineSize) {
    return formattedMax === formattedMin
      ? `${formattedMax} litres`
      : `Between ${formattedMin} litres and ${formattedMax} litres`;
  }

  return minEngineSize
    ? `Over ${formattedMin} litres`
    : `Up to ${formattedMax} litres`;
};

export const selectedLocationToString = (appliedFilters) => {
  if (!appliedFilters.location) return;
  const { location, showClickAndCollectOptions } = appliedFilters;
  if (showClickAndCollectOptions)
    return { selectedOptions: `Click and Collect options for ${location}` };
  const selectedOptions = appliedFilters.distance
    ? `Up to ${appliedFilters.distance} miles from ${location}`
    : `Any distance from ${location}`;
  return { selectedOptions };
};

export const selectedMakeToString = (makeModelTrimSelection) => {
  const displayNames = Object.keys(makeModelTrimSelection).map(
    (key) => makeModelTrimSelection[key].displayName
  );

  return displayNames.join(', ');
};

export const selectedMakeModelsToString = (makeModelTrimSelection) => {
  if (Object.keys(makeModelTrimSelection).length === 0) return;

  const countSelectedModels = (make) => {
    return Object.keys(makeModelTrimSelection[make].models).length;
  };

  const countAllSelectedModels = Object.keys(makeModelTrimSelection).reduce(
    (acc, make) => {
      return acc + countSelectedModels(make);
    },
    0
  );

  if (countAllSelectedModels === 0) return;

  const displaySelectedModelCount = (make) => {
    const count =
      countSelectedModels(make) === 0 ? 'all' : countSelectedModels(make);
    return `${count} model${count === 1 ? '' : 's'}`;
  };

  return Object.keys(makeModelTrimSelection).reduce((acc, make, index) => {
    const { displayName } = makeModelTrimSelection[make];
    return index === 0
      ? `${displayName}: ${displaySelectedModelCount(make)}`
      : `${acc}, ${displayName}: ${displaySelectedModelCount(make)}`;
  }, '');
};

export const selectedMakeModelsTrimsToString = (makeModelTrimSelection) => {
  const countSelectedTrims = (make) => {
    return Object.values(makeModelTrimSelection[make].models).reduce(
      (acc, curr) => {
        const { trims: trims } = curr;
        return acc + Object.keys(trims).length;
      },
      0
    );
  };

  const countAllSelectedModelsAndTrims = Object.keys(
    makeModelTrimSelection
  ).reduce((acc, make) => {
    return acc + countSelectedTrims(make);
  }, 0);

  if (countAllSelectedModelsAndTrims === 0) return;

  return Object.values(makeModelTrimSelection).reduce((prev, curr, index) => {
    const { displayName: makeDisplayName, models: models } = curr;
    if (Object.keys(models).length === 0) {
      return index === 0
        ? `${makeDisplayName}: all models and variants`
        : `${prev}, ${makeDisplayName}: all models and variants`;
    }
    const makeAndModelNames = Object.values(models).reduce(
      (prevCurr, currCurr, indexCurr) => {
        const { displayName: modelDisplayName, trims: trims } = currCurr;
        const count = Object.keys(trims).length;
        const displayCount =
          count === 0
            ? 'all model variants'
            : `${count} ${pluralise(count, 'model variant')}`;

        return indexCurr === 0
          ? `${makeDisplayName} ${modelDisplayName}: ${displayCount}`
          : `${prevCurr}, ${makeDisplayName} ${modelDisplayName}: ${displayCount}`;
      },
      ''
    );
    return index === 0
      ? `${makeAndModelNames}`
      : `${prev}, ${makeAndModelNames}`;
  }, '');
};

export const selectedMPGToString = (appliedFilters) => {
  const appliedValue = appliedFilters.mpg;
  return appliedValue && `Over ${delimitWithCommas(appliedValue)} MPG`;
};

export const selectedElectricRangeToString = (appliedFilters) => {
  const appliedValue = appliedFilters.electricRange;
  return appliedValue && `At least ${delimitWithCommas(appliedValue)} miles`;
};
export const selectedPriceToString = (appliedFilters) => {
  const { deposit, minPrice, maxPrice, paymentType } = appliedFilters;
  const period = paymentType === 'monthly' ? ' per month' : '';

  if (!minPrice && !maxPrice && !deposit) return;

  if (!minPrice && !maxPrice && deposit) {
    return `£${deposit} deposit`;
  }

  const depositString = deposit ? `, £${deposit} deposit` : '';

  if (minPrice && maxPrice)
    return `Between £${delimitWithCommas(minPrice)} and £${delimitWithCommas(
      maxPrice
    )}${period}${depositString}`;

  return minPrice
    ? `Over £${delimitWithCommas(minPrice)}${period}${depositString}`
    : `Under £${delimitWithCommas(maxPrice)}${period}${depositString}`;
};

export const selectedRoadTaxToString = (appliedFilters) => {
  const appliedValue = appliedFilters.roadtaxCost;
  if (appliedValue === '') return '';
  if (appliedValue === '0') return 'Free';
  return `Up to £${delimitWithCommas(appliedValue)}`;
};

export const selectedKeywordsToString = (appliedFilters) => {
  const { keywords, features } = appliedFilters;
  if (!features) return;

  if (keywords === '') return `${features.join(', ')}`;
  if (features.length <= 0) return `${keywords}`;

  return `${keywords}, ${features.join(', ')}`;
};

export const selectedToUpToString = (appliedValue, units = '') => {
  if (!appliedValue) return;

  const suffix =
    +appliedValue > 1 ? units.replace('%s%', 's') : units.replace('%s%', '');

  const selectedOptions = `Up to ${delimitWithCommas(appliedValue)} ${suffix}`;
  return { selectedOptions };
};

export const selectedValueToString = (
  appliedFilters,
  filterId,
  suffix = ''
) => {
  if (
    !appliedFilters[filterId] ||
    !appliedFilters[filterId].length ||
    // Workaround for when a filter uses [''] as default
    // pending for PR 3731 to be discussed
    appliedFilters[filterId][0] === ''
  )
    return;
  if (Array.isArray(appliedFilters[filterId])) {
    const separator = suffix !== '' ? ` ${suffix}, ` : ', ';
    const joinedString = appliedFilters[filterId].join(separator);
    return !suffix ? joinedString : `${joinedString} ${suffix}`;
  }

  return appliedFilters[filterId];
};
