import React from 'react';
import PropTypes from 'prop-types';

const ImageGalleryButton = ({ imageCount, onClick }) =>
  imageCount > 0 ? (
    <button
      className="ac-result__gallery-btn ac-hidden--xs js-off--hide"
      onClick={onClick}
      type="button"
    >
      <span className="ac-result__image-count">{imageCount}</span>
      Quick look
    </button>
  ) : null;

ImageGalleryButton.propTypes = {
  imageCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default React.memo(ImageGalleryButton);
