import { Vehicle } from '@javascript/types/Vehicle';
import ImageWrapper from '../vehicle_search/vehicleResult/imageWrapper';
import { WithExperiments } from '@javascript/types/Experiment';

const SoldVehicle = (props: Vehicle & WithExperiments) => {
  const queryParams = `make=${props.make}&model=${props.model}`;
  return (
    <div className="ch-card ac-result">
      <div className="ac-status-banner">SOLD</div>
      <div className="ch-relative">
        <ImageWrapper
          imageCount={props.imageCount}
          photos={
            props.experiments?.thumbnails ? props.thumbnails : props.photos
          }
        />
      </div>
      <div className="ch-pa--3 ch-bg--grey-1 ch-flex--auto ch-display--flex ch-flex-direction--column ch-justify-content--around ">
        <p className="ch-fs--5 ch-fw--500 ch-text--center ch-mt--2 ch-mb--4">
          This car has now sold
        </p>
        <div>
          <a
            className="ch-btn ch-width--12 ch-mb--2"
            href={`/stock-alerts/new?${queryParams}`}
          >
            Set up a stock alert
          </a>
          <a
            className="ch-btn ch-btn--success ch-width--12"
            href={`/vehicles?${queryParams}`}
          >
            Shop similar vehicles
          </a>
        </div>
      </div>
    </div>
  );
};

export default SoldVehicle;
