import request from 'superagent';
import { fetchRecentlyViewed } from '@javascript/vehicle_product/helper/recentVehiclesHelper';

const similarVehicleSchema = (vehicleData) => ({
  displayName: 'Similar vehicles',
  noVehicleName: 'similar vehicles',
  key: 'similarVehicles',
  fetchVehicles: () =>
    request.get(
      `${
        vehicleData.trim
          ? `/api/similar-vehicles/${vehicleData.make}/${vehicleData.model}/${vehicleData.trim}`
          : `/api/similar-vehicles/${vehicleData.make}/${vehicleData.model}/`
      }`
    ),
});

const specialOfferSchema = {
  displayName: 'Special offers',
  noVehicleName: 'special offers',
  key: 'specialOffers',
  fetchVehicles: () => request.get('/api/special_offers'),
};

const recentlyViewedSchema = {
  displayName: 'Recently viewed',
  noVehicleName: 'recently viewed vehicles',
  key: 'recentlyViewed',
  fetchVehicles: fetchRecentlyViewed,
};

const branchStockSchema = (branchDmsid) => ({
  displayName: 'In stock',
  noVehicleName: 'in stock',
  key: 'branchVehicles',
  fetchVehicles: () => request.get(`/api/branch-vehicles/${branchDmsid}`),
});

export const useVehicleShowcaseSchema = ({
  vehicleData,
  branchDmsid,
  tabsToShow,
}) => {
  const allSchemas = [
    ...(vehicleData ? [similarVehicleSchema(vehicleData)] : []),
    specialOfferSchema,
    recentlyViewedSchema,
    ...(branchDmsid ? [branchStockSchema(branchDmsid)] : []),
  ];

  if (tabsToShow) {
    return allSchemas
      .filter((schema) => tabsToShow.includes(schema.key))
      .sort((a, b) => tabsToShow.indexOf(a.key) - tabsToShow.indexOf(b.key));
  }

  return allSchemas;
};
