import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useVehicleShowcaseSchema } from './useVehicleShowcaseSchema';
import { useVehicleShowcaseData } from './useVehicleShowcaseData';

import { VehicleShowcaseTabs } from './VehicleShowcaseTabs';
import { VehicleShowcaseContent } from './VehicleShowcaseContent';

const VehicleShowcase = ({
  currentVehicle = null,
  vehicleData = null,
  branchDmsid = null,
  page = null,
  tabsToShow = null,
  authenticityToken = null,
}) => {
  const schema = useVehicleShowcaseSchema({
    vehicleData,
    branchDmsid,
    tabsToShow,
  });

  const [activeTab, setActiveTab] = useState(schema[0].key);

  const { vehicles, loading } = useVehicleShowcaseData({
    schema,
    currentVehicle,
  });

  return (
    <div className="ac-vehicle-gallery">
      <div className="ch-bb--1 ch-bc--grey-3 ch-mb--2 sm:ch-mh--1 ch-tab__wrapper">
        <VehicleShowcaseTabs
          activeTab={activeTab}
          schema={schema}
          setActiveTab={setActiveTab}
          vehicles={vehicles}
        />
      </div>

      <VehicleShowcaseContent
        activeTab={activeTab}
        authenticityToken={authenticityToken}
        loading={loading}
        page={page}
        schema={schema}
        vehicles={vehicles}
      />
    </div>
  );
};

export default VehicleShowcase;

VehicleShowcase.propTypes = {
  authenticityToken: PropTypes.string,
  branchDmsid: PropTypes.string,
  currentVehicle: PropTypes.string,
  page: PropTypes.string,
  tabsToShow: PropTypes.arrayOf(PropTypes.string),
  vehicleData: PropTypes.oneOfType([
    PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      trim: PropTypes.string,
    }),
    PropTypes.any,
  ]),
};
