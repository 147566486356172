import { useEffect } from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

let cachedScripts = [];

export const requestScriptLoad = (thirdParty, onComplete = () => {}) => {
  EventEmitter.on(EVENT_TYPES.scriptLoaded[thirdParty], () => onComplete());

  EventEmitter.emit(EVENT_TYPES.loadScript, thirdParty);
};

const ExternalScriptHelper = ({ googleMapsUrl, worldpayUrl }) => {
  const SCRIPTS = {
    google: googleMapsUrl,
    worldpay: worldpayUrl,
  };

  useEffect(() => {
    EventEmitter.on(EVENT_TYPES.loadScript, (thirdParty) => {
      if (!cachedScripts.includes(thirdParty)) {
        cachedScripts.push(thirdParty);
        const script = document.createElement('script');
        script.src = SCRIPTS[thirdParty];

        const onScriptLoad = () =>
          EventEmitter.emit(EVENT_TYPES.scriptLoaded[thirdParty]);

        const onScriptError = () => {
          cachedScripts = cachedScripts.filter(
            (script) => script !== thirdParty
          );
          script.remove();
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        document.body.appendChild(script);
      }
    });
  }, []);

  return null;
};

export default ExternalScriptHelper;

ExternalScriptHelper.propTypes = {
  googleMapsUrl: PropTypes.string,
  worldpayUrl: PropTypes.string,
};
