import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import { scrollToElement } from '@lib/scroller';
import Modal from '@components/application/modal';

const VideoLinks = ({ isPhyronAvailable, stockReference, videoWalkaround }) => {
  const scrollToVideoWalkaround = () => {
    const videoWalkaroundPanel = document.querySelector(
      '[data-element="video-walkaround"]'
    );
    scrollToElement(videoWalkaroundPanel);
  };

  const openVideoModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, `video_${stockReference}`);
  };

  const renderVideoLink = () => {
    if (isPhyronAvailable) {
      return (
        <button
          className="js-off--hide ac-imagethumbnail__video-link"
          onClick={openVideoModal}
          type="button"
        >
          Video tour
        </button>
      );
    } else if (videoWalkaround) {
      return (
        <button
          className="js-off--hide ac-imagethumbnail__video-link"
          onClick={scrollToVideoWalkaround}
          type="button"
        >
          Video walkaround
        </button>
      );
    }
  };

  const renderVideoModal = () => {
    if (isPhyronAvailable) {
      return (
        <Modal id={`video_${stockReference}`}>
          <h2 className="ac-video-tour__load-message">Loading your video</h2>
          <div className="ac-video-tour">
            <div>
              <video
                className="phyron-video"
                data-campaign="arnoldclark"
                data-file="800x600"
                data-product-key={stockReference}
                data-tracking-source="phyron"
              ></video>
            </div>
          </div>
        </Modal>
      );
    }
  };

  return (
    <>
      {renderVideoLink()}
      {renderVideoModal()}
    </>
  );
};

VideoLinks.displayName = 'VideoLinks';

VideoLinks.propTypes = {
  isPhyronAvailable: PropTypes.bool,
  stockReference: PropTypes.string,
  videoWalkaround: PropTypes.string,
};

export default React.memo(VideoLinks);
