const ENTER_KEY = 13;

export default ({ inputElement, onFinished, onPlaceChange }) => {
  const options = {
    componentRestrictions: { country: 'GB' },
    types: ['geocode'],
    fields: ['formatted_address', 'address_components', 'geometry.location'],
  };

  const autocomplete = new window.google.maps.places.Autocomplete(
    inputElement,
    options
  );

  onFinished();

  window.google.maps.event.addDomListener(inputElement, 'keydown', (event) => {
    if (event.keyCode === ENTER_KEY) {
      event.preventDefault();
    }
  });

  autocomplete.addListener('place_changed', () =>
    onPlaceChange(autocomplete.getPlace())
  );
};
