import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';

import AccountLoading from '../account/loading';
import CompareButton from '../vehicle_comparison/compareButton';
import ComparePanel from '../vehicle_comparison/comparePanel';
import EmptyShortlist from './emptyShortlist';
import ShareShortlistButton from './shareShortlistButton';
import ShortlistError from './shortlistError';
import ShortlistHeader from './shortlistHeader';
import ShortlistLoginCard from './shortlistLoginCard';
import ShortlistRemoveAllModal from './shortlistRemoveAllModal';
import SoldVehicle from './soldVehicle';
import VehicleResult from '../vehicle_search/vehicleResult';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

import useIsAuthenticated from '@lib/hooks/useIsAuthenticated';
import { useComparison } from '../vehicle_comparison/hooks';
import useShortlistVehicles from './useShortlistVehicles';
import Auth from '@javascript/lib/auth';

import type { WithExperiments } from '@javascript/types/Experiment';

interface ShortlistProps {
  authenticityToken: string;
  sharedUrl: string;
}

const Shortlist = ({
  authenticityToken,
  experiments,
  sharedUrl,
}: ShortlistProps & WithExperiments) => {
  const { isLoggedIn, loading: authLoading } = useIsAuthenticated();

  const {
    isLoggedIn: nativeAppLoggedIn,
    isNativeApp,
    shortlistEnabled,
  } = acMobileFeatures();

  //shortlistEnabled also includes a check for a native app user
  const usingApiStorage =
    isLoggedIn || (shortlistEnabled() && nativeAppLoggedIn());

  const {
    allStockRefs,
    error,
    loading,
    onlySoldVehicles,
    reset,
    shortlist,
    shortlistEmpty,
    shortlistedVehicles,
    soldVehicles,
    storeHydrated,
  } = useShortlistVehicles();

  // Native app users without the shortlist functionality should not see log in messages
  // login message should appear when user is either
  // - a web user (not a native app) and not logged in, OR
  // - a native app user who has the shortlist enabled, and is not logged in
  const showLoginPrompts =
    (!isNativeApp() && !isLoggedIn) ||
    (shortlistEnabled() && !nativeAppLoggedIn());

  const { clearAll, handleComparison, stockRefs } = useComparison();

  const login = () =>
    new Auth()
      .initializeAuthClient(window.authOptions)
      .then((client) => client.login());

  if (authLoading || !storeHydrated) return <AccountLoading />;

  if (error) return <ShortlistError />;

  if (shortlistEmpty)
    return (
      <EmptyShortlist
        login={login}
        showLoginButton={!isNativeApp() && !isLoggedIn}
        showLoginMessage={showLoginPrompts}
      />
    );

  const openModal = () => {
    EventEmitter.emit(EVENT_TYPES.openModal, 'removeAllShortlist');
  };

  return (
    <>
      <div className="ac-shortlist ch-container ch-ph--3">
        <ShortlistHeader
          onlySoldVehicles={onlySoldVehicles}
          shortlist={shortlist}
          usingApiStorage={usingApiStorage}
        />
        {!onlySoldVehicles && (
          <>
            <div className="ch-display--flex ch-justify-content--between">
              <ShareShortlistButton
                sharedUrl={sharedUrl}
                shortlist={shortlist}
              />
              <button
                className="ac-shortlist__remove-all-link ch-color--ac-red ch-btn ch-fs--3 ch-pr--0 ch-btn--link"
                data-gtm-track="click_cta"
                data-gtm-track-label="Remove all shortlisted vehicles"
                onClick={openModal}
                type="button"
              >
                Remove all
              </button>
            </div>
            <hr className="ch-mv--3 ch-bg--grey-3" />
          </>
        )}
        {loading ? (
          <div className="ac-results">
            {allStockRefs.map((stockRef) => (
              <div
                className="ac-result ch-skeleton ac-result__skeleton"
                key={stockRef}
              />
            ))}
          </div>
        ) : (
          <>
            {showLoginPrompts && (
              <ShortlistLoginCard isAppUser={isNativeApp()} login={login} />
            )}
            <div className="ac-animation--fade-in ac-results">
              {shortlistedVehicles.map((vehicle) => (
                <VehicleResult
                  {...vehicle}
                  authenticityToken={authenticityToken}
                  compareButton={
                    <CompareButton
                      handleChange={handleComparison}
                      stockReference={vehicle.stockReference}
                      stockRefs={stockRefs}
                    />
                  }
                  experiments={experiments}
                  key={vehicle.stockReference}
                />
              ))}
              {soldVehicles.map((vehicle) => (
                <SoldVehicle
                  {...vehicle}
                  experiments={experiments}
                  key={vehicle.stockReference}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <ShortlistRemoveAllModal
        reset={reset}
        usingApiStorage={usingApiStorage}
      />
      {stockRefs.length > 0 && (
        <ComparePanel clearAll={clearAll} stockRefs={stockRefs} />
      )}
    </>
  );
};

export default Shortlist;
