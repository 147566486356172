import React from 'react';
import PropTypes from 'prop-types';
import Money from '@components/application/money';

const VehicleComparisonCardInfo = ({
  cashPrice,
  cashPricePrefix,
  stockReference,
  variant,
}) => {
  return (
    <div
      className="ch-display--flex ch-flex-flow--column-nowrap ch-ph--1 ch-flex--auto ch-justify-content--between"
      key={`card-footer-${stockReference}`}
    >
      <p className="ch-mb--1 ch-lh--body vc-variant">{variant}</p>
      <div className="ac-pricing ch-mb--2 ch-lh--base">
        <div className="ch-fs--1">{cashPricePrefix}</div>
        <div className="ch-fs--4">
          <Money amount={cashPrice} />
        </div>
      </div>
    </div>
  );
};

VehicleComparisonCardInfo.propTypes = {
  cashPrice: PropTypes.number,
  cashPricePrefix: PropTypes.string,
  stockReference: PropTypes.string,
  variant: PropTypes.string,
};

export default VehicleComparisonCardInfo;
