import { range } from '@lib/utils';

const INITIAL_PAGE_NUMBER = 1;
const MAX_PAGES_NUMBER = 5;
const SIDES_PAGES_NUMBER = 2;

const paginate = (current, total) => {
  const currentPage = +current;
  const totalPages = +total;

  const _allPagesRange = () => range(INITIAL_PAGE_NUMBER, totalPages);

  const _breaksLeftBoundary = () =>
    currentPage - SIDES_PAGES_NUMBER < INITIAL_PAGE_NUMBER;

  const _breaksRightBoundary = () =>
    currentPage + SIDES_PAGES_NUMBER > totalPages;

  const _notEnoughPages = () => totalPages < MAX_PAGES_NUMBER;

  const _pagesFromLeft = () => range(INITIAL_PAGE_NUMBER, MAX_PAGES_NUMBER);

  const _pagesFromRight = () =>
    range(totalPages - (MAX_PAGES_NUMBER - 1), totalPages);

  const _samePagesNumberEitherSide = () =>
    range(currentPage - SIDES_PAGES_NUMBER, currentPage + SIDES_PAGES_NUMBER);

  if (_notEnoughPages()) return _allPagesRange();
  if (_breaksLeftBoundary()) return _pagesFromLeft();
  if (_breaksRightBoundary()) return _pagesFromRight();
  return _samePagesNumberEitherSide();
};

export { paginate };
