import React from 'react';
import PropTypes from 'prop-types';
import Money from '../money';

const Deposit = ({ amount, isBusinessContractHire }) => {
  return (
    <span className="ac-pricing__deposit">
      <Money amount={amount} />
      <span className="ac-pricing__suffix">
        {isBusinessContractHire ? ' Initial rental ' : ' Deposit '}
      </span>
    </span>
  );
};

Deposit.propTypes = {
  amount: PropTypes.number.isRequired,
  isBusinessContractHire: PropTypes.bool,
};

export default Deposit;
