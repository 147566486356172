import React from 'react';
import PropTypes from 'prop-types';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import VehicleTitle from '@components/application/vehicle/vehicleTitle';
import ShortlistButton from '@components/shortlist/shortlistButton';
import StatusBanner from '@components/application/vehicle/statusBanner';
import ImageWrapper from './imageWrapper';
import ImageWrapperExperiment from './imageWrapperExperiment';
import VideoLinks from '@javascript/vehicle_product/videoLinks';
import ImageGalleryButton from './imageGalleryButton';
import ResultDetails from './resultDetails';
import BranchFooter from './branchFooter';

const openQuickGallery = (stockReference) => {
  EventEmitter.emit(EVENT_TYPES.openModal, 'imageGallery', {
    reference: stockReference,
    dmsid: null,
    originPage: 'Search results',
  });
};

const VehicleResult = ({
  branch,
  clickAndCollectDetails,
  compareButton,
  experiments,
  hideImageSlider,
  imageCount,
  index,
  isAlertVehicle,
  isMovable,
  isPhyronAvailable,
  photos,
  pickupAvailable,
  referrer,
  removeVehicle,
  stockReference,
  thumbnails,
  title,
  url,
  children,
  isAwaitingImages,
  isReserved,
  enquiryUrl,
  salesInfo,
  authenticityToken,
}) => {
  const handleClick = () => {
    if (referrer !== 'product-similarVehicles') return false;
  };

  return (
    <div className="ch-card ac-result" onClick={handleClick}>
      {children}
      <ShortlistButton
        authenticityToken={authenticityToken}
        stockReference={stockReference}
      />
      <StatusBanner
        isAwaitingImages={isAwaitingImages}
        isReserved={isReserved}
      />
      <div className="ch-relative">
        <a href={url}>
          {experiments?.lazy ? (
            <ImageWrapperExperiment
              hideImageSlider={hideImageSlider}
              imageCount={imageCount}
              photos={experiments?.thumbnails ? thumbnails : photos}
              resultIndex={index}
            />
          ) : (
            <ImageWrapper
              hideImageSlider={hideImageSlider}
              imageCount={imageCount}
              photos={experiments?.thumbnails ? thumbnails : photos}
            />
          )}
        </a>
        <ImageGalleryButton
          imageCount={imageCount}
          onClick={() => openQuickGallery(stockReference)}
        />
        {!!compareButton && compareButton}
        {isPhyronAvailable && (
          <VideoLinks
            isPhyronAvailable={isPhyronAvailable}
            stockReference={stockReference}
          />
        )}
      </div>
      <div className="ac-result__content">
        <VehicleTitle {...title} url={url} />
        <ul className="ac-result__summary">
          {salesInfo.summary.map((item) => (
            <li
              className={
                salesInfo.summary.length > 1
                  ? `ch-text--ellipsis ch-chip ch-chip--grey-2`
                  : `ch-text--ellipsis`
              }
              key={item}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <ResultDetails
        enquiryUrl={enquiryUrl}
        isReserved={isReserved}
        salesInfo={salesInfo}
        stockReference={stockReference}
        url={url}
      />
      <BranchFooter
        branchInfo={branch}
        clickAndCollectDetails={clickAndCollectDetails}
        pickupAvailable={pickupAvailable}
        vehicleMovable={isMovable}
      />
      {isAlertVehicle && (
        <button
          className="ch-btn ch-btn--sm ch-ma--2"
          onClick={() => removeVehicle(stockReference)}
          type="button"
        >
          Delete
        </button>
      )}
    </div>
  );
};

VehicleResult.propTypes = {
  authenticityToken: PropTypes.string,
  branch: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    distance: PropTypes.string,
    distanceToCustomer: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  clickAndCollectDetails: PropTypes.object,
  compareButton: PropTypes.node,
  enquiryUrl: PropTypes.string,
  experiments: PropTypes.object,
  hideImageSlider: PropTypes.bool,
  imageCount: PropTypes.number,
  index: PropTypes.number,
  isAlertVehicle: PropTypes.bool,
  isAwaitingImages: PropTypes.bool,
  isMovable: PropTypes.bool,
  isPhyronAvailable: PropTypes.bool,
  isReserved: PropTypes.bool,
  photos: PropTypes.array,
  pickupAvailable: PropTypes.bool,
  referrer: PropTypes.string,
  removeVehicle: PropTypes.func,
  salesInfo: PropTypes.object,
  stockReference: PropTypes.string.isRequired,
  thumbnails: PropTypes.array,
  title: PropTypes.shape({
    name: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
};

export default React.memo(VehicleResult);
