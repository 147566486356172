//https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
export const ValidPostcode =
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

// ValidPhoneNumber must contain between 7 and 15 characters, no special characters except +
export const ValidPhoneNumber = /^[+]{0,1}[0-9]{7,15}$/;

// https://github.com/arnoldclark/acdotcom/pull/5779
export const ValidEmailAddress = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/;

// ValidName any character including spaces up to max of 30 characters and minimum of 1
export const ValidName = /^[\w\W]{1,30}$/;
