import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { delimitWithCommas } from '@lib/utils';
import { PaymentSwitch } from '@components/search_filters/units';
import { DepositSetter } from '../search_filters/units';

const EMPTY_VALUE = '';
const PLAN_RANGE_TYPES = {
  maximum: 'max_price',
  minimum: 'min_price',
};

const PRICING = {
  cash: {
    minPrice: '',
    maxPrice: '',
  },
  monthly: {
    minPrice: '',
    maxPrice: '',
  },
};

const HomeBudgetSelector = ({
  onBudgetOpening,
  onPriceChange,
  priceOptions,
  selected,
  displayInModal,
  setBudgetText,
}) => {
  const { deposit, paymentType, minPrice, maxPrice } = selected;

  const [pricing, setPricing] = useState({
    ...PRICING,
    [paymentType]: { minPrice, maxPrice },
  });
  const [activeDeposit, setActiveDeposit] = useState(deposit);
  const [activePaymentType, setActivePaymentType] = useState(paymentType);
  const [budgetPopupVisible, setBudgetPopupVisible] = useState(false);

  const includeDeposit = activePaymentType !== 'cash';

  const handlePaymentTypeChange = (paymentType) =>
    setActivePaymentType(paymentType);

  const getAvailableRangeOptions = (planRange) => {
    const options = priceOptions[activePaymentType];
    const extremity =
      planRange === PLAN_RANGE_TYPES.minimum
        ? pricing[activePaymentType].maxPrice
        : pricing[activePaymentType].minPrice;
    const visibleOptions =
      planRange === PLAN_RANGE_TYPES.minimum
        ? (option) => option[1] < extremity
        : (option) => option[1] > extremity;
    return extremity === EMPTY_VALUE ? options : options.filter(visibleOptions);
  };

  const updatePlanPrice = (event) => {
    const key =
      PLAN_RANGE_TYPES.minimum === event.target.name ? 'minPrice' : 'maxPrice';
    setPricing({
      ...pricing,
      [activePaymentType]: {
        ...pricing[activePaymentType],
        [key]: event.target.value,
      },
    });
  };

  const toggleBudgetPopup = () => {
    setBudgetPopupVisible(!budgetPopupVisible);
    onBudgetOpening();
  };

  const emptyBudget = () =>
    !pricing[activePaymentType].minPrice &&
    !pricing[activePaymentType].maxPrice &&
    !activeDeposit;

  const labelSuffix = () => {
    return activePaymentType === 'cash' ? 'cash price' : 'per month';
  };

  const depositText = () => {
    const depositAmount = activeDeposit === '0' ? 'no' : `£${activeDeposit}`;
    return includeDeposit && activeDeposit ? `, ${depositAmount} deposit` : '';
  };

  const summaryText = () => {
    if (emptyBudget()) {
      displayInModal && setBudgetText('Any price');
      return 'Any price';
    }
    const minPrice = pricing[activePaymentType].minPrice
      ? `£${delimitWithCommas(pricing[activePaymentType].minPrice)}`
      : 'No min';
    const maxPrice = pricing[activePaymentType].maxPrice
      ? `£${delimitWithCommas(pricing[activePaymentType].maxPrice)}`
      : 'no max';

    const summary = `${minPrice} - ${maxPrice} ${labelSuffix()}${depositText()}`;
    return summary;
  };

  useEffect(() => {
    onPriceChange({
      deposit: activePaymentType === 'cash' ? '' : activeDeposit,
      paymentType: activePaymentType,
      ...pricing[activePaymentType],
    });
    displayInModal && setBudgetText(summaryText());
  }, [pricing, activeDeposit, activePaymentType]);

  return (
    <div className="ac-searchmask__budget-wrapper sm:ch-mt--0">
      {!displayInModal && (
        <div
          className={`ch-form__control ac-searchmask__budget-summary 
        ${summaryText().length > 30 && 'ch-fs--2'}`}
          onClick={toggleBudgetPopup}
          onKeyPress={toggleBudgetPopup}
          role="button"
          tabIndex="0"
        >
          {summaryText()}
        </div>
      )}

      <div
        className={classNames('', {
          'ac-searchmask__budget-popup': !displayInModal,
          'ac-searchmask__budget-popup--visible': budgetPopupVisible,
          'ac-searchmask__budget-popup--visible-modal': displayInModal,
        })}
      >
        <PaymentSwitch
          onChange={handlePaymentTypeChange}
          selected={activePaymentType}
        />
        <div className="ac-budgetchooser">
          <div className="ch-flex-flow--column-nowrap ch-flex--auto ch-mr--1">
            <label htmlFor="min">Minimum price</label>
            <select
              className="ch-form__control"
              id="min"
              name="min_price"
              onChange={updatePlanPrice}
              value={pricing[activePaymentType].minPrice}
            >
              <option aria-label="No minimum" value={''}>
                No min
              </option>
              {getAvailableRangeOptions(PLAN_RANGE_TYPES.minimum).map(
                ([display, value]) => {
                  return (
                    <option key={`min_${display}`} value={value}>
                      {display}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div className="ch-ml--1 ch-flex-flow--column-nowrap ch-flex--auto">
            <label htmlFor="max">Maximum price</label>
            <select
              className="ch-form__control"
              id="max"
              name="max_price"
              onChange={updatePlanPrice}
              value={pricing[activePaymentType].maxPrice}
            >
              <option aria-label="No maximum" value={''}>
                No max
              </option>
              {getAvailableRangeOptions(PLAN_RANGE_TYPES.maximum).map(
                ([display, value]) => {
                  return (
                    <option key={`max_${display}`} value={value}>
                      {display}
                    </option>
                  );
                }
              )}
            </select>
          </div>
          {includeDeposit && (
            <div className="ac-deposit-setter__home ch-mb--1 ch-width--12">
              <DepositSetter
                deposit={activeDeposit}
                handleChange={setActiveDeposit}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeBudgetSelector;

HomeBudgetSelector.propTypes = {
  displayInModal: PropTypes.bool,
  onBudgetOpening: PropTypes.func,
  onPriceChange: PropTypes.func,
  priceOptions: PropTypes.shape({
    cash: PropTypes.array,
    monthly: PropTypes.array,
  }),
  selected: PropTypes.shape({
    deposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paymentType: PropTypes.oneOf(['cash', 'monthly']),
  }),
  setBudgetText: PropTypes.func,
};
