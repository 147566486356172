import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Money from '@components/application/money';

const nonMoneyFormattedValues = [
  'Annual mileage',
  'Contract mileage',
  'Excess mileage charge',
  'Fixed interest rate',
  'Term (months)',
  'apr',
];

export default class Illustration extends Component {
  static propTypes = {
    caption: PropTypes.string,
    financeData: PropTypes.object,
  };

  formatValue = (key, value) => {
    if (!nonMoneyFormattedValues.includes(key) || key.match(/ deposit$/)) {
      return <Money amount={parseFloat(value)} />;
    }

    if (key === 'Excess mileage charge') {
      return (
        <>
          {value} <span className="ch-fs--3"> per mile</span>
        </>
      );
    }

    return value;
  };

  formatLabel = (key) => {
    if (key === 'apr') return 'Representative APR';
    if (key === 'Guaranteed future value') return 'Optional final payment';
    return key;
  };

  excludedFromIllustrationTableBody = (key) => {
    return (
      [
        'Description',
        'Dealer Days In Stock',
        'Finance type',
        'Monthly payment',
        'Number of payments',
      ].includes(key) || !this.props.financeData[key]
    );
  };

  render() {
    return (
      <table className="ac-illustration">
        {this.props.caption && (
          <caption className="ac-repexample__caption">
            {this.props.caption}
          </caption>
        )}
        {this.props.financeData['Monthly payment'] && (
          <thead className="ac-illustration__header">
            <tr>
              <th>
                {this.props.financeData['Number of payments']
                  ? this.props.financeData['Number of payments']
                  : this.props.financeData['Term (months)']}{' '}
                monthly payments of
              </th>
              <td>
                {this.formatValue(
                  'Monthly payment',
                  this.props.financeData['Monthly payment']
                )}
              </td>
            </tr>
          </thead>
        )}
        <tbody className="ac-illustration__body">
          {Object.keys(this.props.financeData).map((key) => {
            if (this.excludedFromIllustrationTableBody(key)) return;

            const value = this.props.financeData[key];
            return (
              <tr key={key + '-' + value}>
                <th>{this.formatLabel(key)}</th>
                <td>{this.formatValue(key, value)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
