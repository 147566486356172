import React from 'react';
import PropTypes from 'prop-types';
import { delimitWithCommas, deepClone } from '@lib/utils';
import { MAKE_MODEL_KEY } from './vehicleSearchContainer';

const PREFIX = {
  over: 'Over',
  upTo: 'Up to',
  atLeast: 'At least',
};

const MAKE_MODEL_DIVIDER = '|';

const excludedFilters = [
  'branchId',
  'capcode',
  'dor',
  'franchise',
  'locationLat',
  'locationLng',
  'make', // Legacy filter that may be present in url,
  'model', // Legacy filter that may be present in url
  'monthlyPayment',
  'page',
  'page',
  'paymentType',
  'price',
  'showClickAndCollectOptions',
  'sortOrder',
];

const withSearchTags = (Component) => {
  const WrappedComponent = (props) => {
    const { filters, onFilterCriteriaUpdate } = props;
    const isMultiSelect = (filter) => Array.isArray(filter);

    const removeFilterTag = (filter, value) => {
      const { [filter]: toUpdate } = filters;

      if (isMultiSelect(toUpdate)) {
        const updatedValue = toUpdate.filter(
          (filterVal) => filterVal !== value
        );
        return onFilterCriteriaUpdate({ [filter]: updatedValue });
      }

      if (filter === MAKE_MODEL_KEY) {
        const [make, model, trim] = value.split(MAKE_MODEL_DIVIDER);
        const { [MAKE_MODEL_KEY]: updatedValue } = filters;
        const makeModelClone = deepClone(updatedValue);

        if (trim) {
          delete makeModelClone[make].models[model].trims[trim];
        } else if (model) {
          delete makeModelClone[make].models[model];
        } else {
          delete makeModelClone[make];
        }

        return onFilterCriteriaUpdate({ [filter]: makeModelClone });
      }

      if (filter === 'location') {
        return onFilterCriteriaUpdate({
          ['showClickAndCollectOptions']: false,
          ['distance']: '',
          [filter]: '',
        });
      }

      onFilterCriteriaUpdate({ [filter]: '' });
    };

    const shouldHaveTag = (filter, filterValue) => {
      if (excludedFilters.includes(filter)) return false;
      return (
        (isMultiSelect(filterValue) && filterValue.length) ||
        (!isMultiSelect(filterValue) && !!filterValue)
      );
    };

    const generateLabelFor = (filter, value) => {
      const _prefixLabel =
        (prefix, withSpace) =>
        ({ value }) =>
          withSpace ? `${prefix} ${value}` : `${prefix}${value}`;
      const _suffixLabel =
        (suffix) =>
        ({ value }) =>
          `${value} ${suffix}`;
      const _fullLabel = (prefix, suffix, withSpace) => (values) =>
        _suffixLabel(suffix)({
          value: _prefixLabel(prefix, withSpace)(values),
        });
      const _engineSizeLabel =
        (type) =>
        ({ value }) => {
          const inLitres = (value) => (value / 1000).toFixed(1);
          const formattedValue =
            type === 'min' ? inLitres(99 + parseInt(value)) : inLitres(value);
          return `${formattedValue} litre ${type} engine size`;
        };
      const _priceLabel =
        (type) =>
        ({ value, paymentType }) =>
          `£${value} ${type} ${paymentType} ${
            paymentType === 'cash' ? 'price' : 'payment'
          }`;

      const _makeModelLabel = ({ displayName }) => displayName;

      const labelFor = {
        minPrice: _priceLabel('min'),
        maxPrice: _priceLabel('max'),
        age: _fullLabel(PREFIX.upTo, 'years', true),
        deposit: _fullLabel('£', 'deposit', false),
        distance: _fullLabel(PREFIX.upTo, 'miles from your location', true),
        doors: _suffixLabel('doors'),
        mileage: _fullLabel(PREFIX.upTo, 'miles', true),
        seats: _suffixLabel('seats'),
        roadtaxCost: _fullLabel(`${PREFIX.upTo} £`, 'road tax', false),
        mpg: _fullLabel(PREFIX.over, 'MPG', true),
        electricRange: _fullLabel(PREFIX.atLeast, 'miles', true),
        unreservedOnly: () => 'Hide reserved cars',
        [MAKE_MODEL_KEY]: _makeModelLabel,
        minEngineSize: _engineSizeLabel('min'),
        maxEngineSize: _engineSizeLabel('max'),
        evCampaign: () => 'Discounted home charger',
        spotlightCar: () => 'Spotlight cars',
      };

      const labelingFunc = labelFor[filter];
      return labelingFunc ? labelingFunc(value) : value.value;
    };

    const createTag = (type, value, labelingValues) => ({
      type,
      value,
      label: generateLabelFor(type, labelingValues),
    });

    const buildFilterTagSchema = (type, filterValue, depending) => {
      const value = +filterValue ? delimitWithCommas(filterValue) : filterValue;
      const values = { value, ...depending };

      if (isMultiSelect(value))
        return value.map((value) =>
          createTag(type, value, { value, ...depending })
        );

      if (type === MAKE_MODEL_KEY) {
        const _generateModelsTags = (make, models) =>
          Object.keys(models).map((model) =>
            createTag(
              type,
              `${make}${MAKE_MODEL_DIVIDER}${model}`,
              models[model]
            )
          );

        const _generateTrimTags = (make, models) => {
          const tags = [];
          Object.entries(models).forEach((model) => {
            const [modelKey, modelData] = model;

            Object.entries(modelData.trims).forEach((trim) => {
              const [trimKey, trimData] = trim;

              tags.push(
                createTag(
                  type,
                  `${make}${MAKE_MODEL_DIVIDER}${modelKey}${MAKE_MODEL_DIVIDER}${trimKey}`,
                  trimData
                )
              );
            });
          });

          return tags;
        };

        return Object.keys(value).reduce((acc, make) => {
          const makeDetails = value[make];
          const makeTag = createTag(type, make, makeDetails);
          return [
            ...acc,
            ..._generateModelsTags(make, makeDetails.models),
            ..._generateTrimTags(make, makeDetails.models),
            makeTag,
          ];
        }, []);
      }
      return [createTag(type, value, values)];
    };

    const searchTags = Object.keys(filters).reduce((acc, filter) => {
      const { paymentType } = filters;
      if (!shouldHaveTag(filter, filters[filter])) return acc;
      const filtersSchemas = buildFilterTagSchema(filter, filters[filter], {
        paymentType,
      });
      return [...acc, ...filtersSchemas];
    }, []);

    return (
      <Component
        removeFilterTag={removeFilterTag}
        searchTags={searchTags}
        {...props}
      />
    );
  };

  WrappedComponent.propTypes = {
    filters: PropTypes.object,
    onFilterCriteriaUpdate: PropTypes.func.isRequired,
  };

  return WrappedComponent;
};

export default withSearchTags;
