import React from 'react';
import PropTypes from 'prop-types';

export const VehicleShowcaseNoVehicles = ({ noVehicleName }) => {
  return (
    <div className="ac-vehicle-gallery__no-vehicles ch-display--flex ch-justify-content--center ch-align-items--center">
      <h3>You don&#39;t seem to have any {noVehicleName} at the moment.</h3>
    </div>
  );
};

VehicleShowcaseNoVehicles.propTypes = {
  noVehicleName: PropTypes.string,
};
