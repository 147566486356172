import { useState, useEffect } from 'react';

/**
 * Converts an array of tabs represented by a schema to an object with keys
 * initialized as empty arrays. (To use within useState())
 *
 * @param {Array<Object>} schema - An array of schema objects, each containing a 'key' property.
 * @returns {Object} - An object with keys derived from the 'key' property of each schema object
 *                    in the schema, with corresponding values initialized as empty arrays.
 */
const convertSchemaKeysToState = (schema) =>
  schema.reduce(
    (initialState, schemaObject) => ({
      ...initialState,
      [schemaObject.key]: [],
    }),
    {}
  );

export const useVehicleShowcaseData = ({ schema, currentVehicle }) => {
  const initialState = convertSchemaKeysToState(schema);

  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState(initialState);

  useEffect(() => {
    const requests = schema.map((gallery) => {
      return gallery.fetchVehicles();
    });

    Promise.all(requests)
      .then((result) => {
        const newState = Object.keys(vehicles).reduce(
          (newState, key, index) => {
            newState[key] = currentVehicle
              ? result[index].body.filter(
                  (vehicle) => vehicle.stockReference !== currentVehicle
                )
              : result[index].body;
            return newState;
          },
          {}
        );

        setVehicles(newState);
      })
      .catch((err) => {
        if (window.newrelic) {
          window.newrelic.noticeError(`Vehicle Showcase error: ${err.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { vehicles, loading };
};
