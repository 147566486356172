import { openToast, ToastType } from '../application/toast';

export enum AccountEvent {
  login = 'login',
  logout = 'logout',
}

export type AccountEventMessage = {
  event: AccountEvent;
  tabID: string;
};

export default function AccountListener(): null {
  if (typeof window !== 'undefined') {
    const query = window.location.search;
    const loginError = query.includes('login_error=true');

    if (!window.tabID) {
      window.tabID = Math.random().toString(36).substr(2, 9);
    }

    if (loginError) {
      openToast({
        message: 'Something went wrong logging in. Please try again.',
        toastType: ToastType.warning,
        showIcon: true,
        isDismissable: true,
      });
    }
  }

  const accountEvents = new BroadcastChannel('accountEvents');

  accountEvents.onmessage = ({
    data: { event, tabID },
  }: MessageEvent<AccountEventMessage>) => {
    //check to see if the account event is happening on the user's current tab
    //and if so, do nothing
    if (tabID === window.tabID || !Object.values(AccountEvent).includes(event))
      return;

    //reloading also means that all open tabs will always reflect user's logged-in/out state
    window.location.reload();
  };

  return null;
}
