import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from '@components/application/progressiveImage';
import GalleryThumbs from './galleryThumbs';

const GalleryView = ({
  nextImage,
  photos,
  position,
  previousImage,
  setPosition,
  thumbnails,
}) => {
  return (
    <>
      <div className="gallery__images ac-animation--fade-in">
        {photos.map((photo, index) => (
          <div
            className={`gallery__image ${position === index ? 'active' : ''}`}
            key={photo}
            onClick={nextImage}
          >
            <ProgressiveImage
              height={600}
              image={photo}
              placeholder="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='600' viewBox='0 0 800 600'%3E%3Cpath fill='%23000000' d='M0 0h800v600H0z'/%3E%3C/svg%3E"
              width={800}
            />
          </div>
        ))}
      </div>
      {photos.length > 1 && (
        <>
          <button
            className="gallery__nav gallery__nav--previous"
            onClick={previousImage}
            type="button"
          >
            <span className="ch-reader">Previous</span>
          </button>
          <button
            className="gallery__nav gallery__nav--next"
            onClick={nextImage}
            type="button"
          >
            <span className="ch-reader">Next</span>
          </button>
        </>
      )}
      <div className="gallery__footer">
        <p className="gallery__counter">
          {position + 1} of {thumbnails.length}
        </p>
        <GalleryThumbs
          position={position}
          setPosition={setPosition}
          thumbnails={thumbnails}
        />
      </div>
    </>
  );
};

export default GalleryView;

GalleryView.propTypes = {
  nextImage: PropTypes.func,
  photos: PropTypes.array,
  position: PropTypes.number,
  previousImage: PropTypes.func,
  setPosition: PropTypes.func,
  thumbnails: PropTypes.array,
};
