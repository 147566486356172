import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isBrowser } from '@lib/utils';

export default class Recaptcha extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    isTest: PropTypes.bool,
    recaptchaSiteKey: PropTypes.string,
  };

  state = {
    isBrowser: isBrowser(),
    isValid: true,
  };

  componentDidMount() {
    var recaptchaLibrary = document.createElement('script');
    recaptchaLibrary.type = 'text/javascript';
    recaptchaLibrary.src = 'https://www.google.com/recaptcha/api.js';
    document.getElementsByTagName('head')[0].appendChild(recaptchaLibrary);
  }

  validate = () => {
    if (
      window.grecaptcha &&
      window.grecaptcha.getResponse() === '' &&
      !this.props.isTest
    ) {
      this.setState({ isValid: false });
      return false;
    }

    this.setState({ isValid: true });
    return true;
  };

  renderValidatonErrors = () => {
    if (!this.state.isValid) {
      return (
        <p className="ac-validation__message--fail ac-animation--fade-in">
          {this.props.errorMessage}
        </p>
      );
    }
  };

  renderNonJSRecaptcha = () => {
    if (!this.state.isBrowser) {
      return (
        <div className="g-recaptcha__nojs" ref="recaptchaTargetNoJS">
          <iframe
            className="g-recaptcha__nojs-iframe"
            scrolling="no"
            src={
              'https://www.google.com/recaptcha/api/fallback?k=' +
              this.props.recaptchaSiteKey
            }
            title="Recaptcha"
          />
          <div className="g-recaptcha__nojs--response-holder">
            <label className="ch-reader" htmlFor="g-recaptcha-response">
              Copy and paste the recaptcha code into here
            </label>
            <textarea
              className="g-recaptcha-response"
              id="g-recaptcha-response"
              name="g-recaptcha-response"
            />
          </div>
        </div>
      );
    }
  };

  renderJSRecaptcha = () => {
    return (
      <div
        className={classNames('g-recaptcha', {
          'ac-validation--invalid': !this.state.isValid,
        })}
        data-sitekey={this.props.recaptchaSiteKey}
        ref="recaptchaTarget"
      />
    );
  };

  render() {
    return (
      <div>
        {this.renderJSRecaptcha()}
        {this.renderNonJSRecaptcha()}
        {this.renderValidatonErrors()}
      </div>
    );
  }
}
