import React from 'react';

const BestDealGuaranteeBanner = () => {
  return (
    <a
      className="ac-promo__bdg ch-color--white ch-text--normal"
      href="/best-deal-guarantee"
      target="_blank"
    >
      <div className="ac-promo ch-fw--500 ch-text--center ch-bg--ac-black ch-color--white ch-bc--black">
        <p className="ch-flex--auto ch-mb--0">
          The UK&apos;s{' '}
          <span className="ch-color--ac-yellow ch-fw--500">
            best used car deals
          </span>{' '}
          guaranteed.
        </p>
      </div>
    </a>
  );
};

export default BestDealGuaranteeBanner;
