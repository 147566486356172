import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

export default class Modal extends Component {
  static displayName = 'Modal';
  static propTypes = {
    children: PropTypes.node,
    hideClose: PropTypes.bool,
    id: PropTypes.string,
    setWindowHistory: PropTypes.bool,
    shouldResetScroll: PropTypes.bool,
  };

  static defaultProps = {
    hideClose: false,
  };

  state = {
    additionalProps: null,
    isClosing: false,
    isOpen: false,
    scrollPosition: 0,
  };

  componentDidMount() {
    EventEmitter.on(EVENT_TYPES.openModal, this.userRequestedOpen);
    EventEmitter.on(EVENT_TYPES.closeModal, this.userRequestedClose);
    window.addEventListener('keydown', this.onKeyDown, true);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('pageshow', this.handlePageShow);
  }

  componentWillUnmount() {
    EventEmitter.off(EVENT_TYPES.openModal, this.userRequestedOpen);
    EventEmitter.off(EVENT_TYPES.closeModal, this.userRequestedClose);
    window.removeEventListener('keydown', this.onKeyDown, true);
    window.removeEventListener('pageshow', this.handlePageShow);
    window.removeEventListener('popstate', this.popState);
    window.removeEventListener('resize', this.handleResize);
  }

  handlePageShow = (event) => {
    if (event.persisted && this.state.isOpen) {
      document.body.classList.remove('ac-body--scrolling-locked');
      const scrollYPosition = this.props.shouldResetScroll
        ? 0
        : this.state.scrollPosition;
      window.scroll(0, scrollYPosition);
      this.setState({
        additionalProps: null,
        isClosing: false,
        isOpen: false,
        scrollPosition: 0,
      });
    }
  };

  handleResize = () => {
    if (this.state.isOpen) {
      document.body.classList.remove('ac-body--scrolling-locked');
      document.body.offsetHeight;
      document.body.classList.add('ac-body--scrolling-locked');
    }
  };

  userRequestedOpen = (id, additionalProps) => {
    if (this.props.id === id) return this.openModal(additionalProps);
  };

  openModal = (additionalProps) => {
    if (this.props.setWindowHistory) this.setWindowRestorePoint();
    this.setState(
      {
        additionalProps,
        initialPath: window.location.href,
        initialTitle: document.title,
        isOpen: true,
        scrollPosition: window.pageYOffset,
      },
      this.setWindowHistoryPoint
    );
    document.body.classList.add('ac-body--scrolling-locked');
  };

  setWindowRestorePoint = () => {
    window.history.replaceState(this.state, '');
    window.addEventListener('popstate', this.popState);
  };

  setWindowHistoryPoint = () => {
    if (this.props.setWindowHistory) window.history.pushState(this.state, '');
  };

  popState = (event) => {
    if (!event.state) return;
    if (event.state.isOpen) return this.openModal();
    if (!event.state.isOpen) return this.closingModal();
  };

  userRequestedClose = () => {
    if (!this.state.isOpen) return;
    if (this.props.setWindowHistory) return window.history.back();
    this.closingModal();
  };

  closingModal = () => {
    document.body.classList.remove('ac-body--scrolling-locked');
    const scrollYPosition = this.props.shouldResetScroll
      ? 0
      : this.state.scrollPosition;
    window.scroll(0, scrollYPosition);
    this.setState({ isClosing: true });
    setTimeout(() => this.closeModal(), 200);
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
      isClosing: false,
    });
    EventEmitter.emit(EVENT_TYPES.modalClosed);
  };

  onKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.userRequestedClose();
    }
  };

  // You can only inject additionalProps if this.props.children
  // is a React component

  modalContent = () => {
    if (this.state.additionalProps) {
      return React.cloneElement(this.props.children, {
        ...this.state.additionalProps,
      });
    }
    return this.props.children;
  };

  renderClose = () => {
    if (!this.props.hideClose) {
      return (
        <button
          className={`ac-modal__close ac-modal__close--${this.props.id}`}
          onClick={this.userRequestedClose}
          type="button"
        >
          <span className="ch-reader">Close</span>
        </button>
      );
    }
  };

  render() {
    if (this.state.isOpen) {
      return (
        <FocusTrap
          focusTrapOptions={{
            initialFocus: false,
            fallbackFocus: 'body',
          }}
        >
          <div
            aria-modal="true"
            className={classNames(
              'ac-modal__wrapper',
              `ac-modal__wrapper--${this.props.id}`,
              {
                'ac-modal__wrapper--closing': this.state.isClosing,
              }
            )}
            role="dialog"
          >
            <div
              className="ch-mask"
              onClick={this.userRequestedClose}
              role="button"
            />
            {this.renderClose()}
            {this.modalContent()}
          </div>
        </FocusTrap>
      );
    }

    return null;
  }
}
