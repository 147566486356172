import React from 'react';

const AdditionalBanner = () => {
  return (
    <div className="ac-promo__additional-banner-message ch-bg--ac-black ch-display--flex ch-justify-content--center ch-align-items--center">
      <p className="ch-color--white ch-text--bold ch-mb--0">
        Plus get an{' '}
        <span className="ch-color--ac-yellow ch-text--bold">extra £500</span>{' '}
        when you part-exchange.
      </p>
      <a
        className="ac-promo__link ch-color--ac-black additional-banner-icon ch-ml--1"
        href="/voucher"
        target="_blank"
      >
        <span className="ch-reader">Find out more</span>
      </a>
    </div>
  );
};

export default AdditionalBanner;
