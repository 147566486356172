import React from 'react';

const BestDealGuaranteeHomeBanner = () => {
  return (
    <div className="ac-promo ac-promo--bdg-promo ch-color--white ch-text--bold">
      <img alt="" className="ac-promo__bdg-promo-cars-image ch-mh--2" />
      <img alt="" className="ac-promo__bdg-promo-divider" />
      <div className="ac-promo__heading">
        <p className="ch-fs--4 md:ch-fs--5">
          We guarantee the{' '}
          <span className="ch-color--ac-yellow">best used car deals</span> in
          the UK.
        </p>
        <div className="ch-display--flex ch-justify-content--center sm:ch-justify-content--start sm:ch-align-items--end">
          <a
            className="ac-promo__link ch-color--white ch-text--normal"
            href="/best-deal-guarantee"
            target="_blank"
          >
            Find out more
          </a>
        </div>
      </div>
    </div>
  );
};

export default BestDealGuaranteeHomeBanner;
