import React from 'react';
import PropTypes from 'prop-types';
import {
  FilterContainer,
  FilterOption,
} from '@components/search_filters/units';
import { useMultiSelect } from '../hooks';

const MultiSelect = ({
  availableOptions,
  id,
  name,
  onFilterUpdate,
  selected,
}) => {
  const { selectedOptions, handleChange, resetSelected } =
    useMultiSelect(selected);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilterUpdate({ [id]: selectedOptions });
  };

  return (
    <FilterContainer
      handleClick={handleSubmit}
      title={`Select ${name.toLowerCase()}`}
    >
      <form onSubmit={handleSubmit}>
        <FilterOption
          key={`${id}_all`}
          label="Any"
          name={name}
          onChange={resetSelected}
          selected={selectedOptions.length === 0}
          type="checkbox"
          value=""
        />
        {availableOptions.map(([displayLabel, value]) => (
          <FilterOption
            key={`${id}_${value}`}
            label={displayLabel}
            name={name}
            onChange={handleChange}
            selected={selectedOptions.includes(value)}
            type="checkbox"
            value={value}
          />
        ))}
        <button className="ch-reader" type="submit">
          Done
        </button>
      </form>
    </FilterContainer>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  availableOptions: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.array,
};
