import React from 'react';
import PropTypes from 'prop-types';

import HorizontalScroller from '@components/application/horizontalScroller';
import VehicleResult from '@components/vehicle_search/vehicleResult';

import { VehicleShowcaseLoading } from './VehicleShowcaseLoading';
import { VehicleShowcaseNoVehicles } from './VehicleShowcaseNoVehicles';

export const VehicleShowcaseContent = ({
  activeTab,
  loading,
  page,
  vehicles,
  schema,
  authenticityToken,
}) => {
  if (loading) return <VehicleShowcaseLoading />;

  if (vehicles[activeTab].length === 0) {
    return (
      <VehicleShowcaseNoVehicles
        noVehicleName={schema[activeTab]?.noVehicleName.toLowerCase()}
      />
    );
  }

  return (
    <HorizontalScroller>
      {vehicles[activeTab].map((vehicle) => (
        <VehicleResult
          {...vehicle}
          authenticityToken={authenticityToken}
          hideImageSlider
          key={vehicle.stockReference}
          referrer={`${page}-${activeTab}`}
        />
      ))}
    </HorizontalScroller>
  );
};

VehicleShowcaseContent.propTypes = {
  activeTab: PropTypes.string,
  authenticityToken: PropTypes.string,
  loading: PropTypes.bool,
  page: PropTypes.string,
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      noVehicleName: PropTypes.string,
    })
  ),
  vehicles: PropTypes.shape({
    [PropTypes.any]: PropTypes.arrayOf(PropTypes.any),
  }),
};
