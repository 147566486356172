import React from 'react';
import PropTypes from 'prop-types';

const ProgressDisplay = ({ currentStep, stepName, stepped, totalSteps }) => {
  const progress =
    currentStep > totalSteps
      ? 100
      : Math.round((100 / totalSteps) * currentStep);

  const complete = currentStep > totalSteps;

  return (
    <div
      className={`ch-container ch-stepper ${
        complete && 'ch-stepper--complete'
      } ch-pt--2 ch-ph--2`}
    >
      <div className="ch-stepper__header">
        <strong>{stepName}</strong>
        <span className="ch-stepper__percentage">
          {stepped ? `${currentStep} of ${totalSteps}` : `${progress}%`}
        </span>
      </div>
      <div
        className={`ch-progress ch-mh--n2 sm:ch-mh--0 ${
          stepped && `ch-progress--${totalSteps}-step`
        }`}
      >
        <div
          className="ch-progress__bar"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressDisplay;

ProgressDisplay.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepName: PropTypes.string,
  stepped: PropTypes.bool,
  totalSteps: PropTypes.number.isRequired,
};
