import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ isLoading }) =>
  isLoading ? (
    <div className="ch-relative">
      <div className="ac-loading__wrapper">
        <span className="ac-loading__spinner" />
      </div>
    </div>
  ) : null;

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool,
};

export default LoadingSpinner;
