/* eslint-disable */
if (
  'addEventListener' in window &&
  'classList' in document.documentElement &&
  'localStorage' in window &&
  'querySelector' in document
) {
  // Support component names relative to this directory:
  var componentRequireContext = require.context('components', true);
  var ReactRailsUJS = require('react_ujs');
  ReactRailsUJS.useContext(componentRequireContext);
}
