import { MakeModel, Model } from '@javascript/types/FilterSearch';
import { useCallback } from 'react';

const useTrackAppliedFiltersForGA = () => {
  const trackAppliedFiltersForGA = useCallback((filter): string => {
    const result: string[] = [];

    const processMakeModel = ({ displayName: makeName, models }: MakeModel) => {
      if (!Object.keys(models).length) {
        result.push(`makeModel: ${makeName}`);
      } else {
        Object.values(models).forEach(
          ({ displayName: modelName, trims }: Model) => {
            const trimNames = Object.values(trims).map(
              (trim) => trim.displayName
            );
            if (trimNames.length) {
              trimNames.forEach((trimName) =>
                result.push(
                  `makeModel: ${makeName} - ${modelName} - ${trimName}`
                )
              );
            } else {
              result.push(`makeModel: ${makeName} - ${modelName}`);
            }
          }
        );
      }
    };

    Object.entries(filter).forEach(([key, value]) => {
      if (value && value !== '' && (!Array.isArray(value) || value.length)) {
        if (key === 'makeModel') {
          Object.values(value).forEach((v) => processMakeModel(v as MakeModel));
        } else {
          const values = Array.isArray(value) ? value : [value];
          values.forEach((val) => val && result.push(`${key}: ${val}`));
        }
      }
    });

    return result.join(', ');
  }, []);

  return { trackAppliedFiltersForGA };
};

export default useTrackAppliedFiltersForGA;
