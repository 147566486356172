import React from 'react';
import PropTypes from 'prop-types';

const SelectList = ({
  className,
  defaultSelectedOption,
  id,
  name,
  onChange,
  optionsForSelect,
  selectedOption,
}) => {
  const handleChange = (event) => {
    if (onChange) return onChange(event.target.name, event.target.value);
  };

  const generateOptions = () => {
    return optionsForSelect.map((option) => (
      <option key={`${name}-${option[1]}`} value={option[1]}>
        {option[0]}
      </option>
    ));
  };

  return (
    <select
      className={className}
      defaultValue={defaultSelectedOption ? defaultSelectedOption : undefined}
      id={id}
      name={name}
      onBlur={handleChange}
      onChange={handleChange}
      value={
        !defaultSelectedOption && selectedOption ? selectedOption[0] : undefined
      }
    >
      {generateOptions()}
    </select>
  );
};

SelectList.propTypes = {
  className: PropTypes.string,
  defaultSelectedOption: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optionsForSelect: PropTypes.array,
  selectedOption: PropTypes.array,
};

SelectList.defaultProps = {
  optionsForSelect: [],
};

export default React.memo(SelectList);
