import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const AlsoFromArnold = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showIcons, setShowIcons] = useState(false);

  const lazyLoadIcons = () => {
    if (window.pageYOffset >= 500 && showIcons === false) {
      window.removeEventListener('scroll', lazyLoadIcons);
      setShowIcons(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', lazyLoadIcons);
  }, []);

  const toggleListCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={classNames('ac-alsofrom', {
        'ac-alsofrom--collapsed': isCollapsed,
        'ac-alsofrom--showicons': showIcons,
      })}
    >
      <h2 className="ch-h3">Also from Arnold</h2>
      <div className="ch-row">
        <div className="sl:ch-col--6 lg:ch-col--4 lg:ch-pr--0 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link lg:ch-pr--0 ac-alsofrom__link--contact"
            href="/customer-services"
          >
            Customer service
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--careers"
            href="/careers"
          >
            Careers
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--vans"
            href="/van-store"
          >
            Vanstore
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--insurance"
            href="/insurance"
          >
            Insurance
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--news"
            href="/newsroom"
          >
            Car news
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--finance"
            href="/car-finance"
          >
            Car finance
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--hire"
            href="//www.acvm.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Contract hire
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--parts"
            href="//www.arnoldclarkautoparts.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Parts
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--sales"
            href="/new-vans"
            target="_blank"
          >
            New vans
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--motability"
            href="/motability"
          >
            Motability
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--accessories"
            href="/servicing/accessories"
          >
            Accessories
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--training"
            href="//www.gtg.co.uk"
            rel="noreferrer"
            target="_blank"
          >
            Training
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--sell"
            href="/sell-my-car"
          >
            Sell your car
          </a>
        </div>
        <div className="sl:ch-col--6 lg:ch-col--4 ac-alsofrom__item">
          <a
            className="ch-btn ch-btn--block ac-alsofrom__link ac-alsofrom__link--rental"
            href="//www.arnoldclarkrental.com"
            rel="noreferrer"
            target="_blank"
          >
            Rental
          </a>
        </div>
      </div>
      <button
        className="ch-btn ch-btn--sm ch-btn--block ch-btn--link js-off--hide ac-alsofrom__more"
        onClick={toggleListCollapsed}
        type="button"
      >
        {isCollapsed ? 'Show more' : 'Show less'}
      </button>
    </div>
  );
};

export default AlsoFromArnold;
