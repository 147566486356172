import React from 'react';
import PropTypes from 'prop-types';

export const VehicleShowcaseTabs = ({
  activeTab,
  schema,
  setActiveTab,
  vehicles,
}) => {
  return schema.map((gallery) => {
    const { displayName, key } = gallery;

    return vehicles[key].length ? (
      <button
        className={`ch-tab ${activeTab === key ? 'ch-tab--selected' : ''}`}
        key={key}
        onClick={() => setActiveTab(key)}
        type="button"
      >
        {displayName}
      </button>
    ) : null;
  });
};

VehicleShowcaseTabs.propTypes = {
  activeTab: PropTypes.string,
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  setActiveTab: PropTypes.func,
  vehicles: PropTypes.shape({
    // We don't really care what the keys are, just
    // that the values are arrays
    [PropTypes.any]: PropTypes.arrayOf(PropTypes.any),
  }),
};
