import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollHorizontally } from '@lib/scroller';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import classNames from 'classnames';

export default class HorizontalScroller extends Component {
  static displayName = 'HorizontalScroller';
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    locked: false,
    showLeft: false,
    showRight: false,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown, true);

    EventEmitter.on(EVENT_TYPES.imgGalleryOpen, () => {
      this.setState({ locked: true });
    });

    EventEmitter.on(EVENT_TYPES.imgGalleryClosed, () => {
      this.setState({ locked: false });
    });

    this.updateButtons(0);
  }

  componentDidUpdate(prevProps) {
    const oldKeys = prevProps.children.map((el) => el.key).join('');
    const newKeys = this.props.children.map((el) => el.key).join('');
    if (oldKeys === newKeys) return;
    this.slider.scrollLeft = 0;
    this.updateButtons(0);
  }

  marginOffset = 28;

  onKeyDown = (event) => {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;

    if (event.keyCode === LEFT_ARROW) return this.scrollLeft();
    if (event.keyCode === RIGHT_ARROW) return this.scrollRight();
  };

  scrollLeft = () => {
    const newPosition =
      this.slider.scrollLeft - (this.wrapper.offsetWidth - this.marginOffset);
    scrollHorizontally(this.slider, newPosition);
    this.updateButtons(newPosition);
  };

  scrollRight = () => {
    const newPosition =
      this.slider.scrollLeft + (this.wrapper.offsetWidth - this.marginOffset);
    scrollHorizontally(this.slider, newPosition);
    this.updateButtons(newPosition);
  };

  updateButtons = (position) => {
    const showLeft = position > 0;
    const showRight =
      position + this.wrapper.offsetWidth < this.slider.scrollWidth;
    if (
      showLeft !== this.state.showLeft ||
      showRight !== this.state.showRight
    ) {
      this.setState({ showLeft, showRight });
    }
  };

  render() {
    return (
      <div
        className="ac-horiz-scroller ch-mh--n2"
        ref={(wrapper) => (this.wrapper = wrapper)}
      >
        <div
          className={classNames('ac-horiz-scroller__slider sm:ch-mh--2', {
            'ac-horiz-scroller--locked': this.state.locked,
          })}
          ref={(slider) => (this.slider = slider)}
        >
          {this.props.children}
        </div>
        {this.state.showLeft && (
          <button
            className="js-off--hide ac-hidden--sl ac-horiz-scroller__prev"
            onClick={this.scrollLeft}
            type="button"
          >
            <span className="ch-reader">Previous</span>
          </button>
        )}
        {this.state.showRight && (
          <button
            className="js-off--hide ac-hidden--sl ac-horiz-scroller__next"
            onClick={this.scrollRight}
            type="button"
          >
            <span className="ch-reader">Next</span>
          </button>
        )}
      </div>
    );
  }
}
