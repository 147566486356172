import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterButton,
  FilterContainer,
  FilterOption,
} from '@components/search_filters/units';

const EMPTY_VALUE = '';

const ENGINE_RANGES = {
  maximum: 'maximum',
  minimum: 'minimum',
};

const EngineSelect = ({ availableOptions, onFilterUpdate, selected }) => {
  const { maxEngineSizes, minEngineSizes } = availableOptions;

  const [activeEngineRange, setActiveEngineRange] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [maxEngineSize, setMaxEngineSize] = useState(selected.maxEngineSize);
  const [minEngineSize, setMinEngineSize] = useState(selected.minEngineSize);

  const closeFilter = () => {
    setActiveEngineRange(null);
    setIsClosing(true);
    setTimeout(() => setIsClosing(false), 500);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilterUpdate({
      maxEngineSize,
      minEngineSize,
    });
  };

  const handleClick = (evt) =>
    activeEngineRange ? closeFilter() : handleSubmit(evt);

  const handleSelect = (engineSize) => {
    activeEngineRange === ENGINE_RANGES.minimum
      ? setMinEngineSize(engineSize)
      : setMaxEngineSize(engineSize);
  };

  const generateSelectedOptionLabelFor = (range) => {
    const rangeSelected =
      range === ENGINE_RANGES.minimum ? minEngineSizes : maxEngineSizes;
    const valueToDisplay =
      range === ENGINE_RANGES.minimum ? minEngineSize : maxEngineSize;
    if (valueToDisplay === EMPTY_VALUE) return;

    return rangeSelected.find(([, value]) => value === valueToDisplay)[0];
  };

  const openEngineRangeOptions = (engineRange) =>
    setActiveEngineRange(engineRange);

  const getAvailableRangeOptions = (isMinimumRangeOpen) => {
    const limit = isMinimumRangeOpen ? maxEngineSize : minEngineSize;
    const selectedRange = isMinimumRangeOpen ? minEngineSizes : maxEngineSizes;

    const availableOptions = isMinimumRangeOpen
      ? ([, value]) =>
          value === EMPTY_VALUE || parseInt(value) < parseInt(limit)
      : ([, value]) =>
          value === EMPTY_VALUE || parseInt(value) > parseInt(limit);

    return limit === EMPTY_VALUE
      ? selectedRange
      : selectedRange.filter(availableOptions);
  };

  const isSelected = (engineSize) => {
    const selectedEngineSize =
      activeEngineRange === ENGINE_RANGES.minimum
        ? minEngineSize
        : maxEngineSize;
    return engineSize === selectedEngineSize;
  };

  const renderOptions = () => {
    const isMinimumRangeOpen = activeEngineRange === ENGINE_RANGES.minimum;
    const availableOptions = getAvailableRangeOptions(isMinimumRangeOpen);

    return availableOptions.map(([label, value]) => (
      <FilterOption
        key={`${activeEngineRange}_${label}`}
        label={label}
        onChange={() => handleSelect(value)}
        selected={isSelected(value)}
        type="radio"
        value={value}
      />
    ));
  };

  return (
    <FilterContainer handleClick={handleClick} title="Select an engine size">
      {!activeEngineRange ? (
        <div className={`${isClosing && `ac-animation--fade-in-from-left`}`}>
          <FilterButton
            name="Minimum size"
            onClick={() => openEngineRangeOptions(ENGINE_RANGES.minimum)}
            selectedOptions={generateSelectedOptionLabelFor(
              ENGINE_RANGES.minimum
            )}
          />
          <FilterButton
            name="Maximum size"
            onClick={() => openEngineRangeOptions(ENGINE_RANGES.maximum)}
            selectedOptions={generateSelectedOptionLabelFor(
              ENGINE_RANGES.maximum
            )}
          />
        </div>
      ) : (
        <div className="ac-animation--fade-in-from-right">
          <h4 className="ch-mt--4">Select {activeEngineRange}</h4>
          {renderOptions()}
        </div>
      )}
    </FilterContainer>
  );
};

export default EngineSelect;

EngineSelect.propTypes = {
  availableOptions: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.shape({
    minEngineSize: PropTypes.string,
    maxEngineSize: PropTypes.string,
  }),
};
