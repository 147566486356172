import React from 'react';
import Modal from '@components/application/modal';
import ImageGallery from './imageGallery';

const GalleryWrapper = () => {
  return (
    <Modal id="imageGallery">
      <ImageGallery />
    </Modal>
  );
};

export default GalleryWrapper;
