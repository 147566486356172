import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GeolocationButton from '@components/application/input/locationFinder/geolocationButton';
import buildGoogleAutocomplete from './buildGoogleAutocomplete';
import addLocationToAnalytics from './addLocationToAnalytics';
import { requestScriptLoad } from '@components/helpers/externalScriptHelper';
import classNames from 'classnames';

const LocationFinder = ({ id, location, name, source }) => {
  const [browserSupportsGeolocation, setBrowserSupportsGeolocation] =
    useState(false);
  const [autocompleteInitialised, setAutocompleteInitialised] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(location || '');

  const locationInputRef = useRef(null);
  const groupRef = useRef(null);

  const handleAutocompleteHasBuilt = () => setAutocompleteInitialised(true);

  const handleLocationChange = (event) =>
    setCurrentLocation(event.target.value);

  const checkIfBrowserSupportsGeolocation = () => {
    if (navigator.geolocation && navigator.geolocation.getCurrentPosition)
      setBrowserSupportsGeolocation(true);
  };

  const updateLocation = ({ place, location }) => {
    const newLocationState = location ?? place.formatted_address;
    setCurrentLocation(newLocationState);
  };

  const handlePlaceSelected = (place) => {
    addLocationToAnalytics(place);
    updateLocation({ place });
  };

  const initialiseLocationFinder = () => {
    if (!autocompleteInitialised) {
      buildGoogleAutocomplete({
        inputElement: locationInputRef.current,
        onFinished: handleAutocompleteHasBuilt,
        onPlaceChange: handlePlaceSelected,
      });
    }
    checkIfBrowserSupportsGeolocation();
  };

  useEffect(() => {
    window.google
      ? initialiseLocationFinder()
      : requestScriptLoad('google', initialiseLocationFinder);
  }, []);

  return (
    <>
      <div className="ac-location__group" ref={groupRef}>
        {browserSupportsGeolocation && source === 'branch search' && (
          <GeolocationButton onSuccess={updateLocation} source={source} />
        )}
        <input
          className={classNames('ch-form__control', {
            'ac-location__pad-left':
              source === 'branch search' && browserSupportsGeolocation,
          })}
          id={id}
          name={name}
          onChange={handleLocationChange}
          placeholder=""
          ref={locationInputRef}
          type="text"
          value={currentLocation}
        />
      </div>
      {source === 'find a dealer' && (
        <button
          className="ch-btn ch-btn--success ch-width--12 ch-mt--2"
          type="submit"
        >
          Search branches
        </button>
      )}
      {browserSupportsGeolocation && source === 'find a dealer' && (
        <GeolocationButton onSuccess={updateLocation} source={source} />
      )}
    </>
  );
};

export default LocationFinder;

LocationFinder.propTypes = {
  id: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  source: PropTypes.string,
};
