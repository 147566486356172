import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchVehiclesByStockReference } from '@lib/requests/stockReference';
import VehicleComparisonHeader from './vehicleComparisonHeader';
import VehicleComparison from './vehicleComparison';

const VehicleComparisonContainer = ({ stockRefs }) => {
  const [status, setStatus] = useState('');
  const [comparisonData, setComparisonData] = useState({});

  useEffect(() => {
    const fetchVehicleData = async () => {
      setStatus('loading');
      try {
        const response = await fetchVehiclesByStockReference(stockRefs, true);
        if (!response.ok) throw response.error;

        setComparisonData(response.body);
        setStatus('loaded');
      } catch {
        setStatus('error');
      }
    };

    fetchVehicleData();
  }, []);

  const hasVehicleData = (data) => data.length <= 0 && setStatus('cleared');

  const updateComparisonVehicleData = (newVehicleSearchData) => {
    setComparisonData({
      ...comparisonData,
      vehicleSearchData: newVehicleSearchData,
    });
    hasVehicleData(newVehicleSearchData);
  };

  const LOADING_STATES = {
    loaded: (
      <VehicleComparison
        comparisonData={comparisonData}
        updateComparisonVehicleData={updateComparisonVehicleData}
      />
    ),
    loading: (
      <div className="ch-text--center ch-pa--4">
        <span className="ac-loading__spinner ac-loading__spinner--small" />
      </div>
    ),
    error: (
      <div className="ch-text--center ch-pa--4">
        <p>We were unable to load your comparison at this time.</p>
      </div>
    ),
    cleared: (
      <div className="ch-text--center ch-pa--4">
        <p className="ch-text--bold">You have removed all vehicles</p>
        <p>
          You can add more vehicles for comparison from your shortlist page.
        </p>
      </div>
    ),
  };

  const determineContent = (status) => {
    return LOADING_STATES[status];
  };

  return (
    <div className="compare__wrapper ac-modal--fade ch-display--flex ch-flex-flow--column-nowrap">
      <VehicleComparisonHeader
        vehicleCount={comparisonData?.vehicleSearchData?.length}
      />
      <div
        className={`compare__body compare__body--${comparisonData?.vehicleSearchData?.length} ch-bg--grey-1 ch-ph--1`}
      >
        {determineContent(status)}
      </div>
    </div>
  );
};

VehicleComparisonContainer.propTypes = {
  stockRefs: PropTypes.array,
};

export default VehicleComparisonContainer;
