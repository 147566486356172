import React from 'react';
import PropTypes from 'prop-types';

const ColourWidget = ({ colour }) => (
  <span
    className={`ch-mr--2 colour-widget colour-widget--${colour.toLowerCase()}`}
  ></span>
);

export default ColourWidget;

ColourWidget.propTypes = {
  colour: PropTypes.string,
};
