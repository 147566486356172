import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FilterOption,
  FilterContainer,
} from '@components/search_filters/units';

const MakeSelect = ({ availableOptions, onFilterUpdate, selected }) => {
  const [makeModelTrimSelection, setMakeModelTrimSelection] = useState(
    selected.makeModelTrimSelection
  );

  const isMakeSelected = (makeKey) => {
    return makeKey in makeModelTrimSelection;
  };

  const selectMake = (makeKey) => {
    setMakeModelTrimSelection((prevState) => {
      const makeModelTrimCopy = { ...prevState };

      if (isMakeSelected(makeKey)) {
        delete makeModelTrimCopy[makeKey];
      } else {
        makeModelTrimCopy[makeKey] = {
          displayName: availableOptions[makeKey].displayName,
          models: {},
        };
      }
      return makeModelTrimCopy;
    });
  };

  const renderMakeOptions = () => {
    return Object.entries(availableOptions).map((makeData) => {
      const [makeKey, { displayName: displayName }] = makeData;

      return (
        <FilterOption
          key={makeKey}
          label={displayName}
          name={makeKey}
          onChange={() => selectMake(makeKey)}
          selected={isMakeSelected(makeKey)}
          type="checkbox"
          value={makeKey}
        />
      );
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onFilterUpdate({ makeModel: { ...makeModelTrimSelection } });
  };

  const handleClearSelection = () => {
    setMakeModelTrimSelection({});
  };

  return (
    <FilterContainer
      handleClearSelection={handleClearSelection}
      handleClick={handleSubmit}
      showClearButton
      title="Select makes"
    >
      {renderMakeOptions()}
    </FilterContainer>
  );
};

MakeSelect.propTypes = {
  availableOptions: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  selected: PropTypes.object,
};

export default MakeSelect;
