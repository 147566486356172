import React from 'react';
import PropTypes from 'prop-types';

const PaginationButton = ({
  children,
  className,
  href,
  isDisabled,
  onClick,
  pageNumber,
}) => {
  return (
    <a
      className={className}
      data-page-number={pageNumber}
      disabled={isDisabled}
      href={href}
      onClick={onClick}
      rel="nofollow"
    >
      {children}
    </a>
  );
};

PaginationButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  pageNumber: PropTypes.number,
};

export default PaginationButton;
