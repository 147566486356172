import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

const ProgressiveImage = ({ alt, height, image, placeholder, width }) => {
  return (
    <InView rootMargin="72px 0px 72px 0px" threshold={0.05} triggerOnce>
      {({ inView, ref }) => (
        <img
          alt={alt}
          className="ch-img--responsive"
          height={height}
          ref={ref}
          src={inView ? image : placeholder}
          width={width}
        />
      )}
    </InView>
  );
};

ProgressiveImage.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.number,
  image: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.number,
};

ProgressiveImage.defaultProps = {
  alt: '',
  placeholder: '',
};

export default ProgressiveImage;
