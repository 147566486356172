import React from 'react';
import PropTypes from 'prop-types';
import SearchTag from './searchTag';

export const SearchTags = ({
  invalidSearch,
  isMultipleFilterLines,
  isShowingMoreSearchTags,
  isTagsToggleVisible,
  removeFilterTag,
  searchTagListRef,
  searchTags,
  toggleShowMoreTags,
  visibleTagCount,
}) => {
  return (
    <>
      <div className="searchtags__scroll-container sm:ch-display--flex sm:ch-flex-flow--row-wrap md:ch-width--7 sm:ch-width--6">
        <ul
          className={`searchtags__tag-list ch-mt--1 sm:ch-mt--0 js-off--hide ${
            isShowingMoreSearchTags && 'sm:ch-flex-flow--row-wrap'
          }`}
          ref={searchTagListRef}
        >
          {searchTags.map((searchTag) => (
            <SearchTag
              isHighlighted={invalidSearch}
              key={`${searchTag.type}_${searchTag.value}`}
              label={searchTag.label}
              onTagClick={removeFilterTag}
              type={searchTag.type}
              value={searchTag.value}
            />
          ))}

          {isShowingMoreSearchTags && isMultipleFilterLines && (
            <li>
              <button
                className="ch-btn ch-btn--link ch-fs--2 ch-flex--none ac-hidden--sl"
                onClick={toggleShowMoreTags}
                type="button"
              >
                Show less
              </button>
            </li>
          )}
        </ul>

        <noscript>
          <ul className="searchtags__tag-list ch-mt--1 sm:ch-mt--0">
            {searchTags.map((searchTag) => (
              <li
                className="searchtag searchtag--noscript"
                key={searchTag.label}
              >
                {searchTag.label}
              </li>
            ))}
          </ul>
        </noscript>
      </div>

      {isTagsToggleVisible && (
        <button
          className="ch-btn ch-btn--link ch-fs--2 ch-flex--none ac-hidden--sl"
          onClick={toggleShowMoreTags}
          type="button"
        >
          Show {searchTags.length - visibleTagCount} more
        </button>
      )}
    </>
  );
};

export default SearchTags;

SearchTags.propTypes = {
  invalidSearch: PropTypes.bool,
  isMultipleFilterLines: PropTypes.bool,
  isShowingMoreSearchTags: PropTypes.bool,
  isTagsToggleVisible: PropTypes.bool,
  removeFilterTag: PropTypes.func,
  searchTagListRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  searchTags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  toggleShowMoreTags: PropTypes.func,
  visibleTagCount: PropTypes.number,
};
