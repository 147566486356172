import React from 'react';
import PropTypes from 'prop-types';

const BranchContact = ({ unlockNumbers, branchNumbers }) => {
  const renderContactNumbers = () => {
    return branchNumbers.map((department) => {
      const { title, number, numberWithoutWhitespace } = department;

      return (
        <li key={title}>
          {title}{' '}
          <a
            className="ch-relative ch-fw--400"
            href={`tel:${numberWithoutWhitespace}`}
          >
            {number}
          </a>
        </li>
      );
    });
  };
  return (
    <div>
      {unlockNumbers && (
        <ul className="branch__contact">{renderContactNumbers()}</ul>
      )}
    </div>
  );
};

export default BranchContact;

BranchContact.propTypes = {
  branchNumbers: PropTypes.array,
  unlockNumbers: PropTypes.node,
};
