import React from 'react';
import PropTypes from 'prop-types';

const BranchResult = ({ image, content }) => {
  return (
    <li className="ch-card branch ch-mv--3 ch-mh--1 sm:ch-mv--4 sm:ch-mh--2 ch-display--flex ch-flex-flow--column-nowrap ch-shadow--sm ch-rounded--sm">
      {image}
      {content}
    </li>
  );
};

export default BranchResult;

BranchResult.propTypes = {
  content: PropTypes.node,
  image: PropTypes.node,
};
