import React from 'react';
import PropTypes from 'prop-types';
import PaginationButton from './paginationButton';
import { paginate } from './pageRange';

const Pagination = ({
  baseUrl,
  currentPage,
  onPageChanged,
  parameters,
  resultsPerPage,
  totalNumberOfPages,
  totalResults,
}) => {
  const isFirstPage = currentPage === 1 || typeof currentPage === 'undefined';
  const isLastPage = currentPage === totalNumberOfPages;
  const previousPage = (pageNumber) => pageNumber - 1;
  const nextPage = (pageNumber) => pageNumber + 1;

  const handleOnPageChanged = (event) => onPageChanged && onPageChanged(event);

  const urlForPage = (pageNumber) => {
    return `${baseUrl}${parameters}${
      baseUrl.indexOf('?') === -1 ? '?page=' : '&page='
    }${pageNumber}`;
  };

  const getPageButtons = () => {
    return paginate(currentPage, totalNumberOfPages).map((pageNumber) => {
      return (
        <PaginationButton
          className={`ch-btn ch-btn--link ch-display--none sl:ch-display--flex ch-pagination__step ${
            currentPage === pageNumber ? 'ch-pagination__step--current' : ''
          }`}
          href={urlForPage(pageNumber)}
          isDisabled={pageNumber === currentPage}
          key={`page-${pageNumber}`}
          onClick={handleOnPageChanged}
          pageNumber={pageNumber}
        >
          {pageNumber}
        </PaginationButton>
      );
    });
  };

  const upperPageBound = () => {
    return resultsPerPage * currentPage >= totalResults
      ? totalResults
      : resultsPerPage * currentPage;
  };

  const lowerPageBound = () => {
    return resultsPerPage * currentPage - (resultsPerPage - 1);
  };

  if (totalNumberOfPages <= 1) return null;

  return (
    <nav className="ch-text--center ch-pagination ch-mb--2">
      <p className="ch-fs--3 ch-pagination__title ch-mb--3">
        Showing <strong>{lowerPageBound()}</strong> to{' '}
        <strong>{upperPageBound()}</strong> of <strong>{totalResults}</strong>{' '}
        results
      </p>
      <div className="ch-pagination__nav">
        <PaginationButton
          className="ch-btn ch-pagination__previous"
          href={urlForPage(previousPage(currentPage))}
          isDisabled={isFirstPage}
          onClick={handleOnPageChanged}
          pageNumber={previousPage(currentPage)}
        />
        <PaginationButton
          className="ch-btn ch-btn--link ch-pagination__first ch-display--none sl:ch-display--inline"
          href={urlForPage(1)}
          isDisabled={isFirstPage}
          onClick={handleOnPageChanged}
          pageNumber={1}
        >
          First
        </PaginationButton>

        <p className="ch-fs--3 ch-mb--0 sl:ch-hide">
          Page <strong>{currentPage}</strong> of{' '}
          <strong>{totalNumberOfPages}</strong>
        </p>

        {getPageButtons()}

        <PaginationButton
          className="ch-btn ch-btn--link ch-pagination__last ch-display--none sl:ch-display--inline"
          href={urlForPage(totalNumberOfPages)}
          isDisabled={isLastPage}
          onClick={handleOnPageChanged}
          pageNumber={totalNumberOfPages}
        >
          Last
        </PaginationButton>
        <PaginationButton
          className="ch-btn ch-pagination__next"
          href={urlForPage(nextPage(currentPage))}
          isDisabled={isLastPage}
          onClick={handleOnPageChanged}
          pageNumber={nextPage(currentPage)}
        />
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  baseUrl: PropTypes.string,
  currentPage: PropTypes.number,
  onPageChanged: PropTypes.func,
  parameters: PropTypes.string,
  resultsPerPage: PropTypes.number,
  totalNumberOfPages: PropTypes.number,
  totalResults: PropTypes.number,
};

Pagination.defaultProps = {
  parameters: '',
  resultsPerPage: 24,
};

export default Pagination;
