import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import VehicleShowcase from '../vehicle_showcase/vehicleShowcase';

export default function LazyLoader({ componentName, ...props }) {
  const [ref, inView] = useInView({
    rootMargin: '200px 0px',
    triggerOnce: true,
  });

  const components = {
    vehicleShowcase: VehicleShowcase,
  };

  const Component = components[componentName];
  return <div ref={ref}>{inView && <Component {...props} />}</div>;
}

LazyLoader.propTypes = {
  componentName: PropTypes.string.isRequired,
};
