import React from 'react';
import Modal from '../modal';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

const EvBanner = () => {
  const openModal = () => EventEmitter.emit(EVENT_TYPES.openModal, `ev-modal`);
  const closeModal = () => EventEmitter.emit(EVENT_TYPES.closeModal);

  const CONFIG = {
    ev: {
      bannerText: 'Get a home charger for only £529 when you buy this car!',
      tcLink: '/terms/#discounted-home-charger',
      chip: false,
      paragraph1:
        'Get a home charger and installation for only £529 when you buy a pre-reg or used fully electric car.',
      paragraph2:
        'Once your order has been completed, Bumblebee, our trusted partners, will be in touch to arrange the install of your electric charger.',
    },
  };

  return (
    <>
      <div
        className="ac-promo ac-promo__campaign ac-promo__campaign--ev ch-text--bold ch-bc--ac-yellow ch-pa--2"
        onClick={openModal}
      >
        <div className="ac-promo__heading ch-text--center">
          <p className="ch-fs--3 md:ch-fs--4 ch-mb--1 sm:ch-mb--0 sm:ch-pr--2">
            {CONFIG['ev'].bannerText}
          </p>
          <u className="ch-text--normal ch-fs--2 md:ch-fs--4">Find out more</u>
        </div>
      </div>
      <Modal hideClose id="ev-modal">
        <div className="ch-modal ch-modal--xs">
          <div className="modal__header modal__header--ev">
            <button
              className="ch-modal__close"
              onClick={closeModal}
              type="button"
            >
              <span className="ch-reader">Close</span>
            </button>
          </div>
          <div className="ch-text--center ch-pa--4">
            <p className="ch-fs--3 ch-fw--500">{CONFIG['ev'].paragraph1}</p>
            <p className="ch-fs--2">{CONFIG['ev'].paragraph2}</p>
            <div className="ch-display--flex ch-flex-flow--column-nowrap">
              <a
                className="ch-btn ch-btn--primary"
                href="/vehicles?ev_campaign=true"
              >
                Shop electric cars
              </a>
              <a className="ch-mt--2" href={CONFIG['ev'].tcLink}>
                View terms and conditions
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EvBanner;
