import { addToRecentlyViewed } from '../../vehicle_product/helper/recentVehiclesHelper';
import { useEffect } from 'react';
import localStorageAvailable from '@helpers/localStorage';
import PropTypes from 'prop-types';

const RecentlyViewedHelper = ({ stockRef }) => {
  useEffect(() => {
    if (localStorageAvailable()) addToRecentlyViewed(stockRef);
  }, []);
  return null;
};

export default RecentlyViewedHelper;

RecentlyViewedHelper.propTypes = {
  stockRef: PropTypes.string,
};
