import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { delimitWithCommas } from '@lib/utils';

export default class Money extends PureComponent {
  static displayName = 'Money';
  static propTypes = {
    amount: PropTypes.number,
  };
  constructor(props) {
    super(props);
  }
  decimals(number) {
    return number % 1 !== 0 ? number.toFixed(2) : number;
  }
  commas = (number) => {
    return number < 10000 ? number : this.addThousandsCommaTo(number);
  };
  addThousandsCommaTo(numberOverTenThousand) {
    const [pounds, pence] = String(numberOverTenThousand).split('.');
    const formattedPounds = delimitWithCommas(pounds);
    return pence === undefined
      ? formattedPounds
      : `${formattedPounds}.${pence}`;
  }
  sign = () => {
    return Number(this.props.amount) < 0 ? '-' : '';
  };
  asMoney = () => {
    const number = Math.abs(Number(this.props.amount));
    return this.commas(this.decimals(number));
  };

  render() {
    return (
      <span className="ac-money">
        {this.sign()}£{this.asMoney()}
      </span>
    );
  }
}
