import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from '@components/application/progressiveImage';
import Glider from 'react-glider';
import { InView } from 'react-intersection-observer';

const ImageWrapper = ({ hideImageSlider, imageCount, photos }) => {
  return (
    <>
      {imageCount === 0 && <div className="result__image-no-vehicle" />}
      {imageCount > 0 && (
        <InView rootMargin="600px 0px 600px 0px">
          {({ inView, ref }) => (
            <div className="vehicle-slider" ref={ref}>
              {!inView || hideImageSlider ? (
                <ProgressiveImage
                  height={600}
                  image={photos[0]}
                  key={photos[0]}
                  placeholder="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDgwMCA2MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZBRkFGQSIgZD0iTTAgMGg4MDB2NjAwSDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
                  width={800}
                />
              ) : (
                <Glider hasDots>
                  {photos.map((photo) => (
                    <ProgressiveImage
                      height={600}
                      image={photo}
                      key={photo}
                      placeholder="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDgwMCA2MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0ZBRkFGQSIgZD0iTTAgMGg4MDB2NjAwSDB6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
                      width={800}
                    />
                  ))}
                </Glider>
              )}
            </div>
          )}
        </InView>
      )}
    </>
  );
};

ImageWrapper.propTypes = {
  hideImageSlider: PropTypes.bool,
  imageCount: PropTypes.number,
  photos: PropTypes.array,
};

export default React.memo(ImageWrapper);
