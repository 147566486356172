import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pluralise } from '@lib/utils';

export default class VehicleBranch extends Component {
  static propTypes = {
    distance_to_customer: PropTypes.number,
    icon: PropTypes.string,
    name: PropTypes.string,
    prefix: PropTypes.string,
    url: PropTypes.string,
  };

  renderDistanceText = (distance) => {
    const roundedDistance = Math.floor(distance);
    return roundedDistance < 1
      ? ` (Less than 1 mile)`
      : ` (${roundedDistance} ${pluralise(roundedDistance, 'mile')})`;
  };

  render() {
    return (
      <a
        className={`branchname branchname--${this.props.icon}`}
        href={this.props.url}
      >
        {this.props.prefix && (
          <span className="ch-flex--none">{this.props.prefix} </span>
        )}
        <span className="ch-text--bold ch-text--ellipsis">
          {this.props.name}
        </span>
        {typeof this.props.distance_to_customer === 'number' && (
          <span className="ch-flex--none">
            {this.renderDistanceText(this.props.distance_to_customer)}
          </span>
        )}
      </a>
    );
  }
}
