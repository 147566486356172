import request from 'superagent';
import type { StockReferences } from '@components/shortlist/shortlistStore';
import { acMobileFeatures } from '@arnoldclark/ac-mobile-features';
import { useAccessToken } from '@arnoldclark/use-access-token';
import Auth from '../auth';
import { Endpoint, HttpMethod, QueryString } from '@javascript/types/Requests';

type ApiShortlistVehicle = {
  sold: boolean;
  stockReference: string;
};

export enum CounterOperation {
  increment = 'increment',
  decrement = 'decrement',
}

const { isNativeApp } = acMobileFeatures();

const getAccessTokenSilently = () =>
  new Auth()
    .initializeAuthClient(window.authOptions)
    .then((client) => client.getTokenSilently());

// the linter thinks this is a React hook, it's not
// eslint-disable-next-line react-hooks/rules-of-hooks
const { getAuthHeaders, fetchWithAuthRetry } = useAccessToken(
  isNativeApp(),
  getAccessTokenSilently
);

const getCSRFToken = (): string =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ||
  '';

const csrfHeader = () => ({ 'X-CSRF-Token': getCSRFToken() });

async function shortlistAPI(
  method: HttpMethod,
  options?: {
    url: Endpoint | QueryString;
    stockReferences?: StockReferences;
  }
) {
  const headerArgs = {
    ...(method !== HttpMethod.GET ? csrfHeader() : {}),
    ...(options?.stockReferences ? { 'Content-Type': 'application/json' } : {}),
  };
  const headers = await getAuthHeaders(headerArgs);
  const baseURL = '/api/shortlist';
  const requestUrl = options ? `${baseURL}${options.url}` : baseURL;

  return fetchWithAuthRetry(requestUrl, {
    headers,
    method,
    ...(options?.stockReferences
      ? { body: JSON.stringify({ stockReferences: options.stockReferences }) }
      : {}),
  });
}

export const addToShortlist = async (stockReference: StockReferences) =>
  shortlistAPI(HttpMethod.POST, {
    url: `?stockReference=${stockReference}`,
  });

export const deleteSoldVehicles = async () =>
  shortlistAPI(HttpMethod.DELETE, { url: '/deletesoldcars' });

export const getShortlist = async (): Promise<ApiShortlistVehicle[]> =>
  shortlistAPI(HttpMethod.GET).then((res) => res.json());

export const clearShortlist = async () =>
  shortlistAPI(HttpMethod.DELETE, { url: '/clear' });

export const mergeShortlist = async (
  stockReferences: StockReferences
): Promise<ApiShortlistVehicle[]> =>
  shortlistAPI(HttpMethod.PUT, { url: '/merge', stockReferences }).then((res) =>
    res.json()
  );

export const removeFromShortlist = async (stockReference: StockReferences) =>
  shortlistAPI(HttpMethod.DELETE, {
    url: `?stockReference=${stockReference}`,
  });

export const replaceShortlist = async (stockReferences: StockReferences) =>
  shortlistAPI(HttpMethod.PUT, { url: '/replace', stockReferences });

export const updateShortlistCount = (
  stockReferences: StockReferences,
  operation: CounterOperation
): void => {
  const stockRefArray = Array.isArray(stockReferences)
    ? stockReferences
    : [stockReferences];
  request
    .post('/shortlisted/count')
    .set(csrfHeader())
    .send({
      operation,
      stock_references: stockRefArray,
    })
    .end((err, res) => {
      if (err || !res.ok) {
        return;
      }
    });
};
