import { useState } from 'react';

export default function useMultiSelect(selected) {
  const [selectedOptions, setSelectedOptions] = useState(selected || []);

  const addToSelected = (value) => {
    setSelectedOptions([...selectedOptions, value]);
  };

  const removeFromSelected = (value) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== value));
  };

  const resetSelected = () => {
    setSelectedOptions([]);
  };

  const handleChange = (value) => {
    selectedOptions.includes(value)
      ? removeFromSelected(value)
      : addToSelected(value);
  };

  return {
    addToSelected,
    handleChange,
    removeFromSelected,
    resetSelected,
    selectedOptions,
  };
}
