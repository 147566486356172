import React from 'react';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';
import PropTypes from 'prop-types';

const BranchFilter = ({ franchiseSelected }) => {
  const openModal = () => EventEmitter.emit(EVENT_TYPES.openModal, `filters`);
  return (
    <button
      className="ch-btn ch-width--12 ch-mv--2 ch-bc--grey-5 sm:ch-mv--0 sm:ch-ph--6 sm:ch-width--auto branch-filter-button"
      onClick={openModal}
      type="button"
    >
      Filter branches{' '}
      {franchiseSelected && (
        <span className="ch-color--ac-blue ch-pl--1">&#40;1&#41;</span>
      )}
    </button>
  );
};

BranchFilter.propTypes = {
  franchiseSelected: PropTypes.bool,
};

export default BranchFilter;
