import React, { Component } from 'react';
import Modal from '@components/application/modal';
import FinanceLightbox from './financeLightbox';

export default class FinanceLightboxWithModal extends Component {
  render() {
    return (
      <Modal hideClose id="finance-illustration">
        <FinanceLightbox />
      </Modal>
    );
  }
}
