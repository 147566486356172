import React from 'react';
import PropTypes from 'prop-types';
import OpenHours from './openHours';

const OpenHoursContainer = ({ departmentSchema }) => {
  return (
    <ul>
      {departmentSchema.length > 0 &&
        departmentSchema.map((department) => (
          <OpenHours key={department.title} {...department} />
        ))}
    </ul>
  );
};

OpenHoursContainer.propTypes = {
  departmentSchema: PropTypes.array,
};

export default OpenHoursContainer;
