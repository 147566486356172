import React from 'react';
import PropTypes from 'prop-types';
import VehicleReserved from '../vehicleReserved';
import SearchPricing from './searchPricing';

const ResultDetails = ({
  enquiryUrl,
  isReserved,
  salesInfo,
  stockReference,
  url,
}) =>
  isReserved ? (
    <VehicleReserved enquiryUrl={enquiryUrl} />
  ) : (
    <SearchPricing
      pricing={salesInfo.pricing}
      stockReference={stockReference}
      vehicleUrl={url}
    />
  );

ResultDetails.propTypes = {
  enquiryUrl: PropTypes.string,
  isReserved: PropTypes.bool,
  salesInfo: PropTypes.shape({
    summary: PropTypes.array,
    pricing: PropTypes.object,
  }),
  stockReference: PropTypes.string,
  url: PropTypes.string,
};

export default React.memo(ResultDetails);
