import React from 'react';
import PropTypes from 'prop-types';

const StockChip = ({ count, imageCount }) => {
  return count >= 1 && imageCount >= 5 ? (
    <span className="ch-chip stock-chip stock-chip--count">
      {count} IN STOCK NOW
    </span>
  ) : (
    <span className="ch-chip stock-chip">ASK ABOUT STOCK AVAILABILITY</span>
  );
};

StockChip.propTypes = {
  count: PropTypes.number.isRequired,
  imageCount: PropTypes.number.isRequired,
};

export default StockChip;
