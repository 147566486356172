import { useState, useEffect } from 'react';
import type { AsyncVehicles } from '@javascript/types/AsyncResult';
import type { Vehicle } from '@javascript/types/Vehicle';
import { Endpoint } from '@javascript/types/Requests';

export default function useVehicleSearch(
  stockRefs: string[],
  options?: {
    onSuccess:
      | (() => void)
      | ((args: { stockRefs: string[]; vehicles: Vehicle[] }) => void);
    endpoint?: Endpoint;
  }
): AsyncVehicles {
  const [state, setState] = useState<AsyncVehicles>({
    error: null,
    loading: false,
    vehicles: [],
  });

  useEffect(() => {
    if (stockRefs.length === 0) {
      return setState({ vehicles: [], loading: false, error: null });
    }

    if (
      stockRefs.length < state.vehicles.length ||
      stockRefs.length === state.vehicles.length
    )
      return;

    setState((oldState: AsyncVehicles) => ({
      ...oldState,
      loading: true,
    }));

    const baseUrl = `/api/vehicles/search`;
    const query = `?stock_references=${stockRefs.join(' ')}`;
    const url = `${baseUrl}${options?.endpoint || ''}${query}`;

    fetch(url)
      .then((res) => res.json())
      .then((vehicles) => {
        setState({ vehicles, loading: false, error: null });

        if (options?.onSuccess) {
          options.onSuccess({ stockRefs, vehicles });
        }
      })
      .catch((error) =>
        setState({ vehicles: [], loading: false, error: error as Error })
      );
  }, [stockRefs]);

  return state;
}
