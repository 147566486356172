import Modal from '../modal';
import EventEmitter, { EVENT_TYPES } from '@lib/eventEmitter';

const Add500Banner = () => {
  const openModal = () => EventEmitter.emit(EVENT_TYPES.openModal, 'add500');

  return (
    <>
      <button
        className="ac-promo ac-promo-add500 ch-fw--500 ch-width--12 ch-bg--ac-black ch-color--white ch-bc--ac-black ch-text--center sl:ch-text--left"
        onClick={openModal}
        type="button"
      >
        <div className="ac-promo__heading ch-pr--2">
          Get an <span className="ch-color--ac-yellow">extra £500</span> when
          you part-exchange! <u>How it works</u>
        </div>
        <span className="ac-promo-add500__image" />
      </button>
      <Modal id="add500">
        <div className="ch-modal">
          <div className="ch-modal__body">
            <div className="add500-modal">
              <div className="ch-pa--2 ch-bg--ac-black ch-text--center">
                <p className="ch-color--white ch-fw--500 ch-fs--3 ch-mb--0">
                  Get an <span className="ch-color--ac-yellow">extra £500</span>{' '}
                  <br className="sm:ch-display--none" /> when you part-exchange
                </p>
              </div>
              <div className="ch-ph--2 ch-bg--white ch-text--center ch-pt--3 ch-pb--0 md:ch-ph--5">
                <p className="ch-fs--2">
                  Once you’ve found your perfect car, click{' '}
                  <span className="ch-fw--500">‘Build your deal’</span> on any
                  used car page.
                </p>
                <p className="ch-fs--2">
                  As you’re valuing your part exchange, you will be prompted to
                  enter the code:
                </p>
                <img alt="add500" className="add500-modal__logo ch-mb--2" />
                <p className="ch-fs--2">
                  This will give you an{' '}
                  <span className="ch-fw--500">extra £500</span> on top of your
                  valuation!
                </p>
              </div>
              <div className="ch-pa--2 ch-bg--grey-2 ch-text--center">
                <a
                  className="ch-fs--2 ch-pb--2 ch-fw--500"
                  href="/voucher"
                  target="_blank"
                >
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Add500Banner;
