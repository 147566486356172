import React from 'react';
import PropTypes from 'prop-types';

const BranchImage = ({
  firstBranchImageUrl,
  branchImageAttributes,
  branchImageFallbackUrl,
}) => {
  return (
    <div className="branch__image">
      {firstBranchImageUrl ? (
        <img src={firstBranchImageUrl} {...branchImageAttributes} />
      ) : (
        <img
          alt={''}
          height={'600'}
          src={branchImageFallbackUrl}
          width={'400'}
        />
      )}
    </div>
  );
};

export default BranchImage;

BranchImage.propTypes = {
  branchImageAttributes: PropTypes.object,
  branchImageFallbackUrl: PropTypes.string,
  firstBranchImageUrl: PropTypes.string,
};
