import React from 'react';
import PropTypes from 'prop-types';

const FilterButton = ({ name, onClick, selectedOptions, disabled = false }) => {
  return (
    <button
      className={`filter-button ${selectedOptions && 'filter-button--active'}`}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      <span className="ch-width--12">{name}</span>
      {selectedOptions && (
        <small className="ch-color--ac-blue ch-text--ellipsis">
          {selectedOptions}
        </small>
      )}
    </button>
  );
};

export default FilterButton;

FilterButton.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selectedOptions: PropTypes.string,
};
