import React from 'react';
import PropTypes from 'prop-types';
import SelectList from '@components/application/input/selectList';

const ALL_KEY = '_all';

const FallbackFilters = ({ branchId, branchName, makeModel }) => {
  const generateAvailableOptions = (group = [], name) =>
    Object.keys(group).reduce(
      (acc, elemKey) => {
        const { displayName } = group[elemKey];
        return [...acc, [displayName, elemKey]];
      },
      [[`All ${name}`, ALL_KEY]]
    );

  const selectedMakeAndModelsValues = () => {
    const currentMake = Object.keys(makeModel).find(
      (make) => makeModel[make].selected
    );

    if (!currentMake) return ['', ALL_KEY];
    const { models } = makeModel[currentMake];
    const currentModel =
      Object.keys(models).find((model) => models[model].selected) || ALL_KEY;

    return [currentMake, currentModel];
  };

  const generateMakesAndModelsOptions = (currentMake) => {
    const makeOptions = generateAvailableOptions(makeModel, 'makes');
    const availableModels = currentMake && makeModel[currentMake].models;
    const modelOptions = generateAvailableOptions(availableModels, 'models');

    return [makeOptions, modelOptions];
  };

  const [currentMake, currentModel] = selectedMakeAndModelsValues();
  const [makeOptions, modelOptions] =
    generateMakesAndModelsOptions(currentMake);

  return (
    <form
      action="/vehicles"
      className="js-on--hide ch-card ch-mt--4 ch-mb--4"
      id="search"
      method="get"
      name="search"
    >
      <input name="utf8" type="hidden" value="✓" />
      <div className="ch-card__content filters__fallback">
        <div className="ch-form__group ">
          <label className="ch-form__control-label">
            Make
            <SelectList
              className="ch-form__control"
              id="make"
              name="make"
              optionsForSelect={makeOptions}
              selectedOption={[currentMake]}
            />
          </label>
        </div>
        {currentMake && (
          <div className="ch-form__group ">
            <label className="ch-form__control-label">
              Model
              <SelectList
                className="ch-form__control"
                id="model"
                name="model"
                optionsForSelect={modelOptions}
                selectedOption={[currentModel]}
              />
            </label>
          </div>
        )}
        {branchId && (
          <div className="ch-form__group">
            <input
              className="ch-checkbox"
              defaultChecked={branchId}
              id="branch_id"
              name="branch_id"
              type="checkbox"
              value={branchId}
            />
            <label className="ch-checkbox__label" htmlFor="branch_id">
              {branchName}
            </label>
          </div>
        )}

        <button className="ch-btn ch-btn--cta" type="submit">
          Search
        </button>
      </div>
    </form>
  );
};

export default FallbackFilters;

FallbackFilters.propTypes = {
  branchId: PropTypes.string,
  branchName: PropTypes.string,
  makeModel: PropTypes.object.isRequired,
};
