import React from 'react';

export function withViews(Component, options) {
  return class extends Component {
    static displayName = `HOC-${Component.displayName || Component.name}`;

    state = {
      view: options.initialView,
      history: [options.initialView],
      currentStep: 1,
    };

    scrollToTop = () => {
      document.querySelector(options.scrollTo).scrollTop = 0;
    };

    indexOfView = () => this.state.history.indexOf(this.state.view);

    applyCurrentStep = () => {
      const currentStep = this.indexOfView() + 1;
      this.setState({ currentStep });
    };

    setView = (view) => {
      const history = [...this.state.history, view];
      this.scrollToTop();
      if (this.state.history.includes(view)) {
        return this.setState({ view }, this.applyCurrentStep);
      }
      return this.setState({ view, history }, this.applyCurrentStep);
    };

    back = () => {
      if (this.indexOfView() === 0) return;
      const previousView = this.state.history[this.indexOfView() - 1];
      this.setView(previousView);
    };

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          back={this.back}
          ref="withViewComponent"
          setView={this.setView}
        />
      );
    }
  };
}
